import React, {useState, useEffect} from 'react'
import Helmet from 'react-helmet'
import {getResourceOwnerId} from './../../utils/crud'
import CRUDUsername from './../UI/CRUDUsername'
import CRUDIndex from './../UI/CRUDIndex'
import CRUDItemButtons from './../UI/CRUDItemButtons'
import PageHeader from './../UI/PageHeader'
import Loading from './../UI/Loading'
import {doResourcesRequest} from './http'
import './style.scss'

const Home = (props) => {
  const [scoreboards, setScoreboards] = useState(null)
  const [leaderboards, setLeaderboards] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    doResourcesRequest()
      .then(data => {
        setScoreboards(data.scoreboards)
        setLeaderboards(data.leaderboards)
        setLoading(false)
      })
  }, [])

  const renderListItemLabel = (item, type) => {
    const ownerId = getResourceOwnerId(item)
    return (
      <div className="ListItem-Label">
        <div className="ListItem-Label-Title">
          <a
            href={`/viewer/${type}s/${item.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.title}
          </a>
          <span>by</span>
          <CRUDUsername id={ownerId} />
        </div>
      </div>
    )
  }

  const renderScoreboardListItem = (item) =>
    <div
      key={item.id}
      className="ListItem"
    >
      {renderListItemLabel(item, "scoreboard")}
      <CRUDItemButtons
        item={item}
        id={item.id}
        resource="scoreboard"
        title={item.title}
        delteItem={null}
      />
    </div>

  const renderLeaderboardListItem = (item) =>
    <div
      key={item.id}
      className="ListItem"
    >
      {renderListItemLabel(item, "leaderboard")}
      <CRUDItemButtons
        item={item}
        id={item.id}
        resource="leaderboard"
        title={item.title}
        delteItem={null}
      />
    </div>

  const renderResources = () =>
    <div>
      <div className="Home-Section">
        <h2>Newest Public Scoreboards</h2>
        {scoreboards &&
          <CRUDIndex
            resource="scoreboard"
            items={scoreboards}
            loading={false}
            renderListItem={renderScoreboardListItem}
            disableCreate
          />
        }
      </div>
      <div className="Home-Section">
        <h2>Newest Public Leaderboards</h2>
        {leaderboards &&
          <CRUDIndex
            resource="leaderboard"
            items={leaderboards}
            loading={false}
            renderListItem={renderLeaderboardListItem}
            disableCreate
          />
        }
        </div>
    </div>

  return (
    <div className="Home">
      <Helmet>
        <title>ScorbitVision - Home</title>
      </Helmet>
      <PageHeader>
        Home
      </PageHeader>
      {loading
        ? <Loading />
        : renderResources()
      }
    </div>
  )
}
export default Home
