import {
  getAPIURL,
  getScoreAPIURL,
  getXHROptions,
  handleErrors
} from './../../../utils/xhr'

const getBootstrapRequest = (id) => {
  return fetch(getAPIURL(`/api/streamboard/${id}/bootstrap/`), getXHROptions().GET)
    .then(handleErrors)
    .then(response => response.json())
    .catch(error => {return {error: error.toString()}})
}

const getScoresRequest = (id) => {
  return fetch(getScoreAPIURL(`/streamboard/${id}/scores/`), getXHROptions().GET)
    .then(response => response.json())
    .then(json => json)
    .catch(error => {return {error: error.toString()}})
}

export {
  getBootstrapRequest,
  getScoresRequest,
}
