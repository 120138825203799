import React, {useState, useEffect, useContext, useCallback} from 'react'
import {Redirect, Link, useParams} from 'react-router-dom'
import Helmet from 'react-helmet'
import AppContext from './../../contexts/app'
import {capitalize} from './../../utils/string'
import {
  doBootstrapRequest, doMachineRequest, detachScorbitronRequest,
  deleteVenueMachineRequest, getUserByUsernameRequest, doTransferRequest
} from './http'
import Loading from './../UI/Loading'
import PageHeader from './../UI/PageHeader'
import Message from './../UI/Message'
import './style.scss'

const LicenseManager = (props) => {
  const {action} = props
  const {id} = useParams()
  const {profile, message, setMessage} = useContext(AppContext)
  const [licenses, setLicenses] = useState(null)
  const [venueMachines, setVenueMachines] = useState(null)
  const [venueMachine, setVenueMachine] = useState(null)
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState(null)
  const [transferRecipient, setTransferRecipient] = useState(null)
  const [transferError, setTransferError] = useState(null)

  const getData = useCallback(() => {
    if (action === "index") {
      doBootstrapRequest(profile.id)
        .then(data => {
          setLicenses(data.licenses)
          setVenueMachines(data.venueMachines)
          setLoading(false)
        })
    } else if (action === "manage" || action === "transfer") {
      doMachineRequest(id)
        .then(machine => {
          setVenueMachine(machine)
          setLoading(false)
        })
    }
  }, [action, id, profile.id])

  useEffect(() => {
    getData()
  }, [getData])

  const detachScorbitron = (venueMachineId) => {
    if (!window.confirm('Are you sure?')) {
      return false
    }
    detachScorbitronRequest(venueMachineId)
      .then(() => {
        setRedirect(`/license-manager`)
        setMessage('Scorbitron detached!')
      })
  }

  const onSubmitTransfer = (event) => {
    event.preventDefault()
    if (!transferRecipient) {
      return
    }

    getUserByUsernameRequest(transferRecipient)
      .then((user) => {
        if (!user) {
          setTransferError('User not found. Please try again.')
        } else if (user.id === profile.id){
          setTransferError('You can\'t transfer to yourself.')
        } else {
          doTransferRequest(id, user.id)
            .then((response) => {
              if (!response) {
                setTransferError('Transfer failed. Please contact support.')
              } else {
                setRedirect(`/license-manager`)
                setMessage('Transfer successful!')
              }
            })
        }
      })
  }

  const deleteMachine = (venueMachineId) => {
    if (!window.confirm('Are you sure?')) {
      return false
    }
    deleteVenueMachineRequest(venueMachineId)
      .then(() => {
        setRedirect(`/license-manager`)
        setMessage('Machine deleted!')
      })
  }

  const renderLicenses = () => {
    const operatorPurchased = licenses["purchased_operator_licenses"]
    const operatorActive = licenses["active_operator_licenses"]
    const collectorPurchased = licenses["purchased_collector_licenses"]
    const collectorActive = licenses["active_collector_licenses"]
    return (
      <div className="LicenseManager-Licenses">
        <h2>Subscription</h2>
        <dl>
          <div>
            <dt>Total Operator Licenses</dt>
            <dd>{operatorPurchased.toLocaleString()}</dd>
          </div>
          <div>
            <dt>Available Operator Licenses</dt>
            <dd>{(operatorPurchased - operatorActive).toLocaleString()}</dd>
          </div>
          <div>
            <dt>Total Collector Licenses</dt>
            <dd>{collectorPurchased.toLocaleString()}</dd>
          </div>
          <div>
            <dt>Available Operator Licenses</dt>
            <dd>{(collectorPurchased - collectorActive).toLocaleString()}</dd>
          </div>
        </dl>
        <Link
          to="//scorbit.io/purchase"
          target="_blank"
          className="Button Button--secondary"
        >
          Purchase licenses
        </Link>
      </div>
    )
  }

  const renderVenueMachine = (vm) => {
    const {venue, machine, scorbitron} = vm
    const baseClassName = "LicenseManager-Machines-Machine"
    const classNames = [baseClassName]
    classNames.push(vm.scorbitron && vm.scorbitron["is_active"]
      ? `${baseClassName}--active`
      : `${baseClassName}--inactive`
    )
    return (
      <tr
        key={vm["id"]}
        className={classNames.join(" ")}
      >
        <td className={`${baseClassName}-Title`}>
          <div>
            <strong>{machine["name"]}</strong>
          </div>
          <div>
            at {venue["name"]}
          </div>
        </td>
        <td className={`${baseClassName}-ScorbitronSerial`}>
          {scorbitron
            ? scorbitron["serial_number"]
            : "- -"
          }
        </td>
        <td className={`${baseClassName}-ScorbitronLicense`}>
          {scorbitron
            ? scorbitron["license_used"]
            : '- -'
          }
        </td>
        <td className={`${baseClassName}-ScorbitronStatus`}>
          {scorbitron && scorbitron["is_active"]
            ? 'active'
            : 'inactive'
          }
        </td>
        <td className={`${baseClassName}-Actions`}>
          <Link
            to={`/license-manager/${vm.id}/manage`}
            className="Button Button--secondary"
          >
            actions
          </Link>
        </td>
      </tr>
    )
  }

  const renderVenueMachines = () => {
    return (
      <div className="LicenseManager-Machines">
        <h2>Machines</h2>
        {!venueMachines.length
          ? <div className="LicenseManager-Machines">
              No machines...
            </div>
          : <table>
              <thead>
                <tr>
                  <th>Machine</th>
                  <th>Scorbitron</th>
                  <th>License Type</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {venueMachines.map(renderVenueMachine)}
              </tbody>
            </table>
        }
      </div>
    )
  }

  const renderMachineSummary = (details) => {
    const {venue, machine, scorbitron} = details
    return (
      <div className="LicenseManager-Machine-Details">
        <h2>Machine details</h2>
        <dl>
          <div>
            <dt>Machine</dt>
            <dd>{machine.name}</dd>
          </div>
          <div>
            <dt>Venue</dt>
            <dd>{venue.name}</dd>
          </div>
          <div>
            <dt>Scorbitron Serial #</dt>
            <dd>
              {scorbitron
                ? scorbitron["serial_number"]
                : "- -"
              }
            </dd>
          </div>
          <div>
            <dt>License Type</dt>
            <dd>
              {scorbitron
                ? scorbitron["license_used"]
                : '- -'
              }
            </dd>
          </div>
        </dl>
      </div>
    )
  }

  const renderManage = () => {
    const {id} = venueMachine
    const {venue, machine, scorbitron} = venueMachine
    return (
      <div className="LicenseManager-Machine">
        {renderMachineSummary({id, venue, machine, scorbitron})}
        <div className="LicenseManager-Machine-Actions">
          <h2>Actions</h2>
          {scorbitron &&
            <button onClick={() => detachScorbitron(id)}>
              detach scorbitron
            </button>
          }
          {scorbitron &&
            <Link
              to={`/license-manager/${id}/transfer`}
              className="Button"
            >
              transfer scorbitron + machine
            </Link>
          }
          <button onClick={() => deleteMachine(id)}>
            delete machine
          </button>
        </div>
      </div>
    )
  }

  const renderTransfer = () => {
    const {id} = venueMachine
    const {venue, machine, scorbitron} = venueMachine
    return (
      <div className="LicenseManager-Machine">
        <form
          className="has-required-fields"
          onSubmit={onSubmitTransfer}
        >
          {transferError &&
            <div className="formError">{transferError}</div>
          }
          <div className="formRow">
            <label className="primary required">
              Recipient's username
            </label>
            <input
              type="text"
              value={transferRecipient || ''}
              onChange={(event) => setTransferRecipient(event.target.value)}
            />
          </div>
          {renderMachineSummary({id, venue, machine, scorbitron})}
          <div className="formRow formRow--buttons flexRow">
            <button type="submit">
              Transfer!
            </button>
            <Link
              to={`/license-manager/${id}/manage`}
              className="Button Button--secondary"
            >
              Cancel
            </Link>
          </div>
       </form>
      </div>
    )
  }

  const renderIndex = () =>
    <div>
      {renderLicenses()}
      {renderVenueMachines()}
    </div>

  const renderSubSection = () => {
    if (action === "manage") {
      return renderManage()
    } else if (action === "transfer") {
      return renderTransfer()
    } else {
      return renderIndex()
    }
  }

  const getTitle = () => {
    return action === 'index'
      ? 'My Machines'
      : (
          <div>
            <Link to='/license-manager'>
              My Machines
            </Link>
            {[
              " ",
              "→",
              `${capitalize(action)} machine`,
            ].join(' ')}
          </div>
        )
  }

  const render = () =>
    <div className="LicenseManager">
      <Helmet>
        <title>Scorbit Machine Tools</title>
      </Helmet>
      <PageHeader>{getTitle()}</PageHeader>
      {message &&
        <Message content={message} />
      }
      {loading
        ? <Loading />
        : renderSubSection()
      }
    </div>

  return redirect
    ? <Redirect to={redirect} />
    : render()
}

export default LicenseManager