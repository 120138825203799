import React from 'react'
import {Link} from 'react-router-dom'
import Dropdown from './../Dropdown'
import DefaultUserIcon from './../../../assets/img/user-icon/default-user.png'
import './style.scss'

const SecondaryNavigation = (props) => {
  const {profile} = props
  return (
    <div className="SecondaryNavigation">
      {profile && (
        <div className="SecondaryNavigation-UserMenu">
          <Dropdown
            className="SecondaryNavigation-UserMenu-Dropdown"
            trigger = {
              profile["profile_picture"]
                ? <img src={profile["profile_picture"]} alt="" />
                : <img src={DefaultUserIcon} alt="" />
              }
            content = {
              <div className='SecondaryNavigation-UserMenu-Dropdown-Items'>
                <div className="Dropdown-Items-Label">
                  <span>Signed in as</span>
                  <strong>{profile["username"]}</strong>
                </div>
                <Link to="/profile">
                  <span>Profile</span>
                </Link>
                <Link to="//www.scorbit.io/my-account/" target="_blank">
                  <span>Account</span>
                </Link>
                <Link to="//support.scorbit.io/hc/en-us" target="_blank">
                  <span>Help</span>
                </Link>
                <Link to="//www.scorbit.io/" target="_blank">
                  <span>Scorbit.io</span>
                </Link>
                <Link to="/logout">
                  <span>Sign out</span>
                </Link>
              </div>
            }
          />
        </div>
      )}
    </div>
  )
}

export default SecondaryNavigation
