import React, {useState, useEffect, useRef, useCallback} from 'react'
import './style.scss'

const Dropdown = (props) => {
  const wrapper = useRef(null)
  const trigger = useRef(null)
  const items = useRef(null)
  const [open, setOpen] = useState(false)

  const maybeToggle = useCallback((event) => {
    const { target } = event
    if (!wrapper.current.contains(target)) {
      return setOpen(false)
    }
    if (trigger.current.contains(target)) {
      return setOpen(!open)
    }
    if (items.current.contains(target) && (target.tagName === 'A' || target.tagName === 'SPAN')) {
      return setOpen(false)
    }
  }, [open])

  useEffect(() => {
    document.addEventListener('click', maybeToggle)
    return () => {
      document.removeEventListener('click', maybeToggle)
    }
  }, [maybeToggle])

  const getExtraClassNames = () => [
    'Dropdown',
    open ? 'Dropdown--open' : '',
    props.className,
  ].join(' ')

  return (
    <div ref={wrapper} className={getExtraClassNames()}>
      <div ref={trigger} className="Dropdown-Toggle">
        {props.trigger}
      </div>
      <div ref={items} className="Dropdown-Items">
        {props.content}
      </div>
    </div>
  )
}

export default Dropdown
