import {doRequest} from './../../utils/xhr'

const getResourcesRequest = async (ownerId) => {
  const scoreboards = await doRequest(`/api/scoreboard/?limit=100&owner=${ownerId}`, 'GET')
    .then(scoreboards => scoreboards.results)
  const leaderboards = await doRequest(`/api/leaderboard/?limit=100&owner=${ownerId}`, 'GET')
    .then(leaderboards => leaderboards.results)

  return {scoreboards, leaderboards}
}

export {getResourcesRequest}