import React, { useContext, useState, useEffect } from 'react'
import { Redirect, Link, useParams } from 'react-router-dom'
import Helmet from 'react-helmet'
import AppContext from './../../../contexts/app'
import { getCRUDRequests } from './../../../utils/xhr'
import { capitalize, pluralize } from './../../../utils/string'
import {getResourceOwnerId} from './../../../utils/crud'
import PageHeader from './../PageHeader'
import CRUDIndex from './../CRUDIndex'
import CRUDItemButtons from './../CRUDItemButtons'
import CRUDCreate from './../CRUDCreate'
import CRUDUpdate from './../CRUDUpdate'
import Message from './../Message'
import './style.scss'

const CRUDable = (props) => {
  const {resource, action, form, title, icon, enableBulkCreate, wizardForm} = props
  const className = capitalize(pluralize(resource))
  const CRUDRequests = getCRUDRequests(`/api/${resource}`)
  const {message, setMessage, profile} = useContext(AppContext)
  const [items, setItems] = useState([])
  const [listLoading, setListLoading] = useState(true)
  const [createError] = useState(null)
  const [updateError] = useState(null)
  const [updateLoading, setUpdateLoading] = useState(true)
  const [item, setItem] = useState(null)
  const [redirect, setRedirect] = useState(null)
  const {id} = useParams()
  const userRoles = [{
    "user": profile.id,
    "role": "owner"
  }]

  useEffect(() => {
    const CRUDList = getCRUDRequests(`/api/${resource}`, {owner: profile.id, limit: 100}).list
    const CRUDRead = getCRUDRequests(`/api/${resource}`, {owner: profile.id}).read
    CRUDList()
      .then((data) => {
        const {results: items} = data || {}
        items && items.sort((a, b) => a.id > b.id ? -1 : 1)
        setItems(items)
        setListLoading(false)
      })
    if (action === 'update') {
      CRUDRead(id)
        .then((item) => {
          if (!item) {
            setRedirect(`/${resource}s`)
            setMessage(`Item not found.`)
            return
          }
          const ownerId = getResourceOwnerId(item)
          if (ownerId !== profile.id) {
            setRedirect(`/${resource}s`)
            setMessage(`You do not authorized to edit the requested resource.`)
            return
          }
          setItem(item)
          setUpdateLoading(false)
        })
    }
  }, [action, id, resource, profile.id, setMessage])

  const createItem = (item) => {
    item["user_roles"] = userRoles
    console.log(item)
    CRUDRequests.create(item).then((data) => {
      setRedirect(`/${resource}s`)
      if (data.error) {
        setMessage('An error has occurred. Please refresh the page and try again.')
      } else {
        setMessage(`${item.title} created!`)
      }
    })
  }

  const bulkCreateItem = (item) => {
    // Used only for bulk creation of scoreboards and leaderboards for comboboards
    item["user_roles"] = userRoles
    console.log(item)
    const CRUDScoreboardRequests = getCRUDRequests(`/api/scoreboard`)
    const CRUDLeaderboardRequests = getCRUDRequests(`/api/leaderboard`)
    // Create leaderboards for each VM
    const createdItems = {}
    const leaderboardTemplate = {
      "is_public": item.is_public,
      "items_per_list": 10,
      "one_score_per_player": item.one_score_per_player,
      "leaderboard_time_type": item.leaderboard_time_type,
      "background_color": item.leaderboard_bg,
      "display_title": false,
      "leaderboard_player_type": "global",
      "players": [],
      "venuemachines": [],
      "leaderboard_machine_type": "global",
      "user_roles": item.user_roles
    }
    if (item.leaderboard_time_type === "time_box") {
      leaderboardTemplate["score_start_time"] = item.score_start_time
      leaderboardTemplate["score_end_time"] = item.score_end_time
    }
    if (item.leaderboard_time_type === "last_calendar") {
      leaderboardTemplate["last_time_bucket"] = item.last_time_bucket
      leaderboardTemplate["last_time_timezone"] = item.last_time_timezone
      leaderboardTemplate["last_time_count"] = item.last_time_count
    }
    if (item.leaderboard_time_type === "last_rolling") {
      leaderboardTemplate["last_time_bucket"] = item.last_time_bucket
      leaderboardTemplate["last_time_count"] = item.last_time_count
    }
    if (item.leaderboard_type === "machine") {
      leaderboardTemplate["leaderboard_machine_type"] = "selected"
    }

    const scoreboardTemplate = {
        "background_color": item.scoreboard_bg,
        "display_title": false,
        "is_public": item.is_public,
        "idle_leaderboard_show": true,
        "idle_leaderboard_sec": item.idle_display_leaderboard,
        "idle_scoreboard_sec": item.idle_display_scoreboard,
        "idle_hotgame_show": false,
        "enablePlayerRank": item.show_live_player_rank,
        "user_roles": item.user_roles
    }

    Promise.all(item.vms.map(async (vm) => {
        const lb = JSON.parse(JSON.stringify(leaderboardTemplate))
        lb["title"] = `${item.title} - ${vm.machine.name} at ${vm.venue.name} - Leaderboard`
        lb["items"] = [{
            "id": item.leaderboard_type === "machine" ? vm.machine.id : vm.id,
            "type": item.leaderboard_type,
            "order": 0
        }]
        const lbData = await CRUDLeaderboardRequests.create(lb)
        if (lbData.error) {
            setMessage('An error has occurred. Please refresh the page and try again.')
            return false
        }
        createdItems[vm.id] = {}
        createdItems[vm.id]["leaderboard"] = lbData.id
        const sb = JSON.parse(JSON.stringify(scoreboardTemplate))
        sb["title"] = `${item.title} - ${vm.machine.name} at ${vm.venue.name} - Scoreboard`
        sb["venuemachine_items"] = [{
          "venuemachine": vm.id,
          "order": 0
        }]
        sb["leaderboard"] = createdItems[vm.id]["leaderboard"]
        const sbData = await CRUDScoreboardRequests.create(sb)
        if (sbData.error) {
            setMessage('An error has occurred. Please refresh the page and try again.')
            return false
        }
        createdItems[vm.id]["scoreboard"] = sbData.id
        return true
    })).then((values) => {
        if (values.filter((v) => v === false).length > 0) {
            // Error occurred, don't bother making the comboboard
            setRedirect(`/${resource}s`)
        } else {
            const cb = {
                "title": item.title,
                "is_public": item.is_public,
                "high_density": item.high_density,
                "items": item.vms.map((vm, index) => {
                    return {
                        "item_type": "scoreboard",
                        "item_id": createdItems[vm.id]["scoreboard"],
                        "order": index
                    }
                }),
                "user_roles": item.user_roles
            }
            CRUDRequests.create(cb).then((data) => {
              setRedirect(`/${resource}s`)
              if (data.error) {
                setMessage('An error has occurred. Please refresh the page and try again.')
              } else {
                setMessage(`${item.title} created!`)
              }
            })
        }
    })
    // CRUDRequests.create(item).then((data) => {
    //   setRedirect(`/${resource}s`)
    //   if (data.error) {
    //     setMessage('An error has occurred. Please refresh the page and try again.')
    //   } else {
    //     setMessage(`${item.title} created!`)
    //   }
    // })
  }

  const updateItem = (item) => {
    item["user_roles"] = userRoles
    CRUDRequests.update(id, item).then((data) => {
      setRedirect(`/${resource}s`)
      if (data.error) {
        setMessage('An error has occurred. Please refresh the page and try again.')
      } else {
        setMessage(`${item.title} updated`)
      }
    })
  }

  const deleteItem = (id, title) => {
    if (!window.confirm('Are you sure?!')) {
      return false
    }

    const item = items.find(item => item.id === id)
    const ownerId = getResourceOwnerId(item)
    if (ownerId !== profile.id) {
      setRedirect(`/${resource}s`)
      setMessage(`You do not authorized to delete the requested resource.`)
    }

    CRUDRequests.delete(id).then(() => {
      const itemsCopy = [...items].filter(item => item.id !== id)
      setItems(itemsCopy)
      setMessage(`${title} deleted!`)
    })
  }

  const renderListItem = (item) => {
    const {id, title} = item
    return (
      <div
        key={id}
        className="ListItem"
      >
        {props.renderListItemLabel
          ? props.renderListItemLabel(item)
          : <div className="ListItem-Label">
              {title}
            </div>
        }
        <CRUDItemButtons
          item={item}
          resource={resource}
          id={id}
          deleteItem={deleteItem}
          title={title}
        />
      </div>
    )
  }

  const getTitle = () => {
    return action === 'index'
      ? `My ${capitalize(pluralize(resource))}`
      : (
          <div>
            <Link to={`/${pluralize(resource)}`}>
              {`My ${capitalize(pluralize(resource))}`}
            </Link>
            {[
              " ",
              "→",
              capitalize(action),
              capitalize(resource),
            ].join(' ')}
          </div>
        )
  }

  const renderCRUDable = () =>
    <div className={`CRUDable ${className}`}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageHeader>
        <img src={icon} alt="" />
        {getTitle()}
      </PageHeader>
      {message &&
        <Message content={message} />
      }
      {action === 'index' &&
        <CRUDIndex
          resource={resource}
          items={items || []}
          loading={listLoading}
          renderListItem={renderListItem}
          enableBulkCreate={enableBulkCreate}
        />
      }
      {action === 'create' &&
        <CRUDCreate
          form={form}
          onSubmit={createItem}
          titleForSubmit={`Create ${capitalize(resource)}`}
          error={createError}
          items={items || []}
        />
      }
      {action === 'wizard' &&
        <CRUDCreate
          form={wizardForm}
          onSubmit={bulkCreateItem}
          titleForSubmit={`Submit ${capitalize(resource)} Wizard`}
          error={createError}
          items={items || []}
        />
      }
      {action === 'update' &&
        <CRUDUpdate
          item={item}
          items={items || []}
          loading={updateLoading}
          form={form}
          onSubmit={updateItem}
          titleForSubmit={`Update ${capitalize(resource)}`}
          error={updateError}
        />
      }
    </div>

  return redirect
    ? <Redirect to={redirect} />
    : renderCRUDable()
}

export default CRUDable
