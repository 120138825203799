import React, { useState, useEffect, useCallback, useRef } from 'react';
import Helmet from 'react-helmet';
import hash from 'object-hash';
import _ from 'lodash';
//import { w3cwebsocket as W3CWebSocket } from "websocket";
import { getScoresWSURL } from './ws';
import ScorbitLogo from './../../../assets/img/scorbit-logo-square.png';
import { getBootstrapRequest, getScoresRequest } from './http';
import DigitCounter from './../../UI/DigitCounter';
import Loading from './../../UI/Loading';
import './style.scss';
import DefaultUserIcon from './../../../assets/img/user-icon/default-user.png';

const wsOptions = {
  connectionTimeout: 1000,
};

let clientWebSocket = null;

const StreamboardViewer = (props) => {
  const { id } = props;
  const [streamboard, setStreamboard] = useState(null);
  const [size, setSize] = useState(null);
  const [scoresData, setScoresData] = useState(null);
  const [totalPlayers, setTotalPlayers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [config, setConfig] = useState(null);
  const [configHash, setConfigHash] = useState(null);
  const [triggerFromCloudflare, setTriggerFromCloudflare] = useState(true);
  
  const poller = useRef(null);

  const lastFetchTime = useRef(null);
  const checkingWebsocketConnection = useRef(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getScores = () => {
    if (poller.current === null) {
      poller.current = setInterval(() => {
        if (!streamboard) {
          return;
        }
        getScoresRequest(id).then((scores) => {
          if (scores.data) {
            const vmIds = streamboard['scoreboard_items'].map((item) => {
              return item.scoreboard['venuemachine_items'][0].venuemachine.id;
            });
            const sortedScores = vmIds.map((id) =>
              scores.data.find((score) => score['venuemachine_id'] === id)
            );
            setScoresData(sortedScores);
            setLoading(false);
          } else {
            clearInterval(poller.current);
            poller.current = null;
            setTriggerFromCloudflare(true);
          }
        });
      }, 5000);

      setTimeout(() => {
        clearInterval(poller.current);
        poller.current = null;
        setTriggerFromCloudflare(true);
      }, 60000);  //Try to connect websocket after 1 min.
    }
  };

  const getConfig = useCallback(() => {
    getBootstrapRequest(props.id).then((data) => {
      if (data.error) {
        setError(data.error);
      } else {
        try {
          let config = JSON.parse(data['config_json']);
          if (!config.layout) config.layout = 'ThreeCam';
          setConfig(config);
          setStreamboard(data);
          setConfigHash(hash(data));
        } catch (exception) {
          console.log(exception);
        }
      }
    });
  }, [props.id]);

  useEffect(() => {
    if (config) {
      const numPlayers = config.scoreboardsConfig.reduce(
        (acc, { numPlayers }) => parseInt(numPlayers) + acc,
        0
      );
      setTotalPlayers(numPlayers);
      setSize(config.size ? config.size : '1280x720');
    }
  }, [config]);

  useEffect(() => {
    getConfig();
  }, []); // eslint-disable-line

  // To avoid CPU get stuck
  // useEffect(() => {
  //   lastFetchTime.current = Date.now();
  //   if (checkingWebsocketConnection.current === false && props.id && clientWebSocket) {
  //     checkingWebsocketConnection.current = true;
  //     setTimeout(() => {
  //       if (Date.now() - lastFetchTime.current >= 5000 && checkingWebsocketConnection.current === true) {
  //         if (clientWebSocket) {
  //           clientWebSocket.send(
  //             JSON.stringify({
  //               type: 'GET_SCORES',
  //               name: `streamboard:${props.id}:scores`,
  //             })
  //           );
  //         }
  //       }
  //       checkingWebsocketConnection.current = false;
  //     }, 5000)
  //   }
  // }, [scoresData]);

  useEffect(() => {
    if (!streamboard || !configHash || !props.id || poller.current || clientWebSocket)
      return;

    const connectWebsocket = (retries = 0) => {
      clientWebSocket = new WebSocket(
        getScoresWSURL(`/streamboard/${props.id}/scores/websocket/?session=${parseInt(Math.random()*1000)%1000}`)
      );

      clientWebSocket.onerror = function () {
        console.log('Connection Error');
      };

      clientWebSocket.onclose = function () {
        console.log("Websocket Connection Closed");
        setTimeout(() => {
          clientWebSocket = null;
          getScores();
          setTriggerFromCloudflare(false);
        }, wsOptions.connectionTimeout);
      };

      clientWebSocket.onopen = () => {
        // Request scores for this board
        console.log("Websocket Connected");
        clientWebSocket.send(
          JSON.stringify({
            type: 'GET_SCORES',
            name: `streamboard:${props.id}:scores`,
          })
        );
      };

      clientWebSocket.onmessage = (message) => {
        if (typeof message.data === 'string') {
          //console.log("Received: '" + message.data + "'");
          if (message.data !== 'null' && message.data !== 'undefined') {
            try {
              const message_data = JSON.parse(message.data);
              if (message_data && message_data.message === 'UNKNOWN') {
                console.error(message_data);
              } else {
                if (message_data.data) {
                  const vmIds = streamboard['scoreboard_items'].map((item) => {
                    return item.scoreboard['venuemachine_items'][0].venuemachine
                      .id;
                  });
                  const sortedScores = vmIds.map((id) =>
                    message_data.data.find(
                      (score) => score['venuemachine_id'] === id
                    )
                  );
                  setScoresData(sortedScores);
                  setLoading(false);
                }
              }
            } catch (exception) {
              console.log(exception);
            }
          }
        }
      };
    };
    connectWebsocket();
  }, [configHash, streamboard, props.id, getScores, poller.current]);

  const getGameMode = (gameModeArr) => {
    if (!gameModeArr) return null;
    let gameMode = gameModeArr.slice(-1).pop();
    gameMode = gameMode.split(':');
    let color = gameMode[0].match(/\{(.*?)\}/);
    if (color) {
      color = color[1];
    } else {
      color = '#8EC1DC';
    }
    return {
      gameMode: gameMode.slice(-1).pop(),
      color: color,
    };
  };

  const getGameRank = (playerIndex, scores) => {
    const score = scores ? _.get(scores[playerIndex], 'score', '--') : 0;
    if (score === '--') {
      return score;
    }
    let rank = 0;
    for (const item of scores) {
      if (score < item['score']) {
        rank++;
      }
    }

    if (rank === 0) {
      return '1st Place';
    } else if (rank === 1) {
      return '2nd Place';
    } else if (rank === 2) {
      return '3rd Place';
    } else if (rank === 3) {
      return '4th Place';
    }
  };

  const renderPlayer = ({ playerIndex, scoreboardIndex }) => {
    if (!scoresData[scoreboardIndex]) return;

    const { players } = config['scoreboardsConfig'][scoreboardIndex];
    const player = players[playerIndex];
    const session = scoresData[scoreboardIndex].session || {};
    const gameModeSetting = getGameMode(session['game_modes']);
    const scores = session['scores'];
    const gameOver = session['settled_on'];
    const ballNum = session['current_ball'];
    const currentPlayerIndex = session['current_player'] - 1;
    const wrapperClassNames = ['Viewer-Streamboard-Player'];
    const isCurrentPlayer = currentPlayerIndex === playerIndex && !gameOver;

    if (isCurrentPlayer) {
      wrapperClassNames.push(wrapperClassNames[0] + '--Current');
    }
    const infoClassNames = ['Viewer-Streamboard-Player-Info'];
    if (player && player.playerInfo) {
      infoClassNames.push(infoClassNames[0] + '--HasExtra');
    }
    let playerName =
      player && player.playerName
        ? player.playerName
        : `Player ${playerIndex + scoreboardIndex + 1}`;
    if (
      player &&
      !player.playerName &&
      scores && [playerIndex] &&
      scores[playerIndex] &&
      scores[playerIndex].player
    ) {
      playerName = scores[playerIndex].player['cached_display_name'];
    }
    const score = scores ? _.get(scores[playerIndex], 'score', '--') : 0;

    let fullName = '';
    let profilePicture = DefaultUserIcon;
    if (
      scores && [playerIndex] &&
      scores[playerIndex] &&
      scores[playerIndex].player
    ) {
      fullName =
        scores[playerIndex].player['first_name'] +
        ' ' +
        scores[playerIndex].player['last_name'];
      if (scores[playerIndex].player['profile_picture'])
        profilePicture = scores[playerIndex].player['profile_picture'];
    }
    const rank = getGameRank(playerIndex, scores);
    if (
      config &&
      (config.layout === 'LowerThirdHorizontal' ||
        config.layout === 'LowerThirdVertical')
    ) {
      return (
        <div
          className={wrapperClassNames.join(' ')}
          key={`${playerIndex}-${scoreboardIndex}`}
        >
          <div className={infoClassNames.join(' ')}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img className="Avatar" src={profilePicture} alt="" />
              <div className="LowerThirdContent">
                <div className="LowerThirdContent--PlayerNumber LowerThirdContent--Flex">
                  <div>
                    {gameOver ? 'FINAL' : 'Player #' + (playerIndex + 1)}
                  </div>
                  <div className="LowerThirdContent--PlayerRank">{rank}</div>
                </div>
                <div className="LowerThirdContent--PlayerNameRank LowerThirdContent--Flex">
                  <div
                    className={`LowerThirdContent--PlayerName ${
                      gameOver ? 'LowerThirdContent--PlayerName-Gameover' : ''
                    }`}
                  >
                    {playerName}
                  </div>
                  {!gameOver && isCurrentPlayer && (
                    <div className="Viewer-Streamboard-Player-Status-Ball LowerThirdContent--Ball">
                      {ballNum}
                    </div>
                  )}
                </div>
                <div className="LowerThirdContent--PlayerFullName LowerThirdContent--Flex">
                  {fullName}
                </div>
                <div className="LowerThirdContent--PlayerInfo">
                  {player && player.playerInfo && (
                    <div>{player.playerInfo}</div>
                  )}
                </div>

                <div
                  className={`LowerThirdContent--Score ${
                    gameOver ? 'LowerThirdContent--Score-Gameover' : ''
                  }`}
                >
                  <div className={'LowerThirdContent--Flex'}>
                    {isCurrentPlayer ? (
                      <div style={{ fontSize: '1.5em' }}>
                        <DigitCounter score={score} />
                      </div>
                    ) : (
                      <span>{score.toLocaleString()}</span>
                    )}
                  </div>
                </div>
                <div
                  className={`LowerThirdContent--Gamemode LowerThirdContent--Flex`}
                >
                  {gameModeSetting && isCurrentPlayer && (
                    <div style={{ color: gameModeSetting.color }}>
                      {gameModeSetting.gameMode}
                    </div>
                  )}
                  {isCurrentPlayer && !gameModeSetting && (
                    <div>&nbsp;</div>
                  )}
                  {!isCurrentPlayer && gameOver && (
                    <div>Game Over</div>
                  )}
                  {!isCurrentPlayer && !gameOver && (
                    <div>Game On</div>
                  )}
                  {player && player.playerAddress && (
                    <div className="LowerThirdContent--PlayerAddress">
                      <span className="material-icons">location_on</span>&nbsp;
                      {player.playerAddress}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={wrapperClassNames.join(' ')}
          key={`${playerIndex}-${scoreboardIndex}`}
        >
          <div className={infoClassNames.join(' ')}>
            <div className="Viewer-Streamboard-Player-Info-Name">
              {playerName}
            </div>
            {player && player.playerInfo && (
              <div className="Viewer-Streamboard-Player-Info-Extra">
                {player.playerInfo}
              </div>
            )}
          </div>
          {gameModeSetting && (
            <div
              className="Viewer-Streamboard-Player-Gamemode"
              style={{ color: gameModeSetting.color }}
            >
              {gameModeSetting.gameMode}
            </div>
          )}
          <div className="Viewer-Streamboard-Player-Status">
            {!gameOver && (
              <div className="Viewer-Streamboard-Player-Status-Ball">
                {ballNum}
              </div>
            )}
          </div>
          <div className="Viewer-Streamboard-Player-Score">
            {isCurrentPlayer ? (
              <DigitCounter score={score} />
            ) : (
              <span>{score.toLocaleString()}</span>
            )}
          </div>
        </div>
      );
    }
  };

  const renderBranding = () => (
    <div className="Viewer-Streamboard-Branding">
      <img
        className="Viewer-Streamboard-Branding-Logo"
        src={ScorbitLogo}
        alt=""
      />
      <div>
        <span>Live scores powered by Scorbit</span>
        <span>scorbit.io</span>
      </div>
    </div>
  );

  const renderCams = () => {
    return (
      <div
        className={`Viewer-Streamboard-Cams Viewer-Streamboard-Cams--${config.layout}`}
      >
        <div className="Cam Cam--1" />
        <div className="Cam Cam--2" />
        <div className="Cam Cam--3" />
        <div className="Cam Cam--4" />
      </div>
    );
  };

  const renderScores = () => {
    const backglassArtUrl =
      streamboard['scoreboard_items'][0]['scoreboard']['venuemachine_items'][0][
        'venuemachine'
      ]['machine']['backglass_art'];
    let scoreStyle = {};
    if (config && (config.layout === 'LowerThirdHorizontal' || config.layout === 'LowerThirdVertical')) {
      scoreStyle = {
        backgroundImage: `url(${backglassArtUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      };
    }
    return (
      <div
        className="Viewer-Streamboard-Scores"
        style={scoreStyle}
      >
        {streamboard['scoreboard_items'].map((scoreboard, scoreboardIndex) => {
          if (!config['scoreboardsConfig'][scoreboardIndex]) {
            return null;
          }
          const { numPlayers } = config['scoreboardsConfig'][scoreboardIndex];
          let playerIndices = Array.from(Array(parseInt(numPlayers)).keys());
          const session = scoresData[scoreboardIndex].session || {};
          if (session['settled_on']) {
            session['scores'] = session['scores'].sort((a, b) => {
              return b.score - a.score;
            });
            playerIndices = [];
            for (const score of session['scores']) {
              playerIndices.push(score['position'] - 1);
            }
            session['scores'] = session['scores'].sort((a, b) => {
              return a.position - b.position;
            });
          }
          return playerIndices.map((playerIndex) => {
            return renderPlayer({ playerIndex, scoreboardIndex });
          });
        })}
      </div>
    );
  };

  const renderStyles = () => {
    return (
      <style>
        .Viewer-Streamboard-Player:nth-child(odd){' '}
        {`{
          border-right: 2px solid ${config.backgroundColor};
        }`}
        .Viewer-Streamboard--ThreePlayer
        .Viewer-Streamboard-Player:nth-child(1),
        .Viewer-Streamboard--ThreePlayer
        .Viewer-Streamboard-Player:nth-child(2), .Viewer-Streamboard--FourPlayer
        .Viewer-Streamboard-Player:nth-child(1), .Viewer-Streamboard--FourPlayer
        .Viewer-Streamboard-Player:nth-child(2){' '}
        {`{
          border-bottom: 2px solid ${config.backgroundColor};
        }`}
        , .Viewer-Streamboard-Player .Viewer-Streamboard-Player-Gamemode
        {`{
          display: none;
        }`}
        .Viewer-Streamboard-Player--Current .Viewer-Streamboard-Player-Gamemode
        {`{
          display: flex;
        }`}
        .Viewer-Streamboard-Player--Current .Viewer-Streamboard-Player-Score,
        .Viewer-Streamboard-Player--Current .Viewer-Streamboard-Player-Info
        {`{
          color: ${config.highlightColor};
        }`}
      </style>
    );
  };

  const renderStatus = () => {
    return (
      <div className={triggerFromCloudflare ? "status-cloudflare" : "status-heroku"}></div>
    );
  }

  const renderStreamboard = () => {
    const style = {
      backgroundColor: config.backgroundColor,
    };
    let classNames = [
      'Viewer-Streamboard',
      `Viewer-Streamboard--${config.layout}`,
    ];
    if (totalPlayers === 1) {
      classNames.push('Viewer-Streamboard--OnePlayer');
    } else if (totalPlayers === 2) {
      classNames.push('Viewer-Streamboard--TwoPlayer');
    } else {
      classNames.push('Viewer-Streamboard--FourPlayer');
    }
    classNames.push(`Viewer-Streamboard--${size}`);

    return (
      <div className={classNames.join(' ')} style={style}>
        <Helmet>
          <title>ScorbitVision - Streamboard - {streamboard.title}</title>
        </Helmet>
        {renderCams()}
        {renderBranding()}
        {renderScores()}
        {renderStyles()}
        {renderStatus()}
      </div>
    );
  };

  const render = () => {
    if (error) {
      return <div className="ViewerError">{error}</div>;
    }

    return loading ? (
      <div className="Viewer-Resource">
        <Loading />
      </div>
    ) : (
      renderStreamboard()
    );
  };

  return render();
};

export default StreamboardViewer;
