import React from 'react'
import CRUDable from './../UI/CRUDable'
import Form from './form'
import SlideshowsIcon from './../../assets/img/nav-icons/slideshows.svg'
import './style.scss'

const Slideshows = (props) => {
  return (
    <CRUDable
      title="ScorbitVision - My Slideshows"
      className="Slideshows"
      resource="slideshow"
      form={Form}
      icon={SlideshowsIcon}
      {...props}
    />
  )
}

export default Slideshows

