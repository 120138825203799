import Cookies from 'universal-cookie'
import {doRequest} from './../../utils/xhr'

const cookies = new Cookies()

const doBootstrapRequest = async () => {
  const profile = await doRequest('/api/user_profile/get/')

  if (!profile) {
    if (window.location.host.endsWith('scorbit.io')) {
      cookies.set('token', '', {domain: '.scorbit.io'})
    } else if (window.location.host.endsWith('pages.dev')) {
      cookies.set('token', '', {domain: '.scorbitvision.pages.dev'})
    } else {
      cookies.set('token', '') 
    }
    return window.location.href = '/'
  }

  const venuesUrl = `/api/venue/?owner=${profile.id}&limit=100`
  const venues = await doRequest(venuesUrl)
    .then(response => response.results)

  const venueMachinesUrl = `/api/venuemachine/?owner=${profile.id}&limit=500`
  const venueMachines = await doRequest(venueMachinesUrl)
    .then(response => response.results)

  return {profile, venues, venueMachines}
}

export {doBootstrapRequest}
