import {
  getAPIURL,
  getXHROptions,
  handleErrors
} from './../../../utils/xhr'

const getBootstrapRequest = (id) => {
  return fetch(getAPIURL(`/api/slideshow/${id}/`), getXHROptions().GET)
    .then(handleErrors)
    .then(response => response.json())
    .catch(error => {return {error: error.toString()}})
}

export {getBootstrapRequest}