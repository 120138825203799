import Cookies from "universal-cookie";
const cookies = new Cookies();

const getAPIURL = (path) => {
  return `${process.env.REACT_APP_API_URL}${path}`;
};

const getScoreAPIURL = (path) => {
  return `${process.env.REACT_APP_SCORE_URL}${path}`;
};

const getVIEWERAPIURL = (path) => {
  return `${process.env.REACT_APP_VIEWER_API_URL}${path}`;
};

const getXHROptions = (payload = {}) => {
  const token = cookies.get("token");
  const body = JSON.stringify(payload);
  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers["Authorization"] = `Token ${token}`
  }

  return {
    GET: { method: "get", headers },
    POST: { method: "post", headers, body },
    PUT: { method: "put", headers, body },
    DELETE: { method: "delete", headers },
  };
};

const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(`Request rejected with status ${response.status}`);
  }
  else {
    return response;
  }
};

const doRequest = (
  url,
  method = "GET",
  payload = {},
  handleError = () => {}
) => {
  if (process.env.NODE_ENV === "development") {
    // console.log("doRequest", method, url, method !== 'GET' ? payload : '');
  }
  return fetch(getAPIURL(url), getXHROptions(payload)[method])
    .then((response) => {
      if (response.status === 401) {
        if (window.location.host.endsWith('scorbit.io')) {
          cookies.set('token', '', {domain: '.scorbit.io'})
        } else if (window.location.host.endsWith('pages.dev')) {
          cookies.set('token', '', {domain: '.scorbitvision.pages.dev'})
        } else {
          cookies.set('token', '') 
        }
        return window.location.href = '/'
      }
      else if (!response.ok || response.status.toString().match(/^4/)) {
        response.json().then((response) => handleError(response));
        throw Error(`Request rejected with status ${response.status}`);
      }
      else {
        return response;
      }
    })
    .then((response) => (method !== "DELETE" ? response.json() : null))
    .catch((error) => {
      console.error(error)
      return {'error': 'An error has occurred.'}
    });
};

const doRequestWithFile = (
  url,
  method = "GET",
  formData,
  handleError = () => {}
) => {
  const options = { method: method, body: formData, redirect: 'follow' };
  const token = cookies.get("token");
  if (token) {
    options['headers'] = new Headers({
      Authorization: `Token ${token}`,
    })
  }
  return fetch(getAPIURL(url), options)
    .then((response) => {
      if (response.status === 401) {
        if (window.location.host.endsWith('scorbit.io')) {
          cookies.set('token', '', {domain: '.scorbit.io'})
        } else if (window.location.host.endsWith('pages.dev')) {
          cookies.set('token', '', {domain: '.scorbitvision.pages.dev'})
        } else {
          cookies.set('token', '') 
        }
        return window.location.href = '/'
      }
      else if (!response.ok || response.status.toString().match(/^4/)) {
        response.json().then((response) => handleError(response));
        throw Error(`Request rejected with status ${response.status}`);
      }
      else {
        return response;
      }
    })
    .then((response) => (method !== "DELETE" ? response.json() : null))
    .catch((error) => {
      console.error(error)
      return {'error': 'An error has occurred.'}
    });
};

const getCRUDRequests = (baseUrl, args = {owner: null, public: null, limit: null}) => {
  const queryParams = []
  if (args.owner) {
    queryParams.push(`owner=${args.owner}`)
  }
  if (args.public === true) {
    queryParams.push(`is_public=true`)
  }
  if (args.limit) {
    queryParams.push(`limit=${args.limit}`)
  }
  if (args.ordering) {
    queryParams.push(`ordering=${args.ordering}`)
  }
  return {
    list: () =>
      doRequest(`${baseUrl}/?${queryParams.join("&")}`, "GET"),
    read: (id) =>
      doRequest(`${baseUrl}/${id}/`, "GET"),
    create: (item) =>
      doRequest(`${baseUrl}/`, "POST", item),
    update: (id, item) =>
      doRequest(`${baseUrl}/${id}/`, "PUT", item),
    delete: (id) =>
      doRequest(`${baseUrl}/${id}/`, "DELETE"),
  };
};

export { getAPIURL, getScoreAPIURL, getVIEWERAPIURL, getXHROptions, handleErrors, doRequest, doRequestWithFile, getCRUDRequests };
