import React, {useState, useEffect} from 'react'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {ErrorBoundary} from 'react-error-boundary'
import Cookies from 'universal-cookie'
import AppContext from './../../contexts/app.js'
import Login from './../Login'
import Error from  './../UI/AppError'
import Loading from './../UI/Loading'
import SecondaryNavigation from './../UI/SecondaryNavigation'
import AppContent from './../UI/AppContent'
import PrimaryNavigation from './../UI/PrimaryNavigation'
import {ScorbitVisionRoutes, MachineToolsRoutes} from './routes'
import {doBootstrapRequest} from './http'
import ScorbitVisionLogo from './../../assets/img/scorbit-vision-logo.svg'
import MachineToolsLogo from './../../assets/img/scorbit-machine-tools-logo.svg'
import ScoreboardsIconSimple from './../../assets/img/nav-icons/scoreboards-simplified.svg'
import LeaderboardsIconSimple from './../../assets/img/nav-icons/leaderboards-simplified.svg'
import StreamboardsIconSimple from './../../assets/img/nav-icons/streamboards-simplified.svg'
import SlideshowsIconSimple from './../../assets/img/nav-icons/slideshows-simplified.svg'
import ComboboardsIconSimple from './../../assets/img/nav-icons/comboboards-simplified.svg'

import './style.scss'

// redirect http to https
const {NODE_ENV} = process.env
const {protocol, host, href} = document.location
const isSecure = protocol.match(/^https/) || NODE_ENV.match(/development/)
if (!isSecure) {
  document.location.href = href.replace(/^http/, 'https')
}

// configure "subApp"
const subAppConfigs = {
  ScorbitVision:{
    title: 'Scorbit Vision',
    logo: ScorbitVisionLogo,
    routes: ScorbitVisionRoutes,
    navItems: [
      {href: '/scoreboards', label: 'Scoreboards', icon: ScoreboardsIconSimple},
      {href: '/leaderboards', label: 'Leaderboards', icon: LeaderboardsIconSimple},
      {href: '/comboboards', label: 'Comboboards', icon: ComboboardsIconSimple},
      {href: '/slideshows', label: 'Slideshows', icon: SlideshowsIconSimple},
      {href: '/streamboards', label: 'Streamboards', icon: StreamboardsIconSimple},
    ],
  },
  MachineTools: {
    title: 'Scorbit Machine Tools', 
    logo: MachineToolsLogo,
    routes: MachineToolsRoutes,
    navItems: [
      {href: '/license-manager', label: 'My Machines', icon: 'poll'},
    ],
  },
}
const subAppConfig = host.match(/tools|machinetools/)
  ? subAppConfigs.MachineTools
  : subAppConfigs.ScorbitVision

const App = () => {
  const cookies = new Cookies()
  const [loggedIn, setLoggedIn] = useState(null)
  const [profile, setProfile] = useState({})
  const [loading, setLoading] = useState(true)
  const [message, setMessage] = useState(null)
  const [userVenues, setUserVenues] = useState(null)
  const [userVenueMachines, setUserVenueMachines] = useState(null)

  useEffect(() => {
    const cookies = new Cookies()
    if (cookies.get('token')) {
      setLoggedIn(true)
      doBootstrapRequest()
        .then(data => {
          setProfile(data.profile)
          setUserVenues(data.venues)
          setUserVenueMachines(data.venueMachines)
          setLoading(false)
        })
    } else {
      setLoading(false)
      setLoggedIn(false)
    }
  }, [])

  const handleLogin = (token) => {
    if (window.location.host.endsWith('scorbit.io')) {
      cookies.set('token', token, {domain: '.scorbit.io'})
    } else if (window.location.host.endsWith('pages.dev')) {
      cookies.set('token', token, {domain: '.scorbitvision.pages.dev'})
    } else {
      cookies.set('token', token) 
    }
    window.location.reload()
  }

  const clearMessage = () =>
    setMessage(null)

  const scrollToTop = () =>
    document.querySelector('.AppContent').scrollTop = 0

  const getContext = () => {
    return {
      profile,
      userVenues,
      userVenueMachines,
      setMessage,
      clearMessage,
      setProfile,
      message,
      scrollToTop,
    }
  }

  const renderLogin = () =>
    <Login
      title={subAppConfig.title}
      onLogin={handleLogin}
    />

  const renderLoading = () =>
    <div className="ScorbitDashboard ScorbitDashboard--loading">
      <Loading />
    </div>

  const renderStandAlone = () =>
    <Router>
      <ErrorBoundary
        FallbackComponent={Error}
        onError={(error, stack) => {
          console.error(error)
          console.error(stack)
        }}
      >
        <AppContext.Provider value={getContext()}>
          <Switch>
            {subAppConfig.routes.standAlone}
          </Switch>
        </AppContext.Provider>
      </ErrorBoundary>
    </Router>

  const renderApp = () =>
    <Router>
      <div className="ScorbitDashboard">
        <ErrorBoundary
          FallbackComponent={Error}
          onError={(error, stack) => {
            console.error(error)
            console.error(stack)
          }}
        >
          <PrimaryNavigation
            logo={subAppConfig.logo}
            navItems={subAppConfig.navItems}
          />
          <SecondaryNavigation profile={profile} />
          <AppContext.Provider value={getContext()}>
            <AppContent>
              <Switch>{subAppConfig.routes.app}</Switch>
            </AppContent>
          </AppContext.Provider>
        </ErrorBoundary>
      </div>
    </Router>

  const renderAppComponent = () => {
    const {pathname} = window.location
    const isStandAlone = pathname.match(/^\/viewer/)
    const requiresAuth = !pathname.match(/^\/viewer/)

    if (loading) {
      return renderLoading()
    }
    else if (!loggedIn && !requiresAuth) {
      return renderStandAlone()
    }
    else if (!loggedIn) {
      return renderLogin()
    }
    else if (isStandAlone) {
      return renderStandAlone()
    }
    else {
      return renderApp()
    }
  }

  return isSecure
    ? <div className="App">
        {renderAppComponent()}
      </div>
    : null
}

export default App
