import React, {useState, useRef, useEffect} from 'react'
import {Link} from 'react-router-dom'
import Loading from './../Loading'

const CRUDFormButtons = (props) => {
  const submitButton = useRef(null)
  const submitButtonClone = useRef(null)
  const [submitButtonWidth, setSubmitButtonWidth] = useState('auto')
  const buttonText = props.titleForSubmit
    ? props.titleForSubmit
    : 'Submit'
  const disableSubmit = props.disableSubmit
    ? props.disableSubmit
    : false
  const cancelHref = props.cancelHref

  useEffect(() => {
    setSubmitButtonWidth(submitButtonClone.current.offsetWidth)
  }, [])

  return (
    <div className="formRow formRow--buttons flexRow">
      <button
        ref={submitButton}
        type="submit"
        disabled={disableSubmit}
        className="Button"
        style={{width: `${submitButtonWidth + 20}px`}}
      >
        {props.busy ? <Loading /> : buttonText}
      </button>
      <Link
        to={cancelHref}
        className="Button Button--secondary"
      >
        Cancel
      </Link>
      <div>
        <button
          ref={submitButtonClone}
          className="Button"
          disabled={true}
          style={{visibility: 'hidden'}}
        >
          {buttonText}
        </button>
      </div>
    </div>
  )
}

export default CRUDFormButtons
