import React, { Fragment, useEffect, useState, useRef } from 'react';

import HotgameType1 from './../../../assets/img/hotgame-layouts/hotgame-space-invaders.gif'
import HotgameType2 from './../../../assets/img/hotgame-layouts/hotgame-color-bars.gif'
import HotgameType3 from './../../../assets/img/hotgame-layouts/hotgame-dead-television-channel.gif'
import HotgameType4 from './../../../assets/img/hotgame-layouts/hotgame-pinball.gif'
import HotgameTypeRandom from './../../../assets/img/hotgame-layouts/hotgame-random.png'

const HotgameType = ({ type, viewerType }) => {
  const hotgameType = [
    {
      label: 'Random',
      img: HotgameTypeRandom,
    },
    {
      label: 'Space Invaders',
      img: HotgameType1,
    },
    {
      label: 'Color Bars',
      img: HotgameType2,
    },
    {
      label: 'Dead Television Channel',
      img: HotgameType3,
    },
    {
      label: 'Atari 2600 Pinball',
      img: HotgameType4,
    }
  ]
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const typeIndex = type !== 0 ? type : getRandomInt(1, 3);
  return (
    viewerType === 'comboboard' ?
      <div className="Hotgame-No-Available" style={{ backgroundImage: `url(${hotgameType[typeIndex].img})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
      :      
      <div className="Hotgame-No-Available">
        <img src={hotgameType[typeIndex].img} alt="" style={{ height: '100%', width: '100%' }} />
      </div>
  );
};

export default HotgameType;
