import React, {Fragment, useState, useContext, useEffect, useCallback} from 'react'
import Select from 'react-select'
import AppContext from './../../contexts/app'
import {getResourcesRequest} from './http'
import CRUDFormButtons from './../UI/CRUDFormButtons'
import CRUDTitle from './../UI/CRUDTitle'
import CRUDPublicPrivate from './../UI/CRUDPublicPrivate'
import Loading from './../UI/Loading'

const Form = (props) => {
  const newItemTemplate = {
    type: 'scoreboard',
    itemId: null,
  }
  const defaultItems = [{...newItemTemplate}, {...newItemTemplate}]
  const {profile, scrollToTop} = useContext(AppContext)
  const [action, setAction] = useState(null)
  const [validationError, setValidationError] = useState(null)
  const [title, setTitle] = useState(null)
  const [isPublic, setIsPublic] = useState(false)
  const [prevTitle, setPrevTitle] = useState(null)
  const [busy, setBusy] = useState(false)
  const [scoreboards, setScoreboards] = useState(null)
  const [scoreboardsOptions, setScoreboardsOptions] = useState([])
  const [leaderboards, setLeaderboards] = useState(null)
  const [leaderboardsOptions, setLeaderboardsOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState(defaultItems)
  const [highDensity, setHighDensity] = useState(false);

  const mapPropsToState = useCallback(() => {
    const {item} = props
    setAction(item ? 'update' : 'create')
    if (!item) return
    setTitle(item["title"])
    setPrevTitle(item["title"])
    setIsPublic(item["is_public"])
    setHighDensity(item["high_density"])
    setItems(item.items.map(item => ({
      type: item["item_type"],
      itemId: item["item_id"],
    })))
  }, [props])
  useEffect(mapPropsToState, [props.item])

  const getResources = useCallback(() => {
    getResourcesRequest(profile.id)
    .then(resources => {
      const {scoreboards, leaderboards} = resources
      const makeOption = (item) => {
        return {
          value: item.id,
          label: item.title,
        }
      }
      setScoreboardsOptions([...scoreboards.map(makeOption)])
      setLeaderboardsOptions([...leaderboards.map(makeOption)])
      setScoreboards(scoreboards)
      setLeaderboards(leaderboards)
      setLoading(false)
    })
  }, [profile.id])
  useEffect(getResources, [getResources])

  const validate = () => {
    const titles = props.items.map(item => item.title)
    const isDupe = titles.filter(item => item === title).length ? true : false
    let validationError = ''

    // validate title not blank
    if (!title || !title.length) {
      validationError = 'Title must not be blank'
    }

    // validate unique title when action is create
    else if (action === 'create' && isDupe) {
      validationError = 'Title must be unique'
    }

    // validate unique title when action is update
    else if (action === 'update' && isDupe && title !== prevTitle) {
      validationError = 'Title must be unique'
    }

    // validate at leaset two items
    else if (items.length < 2) {
      validationError = 'A comboboard must have at least two items.'
    }

    // every item must have an item
    else if (items.filter(item => item.itemId === null).length) {
      validationError = 'Each item must have a scoreboard or leaderboard'
    }

    // every item must be unique
    else if ([...new Set(items.map(item => `${item.type}${item.itemId}`))].length < items.length) {
      validationError = 'Each item must be unique'
    }

    // show error or proceed
    if (validationError.length) {
      setValidationError(validationError)
      return false
    } else {
      setValidationError(null)
      return true
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    setBusy(true)
    if (validate()) {
      const slideshowItems = items.map((item, i) => ({
        "item_type": item.type,
        "item_id": item.itemId,
        "order": i,
      }))

      const slideshow = {
        "title": title,
        "is_public": isPublic,
        "high_density": highDensity,
        "items": slideshowItems,
      }

      props.onSubmit(slideshow)
    } else {
      setBusy(false)
      scrollToTop()
    }
  }

  const renderSelect = ({key, type, value, options, onChange}) =>
    <div className="formRow">
      <div>
        <label>
          {type === 'scoreboard' && 'Select Scoreboard'}
          {type === 'leaderboard' && 'Select Leaderboard'}
        </label>
      </div>
      <Select
        key={key}
        className="react-select"
        classNamePrefix="react-select"
        value={value}
        onChange={(option) => onChange(key, option)}
        options={options}
      />
    </div>

  const renderScoreboardsSelect = (i) => {
    const getSelectedOption = () => {
      if (!items[i].itemId) {
        return null
      } else {
        return scoreboardsOptions.find(option => option.value === items[i].itemId)
      }
    }
    const onChange = (i, option) => {
      changeItemItem(i, 'scoreboard', option)
    }

    return renderSelect({
      key: i,
      value: getSelectedOption(),
      options: scoreboardsOptions,
      onChange: onChange,
      type: 'scoreboard',
    })
  }

  const renderLeaderboardsSelect = (i) => {
    const getSelectedOption = () => {
      if (!items[i].itemId) {
        return null
      } else {
        return leaderboardsOptions.find(option => option.value === items[i].itemId)
      }
    }
    const onChange = (i, option) => {
      changeItemItem(i, 'leaderboard', option)
    }

    return renderSelect({
      key: i,
      value: getSelectedOption(),
      options: leaderboardsOptions,
      onChange: onChange,
      type: 'leaderboard',
    })
  }

  const createItem = (event) => {
    event.preventDefault()
    setItems([...items, {
      type: 'scoreboard',
      itemId: null,
    }])
  }

  const deleteItem = (event, i) => {
    event.preventDefault()
    const nextItems = [...items]
    nextItems.splice(i, 1)
    setItems(nextItems)
  }

  const changeItemItem = (i, type, option) => {
    const nextItems = [...items]
    nextItems[i].type = type
    nextItems[i].itemId = option.value
    setItems(nextItems)
  }

  const setItemType = (i, type) => {
    const nextItems = [...items]
    nextItems[i].type = type
    nextItems[i].itemId = null
    setItems(nextItems)
  }

  const reorderItems = (event, i, direction) => {
    event.preventDefault()
    const nextItems = [...items]
    const x = i
    const y = direction === 'up' ? i - 1 : i + 1
    nextItems[x] = nextItems.splice(y, 1, nextItems[x])[0]
    setItems(nextItems)
  }

  const renderItem = (item, i) =>
    <div
      key={i}
      className="formRow item"
    >
      <div className="formRow flexRow item-type-buttons">
        <div className="item-type flexRow">
          <label>Type:</label>
          <label htmlFor={`itemTypeScoreboard-${i}`}>
            <input
              id={`itemTypeScoreboard-${i}`}
              type="radio"
              name={`itemTypeScoreboard-${i}`}
              checked={item.type === 'scoreboard'}
              onChange={() => setItemType(i, 'scoreboard')}
            />
            Scoreboard
          </label>
          <label htmlFor={`itemTypeLeaderboard-${i}`}>
            <input
              id={`itemTypeLeaderboard-${i}`}
              type="radio"
              name={`itemTypeLeaderboard-${i}`}
              checked={item.type === 'leaderboard'}
              onChange={() => setItemType(i, 'leaderboard')}
            />
            Leaderboard
          </label>
        </div>
        <div className="item-buttons">
          {items.length >= 2 && i > 0 &&
            <button
              className="Button Button--secondary Button--small"
              onClick={(event) => reorderItems(event, i, 'up')}
              title={'move up'}
            >
              <i className="material-icons">arrow_upward</i>
            </button>
          }
          {items.length >= 2 && i < items.length - 1 &&
            <button
              className="Button Button--secondary Button--small"
              onClick={(event) => reorderItems(event, i, 'down')}
              title={'move down'}
            >
              <i className="material-icons">arrow_downward</i>
            </button>
          }
          {items.length > 2 &&
            <button
              className="Button Button--secondary Button--small"
              onClick={(event) => deleteItem(event, i)}
              title={'delete'}
            >
              <i className="material-icons">close</i>
            </button>
          }
        </div>
      </div>
      {item.type === 'scoreboard' && renderScoreboardsSelect(i)}
      {item.type === 'leaderboard' && renderLeaderboardsSelect(i)}
    </div>

  const renderForm = () =>
    <form
      onSubmit={onSubmit}
      className="has-required-fields"
    >
      {validationError &&
        <div className="formError">{validationError}</div>
      }
      <div className="formRow">
        <CRUDTitle
          title={title}
          setTitle={setTitle}
        />
      </div>
      <div className="formRow">
        <CRUDPublicPrivate
          isPublic={isPublic}
          setIsPublic={setIsPublic}
        />
      </div>
      <div className="formRow">
        <label className="primary">
          <span>High Density</span>
        </label>

        <div className="flexRow">
          <label htmlFor="enableHighDensity">
            <input
              id="enableHighDensity"
              type="radio"
              name="highDensity"
              checked={highDensity}
              onChange={() => setHighDensity(true)}
            />
            Enable
          </label>
          <label htmlFor="disableHighDensity">
            <input
              id="disableHighDensity"
              type="radio"
              name="highDensity"
              checked={!highDensity}
              onChange={() => setHighDensity(false)}
            />
            Disable
          </label>
        </div>
      </div>

      <div className="formRow">
        <label className="primary">Items</label>
        {items.length < 2 &&
          <Fragment>
            {renderItem()}
            {renderItem()}
          </Fragment>
        }
        {items.map(renderItem)}
      </div>
      {((highDensity && items.length < 20) || (!highDensity && items.length < 6)) &&
        <div className="formRow">
          <button
            className="Button Button--secondary"
            onClick={createItem}
          >
            Add Item
          </button>
        </div>
      }
      <CRUDFormButtons
        busy={busy}
        disableSubmit={busy}
        titleForSubmit={props.titleForSubmit}
        cancelHref="/comboboards"
      />
    </form>

  const renderNoResources = () =>
    <p>
      You must create at least one scoreboard or leaderboard to use this feature.
    </p>

  return loading
    ? <Loading />
    : !scoreboards && !leaderboards
      ? renderNoResources()
      : renderForm()
}

export default Form
