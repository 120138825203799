import {doRequest} from './../../../utils/xhr'

const doVenueMachinesByUserRequest = (userId) => {
  const url = `/api/venuemachine/?owner=${userId}&limit=500`
  return doRequest(url, 'GET')
    .then(response => response.results)
}

const doVenueMachinesSearchRequest = (searchTerm) => {
  let url = `/api/venuemachine/?search=${searchTerm}&limit=100`
  return doRequest(url, 'GET')
    .then(response => response.results)
}

const doVenueMachineByIdRequest = (id) => {
  const url = `/api/venuemachine/${id}/`
  return doRequest(url, 'GET')
}

export {doVenueMachinesSearchRequest, doVenueMachinesByUserRequest, doVenueMachineByIdRequest}