import {doRequest, doRequestWithFile} from './../../utils/xhr'

const getResourcesRequest = async (ownerId) => {
  const scoreboards = await doRequest(`/api/scoreboard/?limit=100&owner=${ownerId}`, 'GET')
    .then(response => response.results)
  const leaderboards = await doRequest(`/api/leaderboard/?limit=100&owner=${ownerId}`, 'GET')
    .then(response => response.results)
  const comboboards = await doRequest(`/api/comboboard/?limit=100&owner=${ownerId}`, 'GET')
    .then(response => response.results)

  return {scoreboards, leaderboards, comboboards}
}

const uploadSlideShowImageRequest = async (imageFile) => {
  
  var formdata = new FormData();
  formdata.append("image", imageFile);
  const response = await doRequestWithFile(`/api/slideshow_image/`, 'POST', formdata)
    .then(response => response)
  return response
}

const getSlideShowImageRequest = async (id) => {
  const slideShowImage = await doRequest(`/api/slideshow_image/${id}`, 'GET')
    .then(response => response)
  return slideShowImage
}

export {getResourcesRequest, uploadSlideShowImageRequest, getSlideShowImageRequest}