import React from "react"
import { Route } from "react-router-dom"
import App404 from "./../UI/App404"
import SVHome from "./../SVHome"
import Venuemachines from "./../Venuemachines"
import Scoreboards from "./../Scoreboards"
import Leaderboards from "./../Leaderboards"
import Slideshows from "./../Slideshows"
import Streamboards from './../Streamboards'
import Comboboards from './../Comboboards'
import Viewer from "./../Viewer"
import ComboBoard from "./../Viewer/ComboBoard"
import LicenseManager from './../LicenseManager'
import Logout from "./../Logout"
import Profile from "../Profile"

const MachineToolsRoutes = {
  app: [
    <Route
      exact
      path="/"
      key="index"
      render={(props) => <LicenseManager {...props} action="index" />}
    />,
    <Route
      exact
      path="/license-manager"
      key="license-manager"
      render={(props) => <LicenseManager {...props} action="index" />}
    />,
    <Route
      exact
      path="/license-manager/:id/manage"
      key="license-manager-manage"
      render={(props) => <LicenseManager {...props} action="manage" />}
    />,
    <Route
      exact
      path="/license-manager/:id/transfer"
      key="license-manager-transfer"
      render={(props) => <LicenseManager {...props} action="transfer" />}
    />,
    <Route
      exact
      path="/logout"
      key="logout"
      render={() => Logout()}
    />,
    <Route
      path="*"
      key="not-found"
      component={App404}
    />,
  ]
}

const ScorbitVisionRoutes = {
  app: [
    <Route
      exact
      path="/"
      key="index"
      component={SVHome}
    />,
    <Route
      exact
      path="/machines"
      key="machines"
      render={(props) => <Venuemachines {...props} />}
    />,
    <Route
      exact
      path="/scoreboards"
      key="scoreboards"
      render={(props) => <Scoreboards {...props} action="index" />}
    />,
    <Route
      exact
      path="/scoreboards/add"
      key="scoreboards-create"
      render={(props) => <Scoreboards {...props} action="create" />}
    />,
    <Route
      exact
      path="/scoreboards/:id/edit"
      key="scoreboards-update"
      render={(props) => <Scoreboards {...props} action="update" />}
    />,
    <Route
      exact
      path="/leaderboards"
      key="leaderboards"
      render={(props) => <Leaderboards action="index" />}
    />,
    <Route
      exact
      path="/leaderboards/add"
      key="leaderboards-create"
      render={(props) => <Leaderboards action="create" />}
    />,
    <Route
      exact
      path="/leaderboards/:id/edit"
      key="leaderboards-update"
      render={(props) => <Leaderboards {...props} action="update" />}
    />,
    <Route
      exact
      path="/slideshows"
      key="slideshows"
      render={(props) => <Slideshows action="index" />}
    />,
    <Route
      exact
      path="/slideshows/add"
      key="slideshows-create"
      render={(props) => <Slideshows action="create" />}
    />,
    <Route
      exact
      path="/slideshows/:id/edit"
      key="slideshows-update"
      render={(props) => <Slideshows {...props} action="update" />}
    />,
    <Route
      exact
      path="/profile"
      key="profile"
      render={(props) => <Profile />}
    />,
    <Route
      exact
      path="/comboboards"
      key="comboboards"
      render={(props) => <Comboboards action="index" />}
    />,
    <Route
      exact
      path="/comboboards/add"
      key="comboboards-create"
      render={(props) => <Comboboards action="create" />}
    />,
    <Route
      exact
      path="/comboboards/wizard"
      key="comboboards-bulk-create"
      render={(props) => <Comboboards action="wizard" />}
    />,
    <Route
      exact
      path="/comboboards/:id/edit"
      key="comboboards-update"
      render={(props) => <Comboboards {...props} action="update" />}
    />,
    <Route
      exact
      path="/streamboards"
      key="stream-tools"
      render={(props) => <Streamboards action="index" />}
    />,
    <Route
      exact
      path="/streamboards/add"
      key="stream-tools-create"
      render={(props) => <Streamboards action="create" />}
    />,
    <Route
      exact
      path="/streamboards/:id/edit"
      key="stream-tools-update"
      render={(props) => <Streamboards {...props} action="update" />}
    />,
    <Route
      exact
      path="/logout"
      key="logout"
      render={() => Logout()}
    />,
    <Route
      path="*"
      key="not-found"
      component={App404}
    />,
  ],
  standAlone: [
    <Route
      exact
      path="/viewer/streamboards/:id"
      key="streamboards-view"
      render={(props) => <Viewer {...props} type="streamboard" />}
    />,
    <Route
      exact
      path="/viewer/comboboards/:id"
      key="comboboards-view"
      render={(props) => <Viewer {...props} type="comboboard" />}
    />,
    <Route
      exact
      path="/viewer/scoreboards/:id"
      key="scoreboards-view"
      render={(props) => <Viewer {...props} type="scoreboard" />}
    />,
    <Route
      exact
      path="/viewer/leaderboards/:id"
      key="leaderboards-view"
      render={(props) => <Viewer {...props} type="leaderboard" />}
    />,
    <Route
      exact
      path="/viewer/slideshows/:id"
      key="slideshows-view"
      render={(props) => <Viewer {...props} type="slideshow" />}
    />,
    <Route
      exact
      path="/viewer/combo"
      key="combo-board"
      render={(props) => <ComboBoard {...props} type="comboboard" />}
    />
  ],
}

export {ScorbitVisionRoutes, MachineToolsRoutes}
