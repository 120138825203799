import React from 'react'
import Loading from './../Loading'

const CRUDUpdate = (props) => {
  const {form: Form, item, onSubmit, titleForSubmit, error, loading, items} = props
  return loading
    ? <Loading />
    : <Form
        item={item}
        onSubmit={onSubmit}
        error={error}
        titleForSubmit={titleForSubmit}
        items={items}
      />
}

export default CRUDUpdate
