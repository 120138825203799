import React, {Fragment} from 'react'

const CRUDPublicPrivate = (props) => {
  const {isPublic, setIsPublic} = props
  return (
    <Fragment>
      <label className="primary">Privacy</label>
      <div className="flexRow">
        <label htmlFor="private">
          <input
            id="private"
            type="radio"
            name="privacy"
            checked={!isPublic}
            onChange={() => setIsPublic(false)}
          />
          Private (default)
        </label>
        <label htmlFor="public">
          <input
            id="public"
            type="radio"
            name="privacy"
            checked={isPublic}
            onChange={() => setIsPublic(true)}
          />
          Public
        </label>
      </div>
    </Fragment>
  )
}

export default CRUDPublicPrivate