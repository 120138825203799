import React from 'react'
import ScorbitLogo from './../../../assets/img/scorbit-logo.svg'
import './style.scss'

const AppError = (props) => {
  return (
    <div className="AppError">
      <img
        className="AppError-Logo"
        src={ScorbitLogo}
        alt="Scorbit Logo"
      />
      <h1>Something has gone wrong!</h1>
      <p>Please refresh the page and try again.</p>
    </div>
  )
}

export default AppError