import React from 'react'

import ScorbitLogo from './../../../assets/img/scorbit-logo.svg'
import './style.scss'

const Branding = (props) => {
  return (
    <div className="Viewer-Branding">
      <div>Powered by Scorbit</div>
      <div>
        <img src={ScorbitLogo} alt="" />
      </div>
      <div>scorbit.io</div>
    </div>
  )
}

export default Branding