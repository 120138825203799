import React from 'react'
import './style.scss'

const PageHeader = (props) => {
  const {className, children} = props

  return (
    <div className={`PageHeader ${className || ''}`}>
      <h1>{children}</h1>
    </div>
  )
}

export default PageHeader
