import React, {useState, useEffect, useRef, useCallback} from 'react'
import {
  getBootstrapRequest,
  getComboboardScoresRequest,
  getScoreboardScoresRequest,
  getLeaderboardScoresRequest,
} from './http'
import {getScoresWSURL} from './ws'
import Loading from './../../UI/Loading'
import Board from './board'
import './style.scss'

let clientWebSocket = null;

const ComboBoard = ({id, type, setBackgroundColor}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [config, setConfig] = useState(null)
  const [numItems, setNumItems] = useState(0)
  const [throttled, setThrottled] = useState(true)
  const [data, setData] = useState(null)
  const [curHotGame, setCurHotGame] = useState([]);

  const getConfig = () => {
    if (!id) {
      return
    }

    getBootstrapRequest(type, id)
    .then(data => {
      if (data.error) {
        if (data.error.match(/429/)) {
          setThrottled(true)
          throw Error('Too many requests')
        } else {
          setError(data.error)
        }
      } else {
        setConfig(data)
        setNumItems(type === "comboboard" ? data.items.length : 1)
        setLoading(false)
        setError(null)
        if (data.background_color)
          setBackgroundColor(data.background_color);
      }
    })
    .catch((error) => {
      console.error(error)
    })
  }

  const getScores = useCallback(() => {
    const getComboboardScores = () => {
      if (throttled || clientWebSocket) {
        return
      }
      getComboboardScoresRequest(id)
        .then(response => {
          if (response.error) {
            setThrottled(true)
            setError(response.error)
          }
          setData(response)
          setTimeout(getComboboardScores, 5000)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    const getStandaloneScores = () => {
      if (throttled || clientWebSocket) {
        return
      }

      let doScoresRequest
      if (type === "scoreboard") {
        doScoresRequest = getScoreboardScoresRequest
      }
      else if (type === "leaderboard") {
        doScoresRequest = getLeaderboardScoresRequest
      }
      else if (type === "comboboard") {
        doScoresRequest = getComboboardScores
      }
      doScoresRequest(id)
        .then(response => {
          if (response.error) {
            setThrottled(true)
            setError(response.error)
          }
          if (type === "scoreboard") {
            setData(response.data[0])
          }
          else if (type === "leaderboard") {
            setData(response.data[0].scores)
          }
          setTimeout(getStandaloneScores, 5000)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    if (type === "comboboard") {
      getComboboardScores()
    } else {
      getStandaloneScores()
    }
  }, [id, throttled, type])

  useEffect(getConfig, [id])
  
  // useEffect(getScores, [id, throttled, type])

  useEffect(() => {
    if (loading || !getScores || !type || !id || !throttled || clientWebSocket) return;

    const connectWebsocket = (retries = 0) => {
      clientWebSocket = new WebSocket(getScoresWSURL(`/${type}/${id}/scores/websocket/?session=${parseInt(Math.random() * 1000) % 1000}`));

      clientWebSocket.onclose = function () {
        console.log("Websocket Connection Closed");
        clientWebSocket = null;
        setThrottled(false);
        setTimeout(() => {
          setThrottled(true);
        }, 60000) // Retry to connect websocket after 1 min
      };

      clientWebSocket.onopen = () => {
        // Request scores for this board
        console.log("Websocket Connected");
        clientWebSocket.send(
          JSON.stringify({
            type: 'GET_SCORES',
            name: `${type}:${id}:scores`,
          })
        );
      };

      clientWebSocket.onmessage = (message) => {
        if (typeof message.data === 'string') {
          if (message.data !== 'null' && message.data !== 'undefined') {
            try {
              const message_data = JSON.parse(message.data);
              if (message_data && message_data.message === 'UNKNOWN') {
                console.error(message_data);
              } else {
                if (message_data.data) {
                  if (type === 'leaderboard') {
                    setData(message_data.data[0].scores);
                  } else if (type === 'scoreboard') {
                    if (message_data.data[0]) {
                      setData(message_data.data[0]);  
                    } else if (message_data.data.sessions[0]) {
                      setData(message_data.data.sessions[0]);  
                    }
                  }
                } else if (type === 'comboboard') {
                  setData(message_data);
                }
              }
            } catch (exception) {
              console.log(exception);
            }
          }
        }
      };
    };

    connectWebsocket();

  }, [loading, getScores, type, id, throttled])

  const render = () => {
    if (error) {
      return (
        <div className="Viewer-Error">
          {error}
        </div>
      )
    } else if (loading) {
      return (
        <div className="Viewer-Resource">
          <Loading />
        </div>
      )
    } else {
      return loading
        ? <div className="Viewer-Resource">
            <Loading />
          </div>
        : <div className="ComboBoard">
            <div className={`ComboBoard-Grid ComboBoard-Grid--${numItems}-items`}>
              {(type === "comboboard"
                ? config.items
                : [{"item_type": type, "item_id": id}]
              ).map((item, i) => {
                const itemType = item["item_type"]
                const itemId = item["item_id"]
                const highDensity = config ? config['high_density'] : false;
                let boardConfig
                let boardData
                if (type === "comboboard") {
                  boardConfig = config.boards.find(board => board.id === itemId)
                  if (itemType === "scoreboard") {
                    boardData = data && data.boards && data.boards[i] && data.boards[i].sessions ? data.boards[i].sessions[0] : null
                  }
                  else if (itemType === "leaderboard") {
                    boardData = data && data.boards && data.boards[i] && data.boards[i].data ? data.boards[i].data[0].scores : null
                  }
                } else {
                  boardConfig = config
                  config.id = id
                  boardData = data ? data : null
                }

                return (
                  <Board
                    key={i}
                    type={itemType}
                    config={boardConfig}
                    data={boardData}
                    highDensity={highDensity}
                    viewerType={type}
                    curHotGame={curHotGame}
                    setCurHotGame={setCurHotGame}
                  />
                )
              })}
            </div>
          </div>
    }
  }
  return render()
}

export default ComboBoard