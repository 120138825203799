import React from 'react'
import PageHeader from './../PageHeader'
import './style.scss'

const App404 = props =>
  <div className="App404">
    <PageHeader>OH NO! 404</PageHeader>
    <p>The requested page does not exist.</p>
  </div>

export default App404
