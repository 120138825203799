import _ from "lodash";

const getRandomString = () => {
  return _.times(20, () => _.random(35).toString(36)).join("");
};

const capitalize = (str = "") => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const pluralize = (str = "") => {
  return `${str}s`;
};

const maybePluralize = (str, i = 0) => {
  return `${str}${i > 0 ? "s" : ""}`;
};

export { getRandomString, capitalize, pluralize, maybePluralize };
