import React, {useState, useEffect, useRef} from 'react'
import './style.scss'

const defaultModes = [
  {label: 'GAME ON!', color: 'green'},
]

const Status = (props) => {
  const [modes, setModes] = useState(defaultModes)
  const [prevModes, setPrevModes] = useState(defaultModes)
  const [modeIndex, setModeIndex] = useState(0)
  const [specialMode, setSpecialMode] = useState(null)
  const timeoutId = useRef(null);

  useEffect(() => {
    if (props.settled) {
      if (props.machineName === "Total Nuclear Annihilation" && props.lastPlayerScore !== null && props.lastPlayerScore !== undefined) {
        if (props.lastPlayerScore % 10 === 9) {
          setModes([{label: 'YOU WIN', color: 'red'}]);
        } else {
          setModes([{label: 'YOU FAILED', color: 'red'}]);
        }
      } else {
        setModes([{label: 'GAME OVER', color: 'red'}]);
      }
      return;
    }
    let nextModes = (props.modes || []).map(mode => {
      const split = mode.split(':')
      let prefix = split[0]
      let labelFragment = split[1]
      let colorFragment = prefix.match(/\{([^}]+)\}/)
  
      // Check if prefix starts with BX, BL or EB and strip the number
      if (prefix.startsWith('BX')) {
        prefix = 'BX';
      } else if (prefix.startsWith('EB')) {
        prefix = 'EB';
      } else if (prefix.startsWith('BL')) {
        prefix = 'BL';
      }
  
      // Check if a color is set in the specific mode
      let color;
      if (colorFragment) {
        color = colorFragment[1]; // Use the color set in the mode
        prefix = prefix.replace(/\{([^}]+)\}/, ''); // Remove the color from the prefix
      } else if (labelFragment.includes('Extra Ball')) {
        color = 'red'; // Use red if the words "Extra Ball" are in the string
      } else if (labelFragment.includes('Lock')) {
        color = 'green'; // Use lightblue if the word "Lock" is in the string
      }
  
      // Use the existing colors if a color is not set
      if (!color) {
        switch (prefix) {
          case 'MB':
            color = 'green';
            break;
          case 'BL':
            color = 'green';
            break;
          case 'WM':
            color = 'orange';
            break;
          case 'EB':
            color = 'red';
            break;
          case 'BX':
            color = 'blue';
            break;
          case 'NA':
            color = 'yellow';
            break;
          default:
            color = 'white';
        }
      }
  
      return {
        prefix: prefix,
        label: labelFragment,
        color: color
      }
    })
    
    if (nextModes.length === 0) {
      setModes(defaultModes)
      return
    }
    
    // Check if there is only one mode with prefix XY
    const xyModes = nextModes.filter(mode => mode.prefix.substr(0, 2) === "XY");
    if (xyModes.length === 1) {
      setModes(xyModes);
      return;
    }

    nextModes = nextModes.filter(item => item.prefix.substr(0, 2) !== "ZZ" && item.prefix.substr(0, 2) !== "CP" && item.prefix.substr(0, 2) !== "XY")

    // Check for new modes
    const newMode = nextModes.find(mode => !prevModes.some(prevMode => prevMode.label === mode.label))
    if (newMode) {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      setSpecialMode(newMode)
      timeoutId.current = setTimeout(() => {
        setSpecialMode(null)
      }, 3000)
    }
    setPrevModes(modes)
    setModes(nextModes)
  }, [props.modes, props.settled])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!specialMode) {
        setModeIndex((prevState) => prevState >= modes.length - 1 ? 0 : prevState + 1)
      }
    }, 1000)
    return () => {
      clearInterval(interval);
    };
  }, [modes, modeIndex, specialMode])

  let mode = specialMode || modes[modeIndex]
  return !mode
    ? null
    : <div className={`ComboBoard-Status-Mode ComboBoard-Status-Mode--GameInProgress__${mode.color}`}>
          {mode.label}
        </div>
}

export default Status