import {getCRUDRequests} from './../../utils/xhr'

const {list: doScoreboardsRequest} =
  getCRUDRequests(`/api/scoreboard`, {public: true, limit: 10, ordering: '-id'})

const {list: doLeaderboardsRequest} =
  getCRUDRequests(`/api/leaderboard`, {public: true, limit: 10, ordering: '-id'})

const sortByIdDesc = (a, b) => a.id > b.id ? -1 : 1

const doResourcesRequest = async () => {
  const scoreboards = await doScoreboardsRequest()
    .then(response => response.results)
    .then(items => items.sort(sortByIdDesc))

  const leaderboards = await doLeaderboardsRequest()
    .then(response => response.results)
    .then(items => items.sort(sortByIdDesc))

  return {scoreboards, leaderboards}
}

export {doResourcesRequest}



