import React, {useState, useContext, useEffect, useCallback} from 'react'
import AppContext from './../../contexts/app'
import {doMachinesRequest} from './http'
import Loading from './../UI/Loading'
import CRUDTitle from './../UI/CRUDTitle'
import CRUDFormButtons from './../UI/CRUDFormButtons'
import CRUDPublicPrivate from './../UI/CRUDPublicPrivate'
import TimeOptions from './timeOptions'
import VenueMachineSelect from './../UI/VenueMachineSelect'
import MachineSelect from './../UI/MachineSelect'
import MachineWhiteList from './machineWhiteList'
import UserWhiteList from './userWhiteList'

const Form = (props) => {
  const {userVenueMachines, scrollToTop} = useContext(AppContext)
  const useWhiteLists = true
  const [machines, setMachines] = useState(null)
  const [action, setAction] = useState(null)
  const [validationError, setValidationError] = useState(null)
  const [title, setTitle] = useState(null)
  const [isPublic, setIsPublic] = useState(false)
  const [prevTitle, setPrevTitle] = useState(null)
  const [type, setType] = useState("venuemachine")
  const [perPlayer, setPerPlayer] = useState(false)
  const [busy, setBusy] = useState(false)
  const [loading, setLoading] = useState(true)
  const [venueMachineItems, setVenueMachineItems] = useState(Array(1).fill(null))
  const [machineItems, setMachineItems] = useState(Array(1).fill(null))
  const [machineId, setMachineId] = useState(null)
  const [timeType, setTimeType] = useState(null)
  const [timeBoxStart, setTimeBoxStart] = useState(null)
  const [timeBoxEnd, setTimeBoxEnd] = useState(null)
  const [lastCalendarOption, setLastCalendarOption] = useState(null)
  const [timeZone, setTimeZone] = useState(null)
  const [lastTimeCount, setLastTimeCount] = useState(null)
  const [whiteList, setWhiteList] = useState([])
  const [userWhiteList, setUserWhiteList] = useState([])
  const [backgroundColor, setBackgroundColor] = useState('#000')
  const [displayTitle, setDisplayTitle] = useState(false)

  const mapPropsToState = useCallback(() => {
    const {item} = props
    setAction(item ? 'update' : 'create')
    if (!item) return
    setTitle(item["title"])
    setPrevTitle(item["title"])
    setIsPublic(item["is_public"])
    setPerPlayer(item["one_score_per_player"])
    setType(item.items[0].type)
    setTimeType(item["leaderboard_time_type"])
    setTimeBoxStart(item["score_start_time"])
    setTimeBoxEnd(item["score_end_time"])
    setLastCalendarOption(item["last_time_bucket"])
    setTimeZone(item["last_time_timezone"])
    setLastTimeCount(item["last_time_count"])
    setWhiteList(item["venuemachines"])
    setUserWhiteList(item["players"])
    setBackgroundColor(item["background_color"]);
    setDisplayTitle(item['display_title']);
      if (item.items[0].type === "venuemachine") {
      setVenueMachineItems(item.items.map(item => item.id))
    }
    if (item.items[0].type === "machine") {
      setMachineItems(item.items.map(item => item.id))
      setMachineId(item.items[0])
    }
    if (item.items[0].type === "machinegroup") {
      setType("machine")
      setMachineId(`g-${item.items[0]}`)
      setMachineItems([`g-${item.items[0].id}`])
    }
  }, [props])
  useEffect(mapPropsToState, [props.item])

  const getMachines = useCallback(() => {
    doMachinesRequest()
      .then(machines => {
        setMachines(machines)
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    getMachines()
  }, [getMachines])

  const validate = () => {
    const validMachines = type === "venuemachine"
      ? venueMachineItems.filter(m => m !== null)
      : machineItems.filter(m => m !== null)
    const uniqueMachines = [...new Set(validMachines)]
    const titles = props.items.map(item => item.title)
    const isDupe = titles.filter(item => item === title).length ? true : false
    let validationError = ''

    // validate title not blank
    if (!title || !title.length) {
      validationError = 'Title must not be blank'
    }

    // validate unique title when action is create
    else if (action === 'create' && isDupe) {
      validationError = 'Title must be unique'
    }

    // validate unique title when action is update
    else if (action === 'update' && isDupe && title !== prevTitle) {
      validationError = 'Title must be unique'
    }

    // validate at least one machine
    else if (!uniqueMachines.length) {
      validationError = 'Please select at least 1 machine'
    }

    // validate no duplicate machines
    else if (uniqueMachines.length !== validMachines.length) {
      validationError = `No duplicate machines!`
    }

    // validate time settings
    if (timeType === "time_box" && timeBoxStart === null) {
      validationError = 'Please select a start date'
    }

    // validate whitelist
    if (whiteList.length) {
      const uniqueWhiteListMachines = [...new Set(whiteList)]
      if (uniqueWhiteListMachines.length !== whiteList.length) {
        validationError = 'No duplicate machines in whitelist!'
      }
    }

    // validate user whitelist
    if (userWhiteList.length) {
      const uniqueUserWhiteList = [...new Set(userWhiteList)]
      if (uniqueUserWhiteList.length !== userWhiteList.length) {
        validationError = 'No duplicate users in whitelist!'
      }
    }

    // show error or proceed
    if (validationError.length) {
      setValidationError(validationError)
      setBusy(false)
      return false
    } else {
      setValidationError(null)
      return true
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    setBusy(true)

    if (!validate()) {
      setBusy(false)
      scrollToTop()
      return false
    }

    const validMachines = type === "venuemachine"
      ? venueMachineItems.filter(m => m !== null)
      : machineItems.filter(m => m !== null)

    let items = []
    if (type === "venuemachine") {
      items = validMachines.map((id, i) => ({
        id: id,
        type: "venuemachine",
        order: i,
      }))
    }
    else if (type === "machine") {
      items = validMachines.map((id, i) => {
        let machineId
        let type
        if (typeof(id) === 'string') {
          machineId = parseInt(id.replace('g-', ''))
          type = 'machinegroup'
        } else {
          machineId = id
          type = 'machine'
        }
        return {
          id: machineId,
          type: type,
          order: i,
        }
      })
    }

    const leaderboard = {
      "title": title,
      "is_public": isPublic,
      "items": items,
      "items_per_list": 10,
      "one_score_per_player": perPlayer,
      "leaderboard_time_type": timeType,
      "background_color": backgroundColor,
      "display_title": displayTitle,
    }

    if (timeType === "time_box") {
      leaderboard["score_start_time"] = timeBoxStart
      leaderboard["score_end_time"] = timeBoxEnd
    }

    if (timeType === "last_calendar") {
      leaderboard["last_time_bucket"] = lastCalendarOption
      leaderboard["last_time_timezone"] = timeZone
      leaderboard["last_time_count"] = 1
    }

    if (timeType === "last_rolling") {
      leaderboard["last_time_bucket"] = "day"
      leaderboard["last_time_count"] = lastTimeCount
    }

    if (type === "machine") {
      leaderboard["leaderboard_machine_type"] = "global"
      leaderboard["filter_by_venuemachines"] = false
    }

    if (whiteList.length) {
      leaderboard["venuemachines"] = whiteList
      leaderboard["leaderboard_machine_type"] = "selected"
      leaderboard["filter_by_venuemachines"] = true
    } else {
      leaderboard["venuemachines"] = []
      leaderboard["leaderboard_machine_type"] = "global"
      leaderboard["filter_by_venuemachines"] = false
    }

    if (userWhiteList.length) {
      leaderboard["leaderboard_player_type"] = "invited"
      leaderboard["players"] = userWhiteList
    } else {
      leaderboard["leaderboard_player_type"] = "global"
      leaderboard["players"] = []
    }

    props.onSubmit(leaderboard)
  }

  const changeVenueMachine = (itemIndex, venueMachineId) => {
    setVenueMachineItems((prevItems) => {
      prevItems[itemIndex] = venueMachineId
      return prevItems
    })
  }

  const renderVenueMachineSelect = (index) => {
    return (
      <div className="formRow" key={index}>
        <VenueMachineSelect
          id={venueMachineItems[index]}
          defaultVenueMachines={userVenueMachines}
          onChange={venueMachineId => changeVenueMachine(index, venueMachineId)}
        />
      </div>
    )
  }

  const changeMachine = (itemIndex, machineId) => {
    setMachineId(machineId)
    setWhiteList([])
    setMachineItems((prevItems) => {
      prevItems[itemIndex] = machineId
      return prevItems
    })
  }

  const renderMachineSelect = (index) => {
    return (
      <div className="formRow" key={index}>
        <MachineSelect
          id={machineItems[index]}
          defaultMachines={machines}
          onChange={machineId => changeMachine(index, machineId)}
        />
      </div>
    )
  }

  const renderMachineSelectTip = () => {
    return (
      <span className="tip">
        Tip: Dropdown defaults to machines you own.
        <br />Search for other machines by title and/or venue.
      </span>
    )
  }

  const renderForm = () => {
    return (
      <form onSubmit={onSubmit} className="has-required-fields">
        {validationError && <div className="formError">{validationError}</div>}
        <div className="formRow">
          <CRUDTitle
            title={title}
            setTitle={setTitle}
          />
        </div>
        
        <div className="formRow">
        <label className="primary">
          <span>Display Title</span>
        </label>

        <div className="flexRow">
          <label htmlFor="enableDisplayTitle">
            <input
              id="enableDisplayTitle"
              type="radio"
              name="displayTitle"
              checked={displayTitle}
              onChange={() => setDisplayTitle(true)}
            />
            Enable
          </label>
          <label htmlFor="disableDisplayTitle">
            <input
              id="disableDisplayTitle"
              type="radio"
              name="displayTitle"
              checked={!displayTitle}
              onChange={() => setDisplayTitle(false)}
            />
            Disable
          </label>
        </div>
      </div>

        <div className="formRow">
          <CRUDPublicPrivate
            isPublic={isPublic}
            setIsPublic={setIsPublic}
          />
        </div>
        {useWhiteLists &&
          <TimeOptions
            timeType={timeType}
            setTimeType={setTimeType}
            timeBoxStart={timeBoxStart}
            setTimeBoxStart={setTimeBoxStart}
            timeBoxEnd={timeBoxEnd}
            setTimeBoxEnd={setTimeBoxEnd}
            lastCalendarOption={lastCalendarOption}
            setLastCalendarOption={setLastCalendarOption}
            timeZone={timeZone}
            setTimeZone={setTimeZone}
            lastTimeCount={lastTimeCount}
            setLastTimeCount={setLastTimeCount}
          />
        }
        <div className="formRow">
          <label className="primary">Scores per player</label>
          <div className="flexRow">
            <label htmlFor="perPlayerAll">
              <input
                id="perPlayerAll"
                type="radio"
                name="perPlayer"
                checked={perPlayer === false}
                onChange={() => setPerPlayer(false)}
              />
              All Scores
            </label>
            <label htmlFor="perPlayerOne">
              <input
                id="perPlayerOne"
                type="radio"
                name="perPlayer"
                checked={perPlayer === true}
                onChange={() => setPerPlayer(true)}
              />
              One Score Per Player
            </label>
          </div>
        </div>
        <div className="formRow">
          <label className="primary">Type</label>
          <div className="flexRow">
            <label htmlFor="venuemachine">
              <input
                id="venuemachine"
                type="radio"
                name="type"
                checked={type === "venuemachine"}
                onChange={() => setType("venuemachine")}
              />
              One Machine
            </label>
            <label htmlFor="machine">
              <input
                id="machine"
                type="radio"
                name="type"
                checked={type === "machine"}
                onChange={() => setType("machine")}
              />
              Global or Machine List
            </label>
          </div>
        </div>
        {type === "venuemachine" &&
          <div className="formRow">
            <label className="primary">
              <span>Select machine</span>
              <br />
              {renderMachineSelectTip()}
            </label>
            {Array(1).fill(null).map((v, i) => renderVenueMachineSelect(i))}
          </div>
        }
        {type === "machine" &&
          <div>
            <div className="formRow">
              <label className="primary">
                <span>Select game</span>
              </label>
              {Array(1).fill(null).map((v, i) => renderMachineSelect(i))}
            </div>
            {useWhiteLists &&
              <div className="formRow">
                <label className="primary">
                  <span>Optional: Include only these machines</span>
                  <br />
                  {renderMachineSelectTip()}
                </label>
                <MachineWhiteList
                  userVenueMachines={userVenueMachines}
                  setWhiteList={setWhiteList}
                  venueMachines={whiteList}
                  machineId={machineId}
                />
              </div>
            }
          </div>
        }
        {useWhiteLists &&
          <div className="formRow">
            <label className="primary">
              Optional: Include only these users
            </label>
            <UserWhiteList
              userWhiteList={userWhiteList}
              setUserWhiteList={setUserWhiteList}
            />
          </div>
        }
        <div className="formRow">
          <label className="primary">
            <span>Background Color</span>
          </label>
          <input
            className="colorPicker"
            type="color"
            value={backgroundColor}
            onChange={(event) => {
              setBackgroundColor(event.target.value);
            }}
          />
        </div>
        <CRUDFormButtons
          busy={busy}
          disableSubmit={busy}
          titleForSubmit={props.titleForSubmit}
          cancelHref="/leaderboards"
        />
      </form>
    )
  }

  return loading
    ? <Loading />
    : renderForm()
}

export default Form
