import React, {useState} from 'react'
import Helmet from 'react-helmet'
import ScorbitLogo from './../../assets/img/scorbit-logo.svg'
import Loading from './../UI/Loading'
import {getAPIURL} from './../../utils/xhr'
import './style.scss'

const Login = (props) => {
  const {title} = props
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const [busy, setBusy] = useState(false)
  
  const [pageType, setPageType] = useState('login')

  const [registerData, setRegisterData] = useState({
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    initials: '',
    display_name: ''
  })

  const [resendData, setResendData] = useState({
    email: '',
  })

  const handleUsernameChange = (event) => {
    setUsername(event.target.value)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setBusy(true)

    return fetch(getAPIURL('/api/auth/'), {
      method: 'POST',
      body: JSON.stringify({
        username,
        password,
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((response) => {
      if (response.token) {
        props.onLogin(response.token)
      } else {
        setPassword('')
        setError('Incorrect username or password!')
        setBusy(false)
      }
    })
    .catch((error) => {
      setUsername('')
      setPassword('')
      setError(error['non_field_errors'])
      setBusy(false)
      console.error(error)
    })
  }

  const handleSubmitRegister = (event) => {
    event.preventDefault()
    setError("");
    if (registerData.email === '') {
      setError("Email should not be empty");
      return;
    } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(registerData.email))) {
      setError("Please input correct email");
      return;
    } else if (registerData.first_name === '') {
      setError("First name should not be empty");
      return;
    } else if (registerData.last_name === '') {
      setError("Last name should not be empty");
      return;
    } else if (registerData.password !== registerData.passwordConfirm) {
      setError("Please check password");
      return;
    } else if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%=^&*-+/.,{}()\[\]`~?\\|])(?=.{8,})/g.test(registerData.password))) {
      setError("Password must be at least 8 characters long and include at least one letter, one number and one symbol");
      return;
    } else if (registerData.initials !== '' && registerData.initials.length > 3) {
      setError("Initials must less than 4 characters");
      return;
    }

    setBusy(true)

    return fetch(getAPIURL('/api/account/register/'), {
      method: 'POST',
      body: JSON.stringify({
        username: registerData.username,
        password: registerData.password,
        first_name: registerData.first_name,
        last_name: registerData.last_name,
        email: registerData.email,
        initials: registerData.initials,
        display_name: registerData.display_name
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((response) => {
      if (response.id) {
        setPageType("registerSuccess");
      } else {
        for (const item in response) {
          if (response[item]) {
            setError(response[item][0]);
            break;
          }
        }
      }
      setBusy(false)
    })
    .catch((error) => {
      setUsername('')
      setPassword('')
      setError(error['non_field_errors'])
      setBusy(false)
      console.error(error)
    })
  }

  const handleSubmitResendVerificationEmail = (event) => {
    event.preventDefault()
    setError("");

    if (resendData.email === '') {
      setError("Email should not be empty");
      return;
    } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(resendData.email))) {
      setError("Please input correct email");
      return;
    }

    setBusy(true)

    sendVerifyEmail(resendData.email, () => {
      setPageType("resendSuccess");
    });
  }

  const handleSubmitResetPassword = (event) => {
    event.preventDefault()
    setError("");

    if (resendData.email === '') {
      setError("Email should not be empty");
      return;
    } else if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(resendData.email))) {
      setError("Please input correct email");
      return;
    }

    setBusy(true)

    sendResetPasswordLink(resendData.email, () => {
      setPageType("resetPasswordSuccess");
    });
  }

  const sendVerifyEmail = (email, callback) => {
    return fetch(getAPIURL('/api/account/send-verification-email/'), {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((response) => {
      setBusy(false);
      callback();
    })
    .catch((error) => {
      setBusy(false);
    })
  }

  const sendResetPasswordLink = (email, callback) => {
    return fetch(getAPIURL('/api/account/send-reset-password-link/'), {
      method: 'POST',
      body: JSON.stringify({
        email: email.toLowerCase(),
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => response.json())
    .then((response) => {
      setBusy(false);
      callback();
    })
    .catch((error) => {
      setBusy(false);
    })
  }

  const renderUsername = (username) => (
    <div className="formRow">
      <label htmlFor="Login-Username">Username</label>
      <input
        name="username"
        value={username}
        type="text"
        onChange={handleUsernameChange}
      />
    </div>
  )
  const renderPassword = (password) => (
    <div className="formRow">
      <label htmlFor="password">Password</label>
      <input
        name="password"
        value={password}
        type="password"
        onChange={handlePasswordChange}
      />
    </div>
  )

  const renderLoginPage = () => (
    <div className="AppLogin">
      <Helmet>
        <title>{title} - Login</title>
      </Helmet>
      <div className="AppLogin-Logo">
        <img src={ScorbitLogo} alt="" />
      </div>
      <h2>Please log in to continue</h2>
      <form onSubmit={handleSubmit}>
        {error && <div className="formError">{error}</div>}
        {renderUsername(username)}
        {renderPassword(password)}
        <div className="formRow formRow--buttons">
          <button
            type="submit"
            disabled={busy}
            className={`Button`}
          >
            {busy ? <Loading /> : 'Log in'}
          </button>
          <button
            type="button"
            disabled={busy}
            className={`Button`}
            onClick={() => {
              setPageType("register")
            }}
            style={{marginTop: "10px"}}
          >
            Register
          </button>
        </div>
        <div className="AppLogin-Forgot">
          <a
            href="#"
            onClick={() => {setPageType("resetPassword")}}
            rel="noopener noreferrer"
          >
            Forgot username or password?
          </a>
          <br/><br/>
          <a
            href="#"
            onClick={() => {setPageType("resend")}}
            rel="noopener noreferrer"
          >
            Resend verification Email
          </a>
        </div>
      </form>
    </div>
  )

  const renderRegisterPage = () => (
    <div className="AppRegister">
      <Helmet>
        <title>{title} - Register</title>
      </Helmet>
      <div className="AppRegister-Logo">
        <img src={ScorbitLogo} alt="" />
      </div>
      <h2>Please register to Scorbit Vision</h2>
      <form onSubmit={handleSubmitRegister}>
        {error && <div className="formError">{error}</div>}
        <div className="formBox">
          <div className="formRow">
            <label htmlFor="username">Username</label>
            <input
              name="username"
              value={registerData.username}
              type="text"
              onChange={(event) => { setError(''); setRegisterData({ ...registerData, username: event.target.value }); }}
            />
          </div>
          <div className="formRow">
            <label htmlFor="email">Email</label>
            <input
              name="email"
              value={registerData.email}
              type="text"
              onChange={(event) => { setError(''); setRegisterData({ ...registerData, email: event.target.value }); }}
            />
          </div>
        </div>
        <div className="formBox">
          <div className="formRow">
            <label htmlFor="first_name">First Name</label>
            <input
              name="first_name"
              value={registerData.first_name}
              type="text"
              onChange={(event) => { setError(''); setRegisterData({ ...registerData, first_name: event.target.value }); }}
            />
          </div>
          <div className="formRow">
            <label htmlFor="last_name">Last Name</label>
            <input
              name="last_name"
              value={registerData.last_name}
              type="text"
              onChange={(event) => { setError(''); setRegisterData({ ...registerData, last_name: event.target.value }); }}
            />
          </div>
        </div>
        <div className="formBox">
          <div className="formRow">
            <label htmlFor="password">Password</label>
            <input
              name="password"
              value={registerData.password}
              type="password"
              onChange={(event) => { setError(''); setRegisterData({ ...registerData, password: event.target.value }); }}
            />
          </div>
          <div className="formRow">
            <label htmlFor="passwordConfirm">Password Confirm</label>
            <input
              name="passwordConfirm"
              value={registerData.passwordConfirm}
              type="password"
              onChange={(event) => { setError(''); setRegisterData({ ...registerData, passwordConfirm : event.target.value }); }}
            />
          </div>
        </div>
        
        <div className="formBox">
          <div className="formRow">
            <label htmlFor="initials">Initials (Optional)</label>
            <input
              name="initials"
              value={registerData.initials}
              type="text"
              onChange={(event) => { setError(''); setRegisterData({ ...registerData, initials : event.target.value }); }}
            />
          </div>
          <div className="formRow">
            <label htmlFor="display_name">Display Name (Optional)</label>
            <input
              name="display_name"
              value={registerData.display_name}
              type="text"
              onChange={(event) => { setError(''); setRegisterData({ ...registerData, display_name: event.target.value }); }}
            />
          </div>
        </div>
        <div className="formRow formRow--buttons">
          <button
            type="submit"
            disabled={busy}
            className={`Button`}
          >
            {busy ? <Loading /> : 'Register'}
          </button>
          
          <div className="ReturnToLogin">
            <a
              href="/"
              rel="noopener noreferrer"
            >
              Return to Login
            </a>
          </div>
        </div>
      </form>
    </div>
  )

  const renderResendVerificationEmail = () => (
    <div className="AppLogin">
      <Helmet>
        <title>{title} - Resend Verification Email</title>
      </Helmet>
      <div className="AppLogin-Logo">
        <img src={ScorbitLogo} alt="" />
      </div>
      <form onSubmit={handleSubmitResendVerificationEmail}>
        {error && <div className="formError">{error}</div>}
        <div className="formRow">
          <label htmlFor="email">Email</label>
          <input
            name="email"
            value={resendData.email}
            type="text"
            onChange={(event) => { setError(''); setResendData({ email: event.target.value }); }}
          />
        </div>
        <div className="formRow formRow--buttons">
          <button
            type="submit"
            disabled={busy}
            className={`Button`}
          >
            {busy ? <Loading /> : 'Resend Verification Email'}
          </button>
          
          <div className="ReturnToLogin">
            <a
              href="/"
              rel="noopener noreferrer"
            >
              Return to Login
            </a>
          </div>
        </div>
      </form>
    </div>
  )

  const renderResendVerificationEmailSuccess = () => (
    <div className="AppRegister">
      <Helmet>
        <title>{title} - Resend Verification Email</title>
      </Helmet>
      <div className="AppRegister-Logo">
        <img src={ScorbitLogo} alt="" />
      </div>
      <form>
        <div className="formPopup">If your user at email ({resendData.email}) exists, you will receive a new verification email.</div>
        
        <div className="formRow formRow--buttons">
          <div className="ReturnToLogin">
            <a
              href="/"
              rel="noopener noreferrer"
            >
              Return to Login
            </a>
          </div>
        </div>
      </form>
    </div>
  )

  const renderResetPassword = () => (
    <div className="AppLogin">
      <Helmet>
        <title>{title} - Reset Password</title>
      </Helmet>
      <div className="AppLogin-Logo">
        <img src={ScorbitLogo} alt="" />
      </div>
      <form onSubmit={handleSubmitResetPassword}>
        {error && <div className="formError">{error}</div>}
        <div className="formRow">
          <label htmlFor="email">Email</label>
          <input
            name="email"
            value={resendData.email}
            type="text"
            onChange={(event) => { setError(''); setResendData({ email: event.target.value }); }}
          />
        </div>
        <div className="formRow formRow--buttons">
          <button
            type="submit"
            disabled={busy}
            className={`Button`}
          >
            {busy ? <Loading /> : 'Send Reset Password Email'}
          </button>
          
          <div className="ReturnToLogin">
            <a
              href="/"
              rel="noopener noreferrer"
            >
              Return to Login
            </a>
          </div>
        </div>
      </form>
    </div>
  )

  const renderResetPasswordSuccess = () => (
    <div className="AppRegister">
      <Helmet>
        <title>{title} - Reset Password</title>
      </Helmet>
      <div className="AppRegister-Logo">
        <img src={ScorbitLogo} alt="" />
      </div>
      <form>
        <div className="formPopup">Please check your email ({resendData.email}) and click on the reset password link. If you do not receive an email, please contact <a href="mailto:support@scorbit.io">support@scorbit.io</a>.</div>
        
        <div className="formRow formRow--buttons">
          <div className="ReturnToLogin">
            <a
              href="/"
              rel="noopener noreferrer"
            >
              Return to Login
            </a>
          </div>
        </div>
      </form>
    </div>
  )

  const renderRegisterSuccessPage = () => (
    <div className="AppRegister">
      <Helmet>
        <title>{title} - Register</title>
      </Helmet>
      <div className="AppRegister-Logo">
        <img src={ScorbitLogo} alt="" />
      </div>
      <form>
        <div className="formPopup">
          Please check your email for a verification link.
          <br />
          You must click this link for your account to remain active.
          <br />
          If you have trouble receiving a link, please contact <a href="mailto:support@scorbit.io">support@scorbit.io</a>.
        </div>
        
        <div className="formRow formRow--buttons">          
          <div className="ReturnToLogin">
            <a
              href="/"
              rel="noopener noreferrer"
            >
              Return to Login
            </a>
          </div>
        </div>
      </form>
    </div>
  )

  return (
    <>
      {pageType === "login" && renderLoginPage()}
      {pageType === "register" && renderRegisterPage()}
      {pageType === "registerSuccess" && renderRegisterSuccessPage()}
      {pageType === "resend" && renderResendVerificationEmail()}      
      {pageType === "resendSuccess" && renderResendVerificationEmailSuccess()}
      {pageType === "resetPassword" && renderResetPassword()}      
      {pageType === "resetPasswordSuccess" && renderResetPasswordSuccess()}
    </>
  )
}

export default Login
