import React, {useContext, useState, useEffect, useCallback} from 'react'
import _ from 'lodash'
import AppContext from './../../../contexts/app'
import AsyncSelect from 'react-select/async'
import {doVenueMachinesSearchRequest, doVenueMachinesByUserRequest,
   doVenueMachineByIdRequest} from './http'

const VenueMachineSelect = (props) => {
  const {id, onChange, defaultVenueMachines} = props
  const {profile} = useContext(AppContext)
  const [defaultOptions, setDefaultOptions] = useState(null)
  const [selection, setSelection] = useState(null)

  const noOption = {
    value: null,
    label: '-- none --',
  }

  const makeOption = (venueMachine) => {
    const {id, venue, machine} = venueMachine
    const ownerIds = venueMachine["user_roles"]
      .filter(role => role.role === 'owner')
      .map(role => role.user.id)
    return {
      value: id,
      label: `${machine.name} at ${venue.name}`,
      ownerIds,
    }
  }

  const makeOptions = useCallback((venueMachines) => {
    return venueMachines.map(makeOption)
  }, [])

  const getDefaultOptions = useCallback(() => {
    doVenueMachinesByUserRequest(profile.id)
      .then(venueMachines => {
        const options = makeOptions(venueMachines)
        setDefaultOptions(options)
      })
  }, [profile.id, makeOptions])

  useEffect(() => {
    if (!id) return
    doVenueMachineByIdRequest(id)
      .then(venueMachine => {
        const option = makeOption(venueMachine)
        setSelection(option)
      })
  }, [id])

  useEffect(() => {
    if (!defaultVenueMachines) {
      getDefaultOptions()
    } else {
      const options = makeOptions(defaultVenueMachines)
      setDefaultOptions(options)
    }
  }, [defaultVenueMachines, getDefaultOptions, makeOptions])

  const loadOptions = _.debounce((inputValue, callback) => {
    if (!inputValue || !inputValue.length) {
      callback([noOption, ...defaultOptions])
    } else {
      doVenueMachinesSearchRequest(inputValue)
        .then(venueMachines => {
          const ownerMatches = defaultOptions.filter(option => {
            const haystack = option.label.toLowerCase()
            const needle = inputValue.toLowerCase()
            return haystack.match(needle)
          })
          const searchMatches = makeOptions(venueMachines)
          callback([noOption, ...ownerMatches, ...searchMatches])
        })
    }
  }, 500)

  const handleChange = (option) => {
    setSelection(option)
    onChange(option.value)
  }

  return (
    <div className="VenueMachineSelect">
      {defaultOptions
        ? <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            placeholder={"search by machine or venue..."}
            onChange={handleChange}
            value={selection ? selection : null}
          />
        : null
      }
    </div>
  )
}

export default VenueMachineSelect