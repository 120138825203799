import React, {useState,  useEffect, useCallback} from 'react'
import Datetime from 'react-datetime'
import 'react-day-picker/lib/style.css'
import "react-datetime/css/react-datetime.css"
import timeZones from './timezones'

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const displayDate = (date) => {
  return `${months[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`
}
const convertDateToYYYYMMDD = (date) => {
  return date.toJSON().slice(0, 10)
}

const options = {
  "all_time": 'All time',
  "time_box": 'Boxed',
  "last_calendar": 'Last Calendar...',
  "last_rolling": 'Rolling',
}

const defaultOptionKey = "all_time"

const lastCalendarOptions = ["year", "month", "week", "day", "hour"]

const TimeOptions = (props) => {
  const [selectedOptionKey, setSelectedOptionKey] = useState(props.timeType || defaultOptionKey)
  const [timeBoxStart, setTimeBoxStart] = useState(null)
  const [timeBoxEnd, setTimeBoxEnd] = useState(null)
  const [lastCalendarOptionsIndex, setLastCalendarOptionsIndex] = useState(0)
  const [lastTimeCount, setLastTimeCount] = useState(null)
  const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)

  const mapPropsToState = useCallback(() => {
    if (props.timeBoxStart) {
      setTimeBoxStart(new Date(props.timeBoxStart))
    }
    if (props.timeBoxEnd) {
      setTimeBoxEnd(new Date(props.timeBoxEnd))
    }
    if (props.lastCalendarOption) {
      const lastCalendarOptionIndexFromProps = lastCalendarOptions.indexOf(props.lastCalendarOption)
      if (lastCalendarOptionIndexFromProps === -1) {
        setLastCalendarOptionsIndex(0)
      } else {
        setLastCalendarOptionsIndex(lastCalendarOptionIndexFromProps)
      }
    }
    if (props.timeZone) {
      setTimeZone(props.timeZone)
    }
    if (props.lastTimeCount) {
      setLastTimeCount(props.lastTimeCount)
    }
  }, [props])
  useEffect(mapPropsToState, [])

  useEffect(() => {
    props.setTimeType(selectedOptionKey)
  }, [props, selectedOptionKey])

  useEffect(() => {
    props.setLastCalendarOption(lastCalendarOptions[lastCalendarOptionsIndex])
  }, [props, lastCalendarOptionsIndex])

  useEffect(() => {
    timeBoxStart && props.setTimeBoxStart(timeBoxStart.toISOString())
  }, [props, timeBoxStart])

  useEffect(() => {
    if (timeBoxEnd) {
      props.setTimeBoxEnd(timeBoxEnd.toISOString())
    } else {
      props.setTimeBoxEnd(null)
    }
  }, [props, timeBoxEnd])

  useEffect(() => {
    timeZone && props.setTimeZone(timeZone)
  }, [props, timeZone])

  useEffect(() => {
    if (lastTimeCount && lastTimeCount < 1) {
      setLastTimeCount(1)
    } else {
      props.setLastTimeCount(lastTimeCount)
    }
  }, [props, lastTimeCount])

  const clearTimeBoxEnd = (event) => {
    event.preventDefault()
    setTimeBoxEnd(null)
  }

  useEffect(() => {
    if (timeBoxStart && timeBoxEnd) {
      let endDateIsValid = true
      const convertedStart = convertDateToYYYYMMDD(timeBoxStart)
      const convertedEnd = convertDateToYYYYMMDD(timeBoxEnd)
      if (convertedEnd < convertedStart) {
        endDateIsValid = false
        alert('End date can not be before start date')
      }
      if (convertedEnd === convertedStart) {
        endDateIsValid = false
        alert('End date can not be same as start date')
      }
      if (!endDateIsValid) {
        setTimeBoxEnd(null)
      }
    }
  }, [timeBoxStart, timeBoxEnd])

  const changeOption = (event) => {
    setSelectedOptionKey(event.target.value)
  }

  const changeTimeZone = (event) => {
    setTimeZone(event.target.value)
  }

  return (
    <div>
      <div className="formRow">
        <label className="primary">Time Options</label>
        <div className="flexRow">
          <select
            onChange={changeOption}
            value={selectedOptionKey}
          >
            {Object.keys(options).map(key =>
              <option
                key={key}
                value={key}
              >
                {options[key]}
              </option>
            )}
          </select>
        </div>
      </div>
      {selectedOptionKey === "time_box" &&
        <div className="formRow">
          <div className="flexRow">
            <div>
              <div className="datePanel">
                <label className="selectedDate">
                  {timeBoxStart ? `Start: ${displayDate(timeBoxStart)}` : 'Select start'}
                </label>
              </div>
              <div>
                <Datetime
                  onChange={(e) => { setTimeBoxStart(e.toDate()) }}
                  renderInput={(props) => {
                    return <input {...props} value={ timeBoxStart ? props.value : '' } readonly = 'true' />
                  }}
                  value={timeBoxStart}
                />
              </div>
            </div>
            <div>
              <div className="datePanel">
                <label className="selectedDate">
                  {timeBoxEnd ? `End: ${displayDate(timeBoxEnd)}` : 'Select end (optional)'}
                </label>
                {timeBoxEnd && <button className="Button Button--secondary Button--small Button--small--text" onClick={clearTimeBoxEnd}>clear</button>}
              </div>
              <div>
                <Datetime
                  onChange={(e) => { setTimeBoxEnd(e.toDate()) }}
                  renderInput={(props) => {
                    return <input {...props} value={ timeBoxEnd ? props.value : '' }  readonly = 'true' />
                  }}
                  value={timeBoxEnd}
                />
              </div>
            </div>
          </div>
        </div>
      }
      {selectedOptionKey === "last_calendar" &&
        <div className="formRow">
          <div className="flexRow">
            {lastCalendarOptions.map((option, i) =>
              <label key={option} htmlFor={option}>
                <input
                  id={option}
                  type="radio"
                  name={option}
                  checked={i === lastCalendarOptionsIndex}
                  onChange={() => setLastCalendarOptionsIndex(i)}
                />
                {option}
              </label>
            )}
          </div>
          <div>
            <label>Time zone</label>
            <select
              value={timeZone}
              onChange={changeTimeZone}
            >
              {timeZones.map(timeZone =>
                <option
                  key={timeZone}
                  value={timeZone}
                >
                  {timeZone}
                </option>
              )}
            </select>
          </div>
        </div>
      }
      {selectedOptionKey === "last_rolling" &&
        <div className="formRow">
          <div className="flexRow">
            <label>
              {`Last `}
              <input
                className="inline"
                type="number"
                value={lastTimeCount || ''}
                onChange={event => setLastTimeCount(event.target.value)}
                min={0}
              />
              {` day${lastTimeCount > 1 ? 's' : ''}`}</label>
          </div>
        </div>
      }
    </div>
  )
}

export default TimeOptions