import {
  doRequest,
} from './../../utils/xhr'

const getVenuemachinesRequest = async (limit = 100) => {
  let items = []
  let offset = 0

  const recursiveRequest = async () => {
    const url = `/api/venuemachine/?limit=${limit}&offset=${offset}`
    const data = await doRequest(url)
    const {results, count} = data
    offset += limit
    items = items.concat(results)
    return items.length === count ? items : recursiveRequest()
  }

  return recursiveRequest()
}

export {
  getVenuemachinesRequest
}
