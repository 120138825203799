import React, {useContext, Fragment} from 'react'
import AppContext from './../../../contexts/app'
import {pluralize} from './../../../utils/string'
import {getResourceOwnerId} from './../../../utils/crud'
import {Link} from 'react-router-dom'
  
const CRUDItemButtons = (props) => {
  const {item, className, id, resource, title, deleteItem} = props
  const {profile} = useContext(AppContext)
  const ownerId = getResourceOwnerId(item)

  return (
    <div className={`CRUDActions ListItem-Actions ${className}`}>
      <Link
        to={`/viewer/${pluralize(resource)}/${id}`}
        className="Button Button--secondary Button--small"
        target="_blank"
        title="launch"
      >
        <i className="material-icons">visibility</i>
      </Link>
      {profile.id === ownerId &&
        <Fragment>
          <Link
            className="Button Button--secondary Button--small"
            to={`/${pluralize(resource)}/${id}/edit`}
            title="edit"
          >
            <i className="material-icons">edit</i>
          </Link>
          {deleteItem &&
            <button
              className="Button Button--secondary Button--small"
              onClick={() => deleteItem(id, title)}
              title="delete"
            >
              <i className="material-icons">delete</i>
            </button>
          }
        </Fragment>
      }
    </div>
  )
}

export default CRUDItemButtons
