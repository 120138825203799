import React from 'react'

const Info = (props) => {
  const {leaderboard} = props
  const type = leaderboard["items"][0]["type"]
  const venuemachines = leaderboard["venuemachines"]
  const players = leaderboard["players"]

  return <div className="Leaderboard-Header-Info">
    {type === "venuemachine" &&
      <div>
        <span className="material-icons">location_on</span>
        {leaderboard["items"][0]["venuemachine"]["venue"]["name"]}
      </div>
    }
    {(type === "machine" || type === "machinegroup") &&
      <div>
        <span className="material-icons">location_on</span>
        {!venuemachines.length
          ? 'All machines'
          : `${venuemachines.length} machine${venuemachines.length > 1 ? 's' : ''}`
        }
      </div>
    }
    <div>
      <span className="material-icons">emoji_people</span>
        {!players.length
          ? 'all players'
          : `${players.length} players`
        }
    </div>
    <Time leaderboard={leaderboard} />
  </div>
}

const Time = (props) => {
  const {leaderboard} = props
  const timeType = leaderboard["leaderboard_time_type"]

  const renderAllTime = () => 'All time'

  const renderLastCalendar = () => {
    return `this ${leaderboard["last_time_bucket"]}`
  }

  const renderLastRolling = () => {
    const bucket = leaderboard["last_time_bucket"]
    const numBuckets = leaderboard["last_time_count"]
    return `last ${numBuckets} ${bucket}${numBuckets > 1 ? 's' : ''}`
  }

  const renderTimeBox = () => {
    const start = new Date(leaderboard["score_start_time"])
    const end = leaderboard["score_end_time"] ? new Date(leaderboard["score_end_time"]) : null
    const formatOptions = { month: 'short', day: 'numeric' }
    let output =  `${start.toLocaleDateString(undefined, formatOptions)} - `
    if (end) {
      output +=  `${end.toLocaleDateString(undefined, formatOptions)}`
    } else {
      output += `now`
    }
    return output
  }

  return (
    <div>
      <span className="material-icons">access_time</span>
      {timeType === 'all_time' && renderAllTime()}
      {timeType === 'last_calendar' && renderLastCalendar()}
      {timeType === 'last_rolling' && renderLastRolling()}
      {timeType === 'time_box' && renderTimeBox()}
    </div>
  )
}

export default Info