import {doRequest} from './../../../utils/xhr'

const doUsersRequest = (searchTerm) => {
  const url = `/api/user?search=${searchTerm}&ordering=usernames`
  return doRequest(url, 'GET')
    .then(response => response.results)
}

const doUserRequest = (id) => {
  const url = `/api/user/${id}`
  return doRequest(url, 'GET')
}

export {doUsersRequest, doUserRequest}