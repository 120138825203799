import {doRequest} from './../../utils/xhr'

const doBootstrapRequest = async (ownerId) => {
  const licensesURL = `/api/user_profile/licenses/`
  const licenses = await doRequest(licensesURL, 'GET')

  const venueMachinesURL = `/api/venuemachine/?owner=${ownerId}&limit=500`
  const venueMachines = await doRequest(venueMachinesURL, 'GET')
    .then(response => response.results)

  return {licenses, venueMachines}
}

const doMachineRequest = (machineId) => {
  return doRequest(`/api/venuemachine/${machineId}/`, 'GET')
}

const detachScorbitronRequest = (machineId) => {
  return doRequest(`/api/venuemachine/${machineId}/unpair/`, 'POST')
}

const deleteVenueMachineRequest = (machineId) => {
  return doRequest(`/api/venuemachine/${machineId}/`, 'DELETE')
}

const getUserByUsernameRequest = (username) => {
  return doRequest(`/api/user/?username=${username}`, 'GET')
    .then(response => response.results[0])
}

const doTransferRequest = (machineId, userId) => {
  const payload = {"new_owner": userId}
  return doRequest(`/api/venuemachine/${machineId}/transfer/`, 'POST', payload)
}

export {
  doBootstrapRequest,
  doMachineRequest,
  detachScorbitronRequest,
  deleteVenueMachineRequest,
  getUserByUsernameRequest,
  doTransferRequest,
}