import {
  getAPIURL,
  getScoreAPIURL,
  getXHROptions,
  handleErrors,
} from './../../../utils/xhr'

const getBootstrapRequest = (type, id) => {
  return fetch(getAPIURL(`/api/${type}/${id}/bootstrap/`), getXHROptions().GET)
    .then(handleErrors)
    .then(response => response.json())
    .catch(error => {return {error: error.toString()}})
}

const getComboboardScoresRequest = (id) => {
  return fetch(getScoreAPIURL(`/comboboard/${id}/scores/`), getXHROptions().GET)
    .then(handleErrors)
    .then(response => response.json())
    .catch(error => {return {error: error.toString()}})
}

const getScoreboardScoresRequest = (id) => {
  return fetch(getScoreAPIURL(`/scoreboard/${id}/scores/`), getXHROptions().GET)
    .then(handleErrors)
    .then(response => response.json())
    .catch(error => {return {error: error.toString()}})
}

const getLeaderboardScoresRequest = (id) => {
  return fetch(getScoreAPIURL(`/leaderboard/${id}/scores/`), getXHROptions().GET)
    .then(handleErrors)
    .then(response => response.json())
    .catch(error => {return {error: error.toString()}})
}

const getVenuemachineHotRequest = async () => {
  return fetch(getAPIURL(`/api/venuemachine_hot`), getXHROptions().GET)
    .then(handleErrors)
    .then(response => response.json())
    .catch(error => {return {error: error.toString()}})
}

const getVenuemachineByIdRequest = async (id) => {
  return fetch(getAPIURL(`/api/venuemachine/${id}`), getXHROptions().GET)
    .then(handleErrors)
    .then(response => response.json())
    .catch(error => {return {error: error.toString()}})
}

const getVenuemachineCurrentByIdRequest = async (id) => {
  return fetch(getAPIURL(`/api/venuemachine/${id}/current`), getXHROptions().GET)
    .then(handleErrors)
    .then(response => response.json())
    .catch(error => {return {error: error.toString()}})
}


export {
  getBootstrapRequest,
  getComboboardScoresRequest,
  getScoreboardScoresRequest,
  getLeaderboardScoresRequest,
  getVenuemachineHotRequest,
  getVenuemachineByIdRequest,
  getVenuemachineCurrentByIdRequest
}
