import React, {useEffect, useState} from 'react'
import VenueMachineSelect from '../UI/VenueMachineSelect'

const MachineWhiteList = (props) => {
  const [venueMachines, setVenueMachines] = useState(props.venueMachines || [])
  const [adding, setAdding] = useState(false)

  const changeVenueMachine = (index, venueMachineId) => {
    if (index === null) {
      setVenueMachines([...venueMachines, venueMachineId])
      setAdding(false)
    } else {
      if (venueMachineId === null) {
        setVenueMachines([...venueMachines.filter((v, k) => k !== index)])
      } else {
        const nextVenueMachines = [...venueMachines]
        nextVenueMachines[index] = venueMachineId
        setVenueMachines(nextVenueMachines)
      }
    }
  }

  const removeVenueMachine = (venueMachineId) => {
    setVenueMachines(venueMachines.filter(id => id !== venueMachineId))
  }

  useEffect(() => {
    props.setWhiteList(venueMachines)
  }, [props, venueMachines])

  return (
    <div>
      {venueMachines && venueMachines.map((id, i) =>
        <div className="whiteListItem" key={i}>
          <VenueMachineSelect
            id={id}
            defaultVenueMachines={props.userVenueMachines}
            onChange={venueMachineId => changeVenueMachine(i, venueMachineId)}
          />
          <button
            type="button"
            className="Button Button--secondary Button--small removeWhiteListItem"
            onClick={() => removeVenueMachine(id)}
          >
            remove
          </button>
        </div>
      )}
      {!adding &&
        <button
          type="button"
          className="Button Button--secondary Button--small"
          onClick={() => setAdding(true)}
        >
          Add Machine
        </button>
      }
      {adding &&
        <VenueMachineSelect
          id={null}
          defaultVenueMachines={props.userVenueMachines}
          onChange={venueMachineId => changeVenueMachine(null, venueMachineId)}
        />
      }
    </div>
  )
}

export default MachineWhiteList