import React, {useContext, useState, useEffect, useCallback} from 'react'
import AppContext from './../../contexts/app'
import CRUDTitle from './../UI/CRUDTitle'
import CRUDPublicPrivate from './../UI/CRUDPublicPrivate'
import CRUDFormButtons from './../UI/CRUDFormButtons'
import VenueMachineSelect from './../UI/VenueMachineSelect'
import { getResourcesRequest } from '../Comboboards/http'
import Select from 'react-select'
import HotgameType1 from './../../assets/img/hotgame-layouts/hotgame-space-invaders.gif'
import HotgameType2 from './../../assets/img/hotgame-layouts/hotgame-color-bars.gif'
import HotgameType3 from './../../assets/img/hotgame-layouts/hotgame-dead-television-channel.gif'
import HotgameType4 from './../../assets/img/hotgame-layouts/hotgame-pinball.gif'
import HotgameTypeRandom from './../../assets/img/hotgame-layouts/hotgame-random.png'

const Form = (props) => {
  const {userVenueMachines, profile, scrollToTop} = useContext(AppContext)
  const [action, setAction] = useState(null)
  const [validationError, setValidationError] = useState(null)
  const [title, setTitle] = useState(null)
  const [isPublic, setIsPublic] = useState(false)
  const [prevTitle, setPrevTitle] = useState(null)
  const [machines, setMachines] = useState(Array(1).fill(null))
  const [busy, setBusy] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState('#000')
  const [displayTitle, setDisplayTitle] = useState(false)  
  const [idleLeaderboardShow, setIdleLeaderboardShow] = useState(false)
  const [idleLeaderboardSec, setIdleLeaderboardSec] = useState(10)
  const [idleScoreboardSec, setIdleScoreboardSec] = useState(10)
  const [leaderboardItem, setLeaderboardItem] = useState(null)
  const [leaderboardsOptions, setLeaderboardsOptions] = useState(null)
  const [idleHotgameShow, setIdleHotgameShow] = useState(false)
  const [idleHotgameType, setIdleHotgameType] = useState(0)
  const [enablePlayerRank, setEnablePlayerRank] = useState(false)
  const hotgameType =  [
    {
      label: 'Random',
      img: HotgameTypeRandom,
    },
    {
      label: 'Space Invaders',
      img: HotgameType1,
    },
    {
      label: 'Color Bars',
      img: HotgameType2,
    },
    {
      label: 'Dead Television Channel',
      img: HotgameType3,
    },
    {
      label: 'Atari 2600 Pinball',
      img: HotgameType4,
    }
  ]
  const getResources = useCallback(() => {
    getResourcesRequest(profile.id)
    .then(resources => {
      const { leaderboards } = resources
      const makeOption = (item) => {
        return {
          value: item.id,
          label: item.title,
        }
      }
      setLeaderboardsOptions([{value: null, label: '--none--'}, ...leaderboards.map(makeOption)])
    })
  }, [profile.id])
  useEffect(getResources, [getResources])

  const mapPropsToState = useCallback(() => {
    const {item} = props
    setAction(item ? 'update' : 'create')
    if (!item) return
    setTitle(item["title"])
    setPrevTitle(item["title"])
    setIsPublic(item["is_public"])
    setBackgroundColor(item["background_color"])
    setDisplayTitle(item['display_title']);
    setIdleLeaderboardShow(item['idle_leaderboard_show']);
    setIdleLeaderboardSec(item['idle_leaderboard_sec']);
    setIdleScoreboardSec(item['idle_scoreboard_sec']);
    setIdleHotgameType(item['idle_hotgame_type']);
    setEnablePlayerRank(item['enablePlayerRank']);
    if (item['idle_leaderboard_show']) {
      setIdleHotgameShow(false);
    } else {
      setIdleHotgameShow(item['idle_hotgame_show']);
    }
    if (leaderboardsOptions && item['leaderboard']) {
      setLeaderboardItem(leaderboardsOptions.find(leaderboardOption => leaderboardOption['value'] === item['leaderboard']))
    } else {
      setLeaderboardItem({ value: null, label: '--none--' })
    }
    setMachines([...machines].map((v, i) =>
      item.venuemachine_items[i]
        ? item.venuemachine_items[i].venuemachine
        : null
    ))
  }, [props, machines, leaderboardsOptions])
  useEffect(mapPropsToState, [props.item, leaderboardsOptions])

  const validate = () => {
    const validMachines = machines.filter(m => m !== null)
    const uniqueMachines = [...new Set(validMachines)]
    const titles = props.items.map(item => item.title)
    const isDupe = titles.filter(item => item === title).length ? true : false
    let validationError = null

    // validate title not blank
    if (!title || !title.length) {
      validationError = 'Title must not be blank'
    }

    // validate unique title when action is create
    else if (action === 'create' && isDupe) {
      validationError = 'Title must be unique'
    }

    // validate unique title when action is update
    else if (action === 'update' && isDupe && title !== prevTitle) {
      validationError = 'Title must be unique'
    }

    // validate at least one machine
    else if (!uniqueMachines.length) {
      validationError = 'Please select at least 1 machine'
    }

    // validate no duplicate machines
    else if (uniqueMachines.length !== validMachines.length) {
      validationError = `No duplicate machines!`
    }

    return validationError
  }

  const onSubmit = (event) => {
    event.preventDefault()
    setBusy(true)
    const validationError = validate()
    if (validationError) {
      setValidationError(validationError)
      setBusy(false)
      scrollToTop()
    } else {
      const validMachines = machines.filter(id => id !== null)
      props.onSubmit({
        "title": title,
        "background_color": backgroundColor,
        "display_title": displayTitle,
        "venuemachine_items": validMachines.map((id, i) => ({
          "venuemachine": id,
          "order": i,
        })),
        "is_public": isPublic,
        "leaderboard": (leaderboardItem && leaderboardItem.value !== null) ? leaderboardItem.value : null,
        "idle_leaderboard_show": idleLeaderboardShow,
        "idle_leaderboard_sec": idleLeaderboardSec,
        "idle_scoreboard_sec": idleScoreboardSec,
        "idle_hotgame_show": idleHotgameShow,
        "idle_hotgame_type": idleHotgameType,
        "enablePlayerRank": enablePlayerRank
      })
    }
  }

  const changeMachine = (machineIndex, venueMachineId) => {
    setMachines((prevMachines) => {
      prevMachines[machineIndex] = venueMachineId
      return prevMachines
    })
  }

  const renderMachineSelect = (index) => {
    return (
      <div className="formRow" key={index}>
        <VenueMachineSelect
          id={machines[index]}
          defaultVenueMachines={userVenueMachines}
          onChange={venueMachineId => changeMachine(index, venueMachineId)}
        />
      </div>
    )
  }

  return (
    <form onSubmit={onSubmit} className="has-required-fields">
      {validationError &&
        <div className="formError">
          {validationError}
        </div>
      }
      <div className="formRow">
        <CRUDTitle
          title={title}
          setTitle={setTitle}
        />
      </div>
      
      <div className="formRow">
        <label className="primary">
          <span>Display Title</span>
        </label>

        <div className="flexRow">
          <label htmlFor="enableDisplayTitle">
            <input
              id="enableDisplayTitle"
              type="radio"
              name="displayTitle"
              checked={displayTitle}
              onChange={() => setDisplayTitle(true)}
            />
            Enable
          </label>
          <label htmlFor="disableDisplayTitle">
            <input
              id="disableDisplayTitle"
              type="radio"
              name="displayTitle"
              checked={!displayTitle}
              onChange={() => setDisplayTitle(false)}
            />
            Disable
          </label>
        </div>
      </div>

      <div className="formRow">
        <CRUDPublicPrivate
          isPublic={isPublic}
          setIsPublic={setIsPublic}
        />
      </div>
      <div className="formRow">
        <label className="primary">
          <span>Select machine</span>
          <br />
          <span className="tip">
            Tip: List defaults to machines you own. Search for
            <br />other public machines by title and/or venue.
          </span>
        </label>
        {Array(1).fill(null).map((v, i) => renderMachineSelect(i))}
      </div>
      <div className="formRow">
        <label className="primary">
          <span>Background Color</span>
        </label>
        <input
          className="colorPicker"
          type="color"
          value={backgroundColor}
          onChange={(event) => {
            setBackgroundColor(event.target.value);
          }}
        />
      </div>
      <div className="formRow">
        <label className="primary">
          <span>Show Live Player Rank</span>
        </label>

        <div className="flexRow">
          <label htmlFor="enablePlayerRank">
            <input
              id="enablePlayerRank"
              type="radio"
              name="enablePlayerRank"
              checked={enablePlayerRank}
              onChange={() => setEnablePlayerRank(true)}
            />
            Enable
          </label>
          <label htmlFor="disablePlayerRank">
            <input
              id="disablePlayerRank"
              type="radio"
              name="enablePlayerRank"
              checked={!enablePlayerRank}
              onChange={() => setEnablePlayerRank(false)}
            />
            Disable
          </label>
        </div>
      </div>    
      <div className="formRow">
        <label className="primary">
          <span>Show Leaderboard When Idle</span>
        </label>

        <div className="flexRow">
          <label htmlFor="enableIdleLeaderboardShow">
            <input
              id="enableIdleLeaderboardShow"
              type="radio"
              name="idleLeaderboardShow"
              checked={idleLeaderboardShow}
              onChange={() => {
                setIdleLeaderboardShow(true)
                setIdleHotgameShow(false)
              }}
            />
            Enable
          </label>
          <label htmlFor="disableIdleLeaderboardShow">
            <input
              id="disableIdleLeaderboardShow"
              type="radio"
              name="idleLeaderboardShow"
              checked={!idleLeaderboardShow}
              onChange={() => setIdleLeaderboardShow(false)}
            />
            Disable
          </label>
        </div>
      </div>
      {idleLeaderboardShow && <>
        <div className="formRow">
          <label className="primary">
            <span>Select Leaderboard When Idle</span>
            <br />
            <span className="tip">
              Tip: A leaderboard selected here will automatically display
              <br />when a scoreboard is idle/game over for ten seconds.
              <br />The scoreboard will return when a game starts.
            </span>
          </label>
          <Select
            className="react-select"
            classNamePrefix="react-select"
            value={leaderboardItem}
            onChange={(option) => {
              setLeaderboardItem(option)
            }}
            options={leaderboardsOptions}
          />
        </div>
        <div className="formRow">
          <label className="primary">
            <span>During Idle Display Scoreboard (in seconds)</span>
          </label>
          <input
            className="idleScoreboardSec"
            type="text"
            value={idleScoreboardSec}
            onChange={(event) => {
              setIdleScoreboardSec(event.target.value);
            }}
          />
        </div>
        <div className="formRow">
          <label className="primary">
            <span>During Idle Display Leaderboard (in seconds)</span>
          </label>
          <input
            className="idleLeaderboardSec"
            type="text"
            value={idleLeaderboardSec}
            onChange={(event) => {
              setIdleLeaderboardSec(event.target.value);
            }}
          />
        </div>
      </>}
            
      <div className="formRow">
        <label className="primary">
          <span>Show Hot Active Game When Idle</span>
        </label>

        <div className="flexRow">
          <label htmlFor="enableIdleHotgameShow">
            <input
              id="enableIdleHotgameShow"
              type="radio"
              name="idleHotgameShow"
              checked={idleHotgameShow}
              onChange={() => {
                setIdleLeaderboardShow(false)
                setIdleHotgameShow(true)
              }}
            />
            Enable
          </label>
          <label htmlFor="disableIdleHotgameShow">
            <input
              id="disableIdleHotgameShow"
              type="radio"
              name="idleHotgameShow"
              checked={!idleHotgameShow}
              onChange={() => setIdleHotgameShow(false)}
            />
            Disable
          </label>
        </div>
      </div>

      {idleHotgameShow && <div className="formRow wide">
        <label className="primary">
          Select Screen To Display When No Game is Available
        </label>
        {hotgameType.map((item, key) =>
          <label
            className={`hasImage ${idleHotgameType === key ? ' selected' : ''}`}
            htmlFor={`layout-${key}`}
            key={key}
          >
            <div>
              <input
                id={`layout-${key}`}
                type="radio"
                name={`layout-${key}`}
                checked={idleHotgameType === key}
                onChange={() => { setIdleHotgameType(key) }}
              />
              <img src={hotgameType[key].img} alt="" width="200" height="112" />
            </div>
            <div>
              <strong>
                {hotgameType[key].label}
              </strong>
            </div>
          </label>
        )}
      </div>}
      <CRUDFormButtons
        busy={busy}
        disableSubmit={busy}
        titleForSubmit={props.titleForSubmit}
        cancelHref="/scoreboards"
      />
    </form>
  )
}

export default Form
