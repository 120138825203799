import React, {Fragment, useState, useContext, useEffect, useCallback} from 'react'
import Select from 'react-select'
import AppContext from './../../contexts/app'
import {getResourcesRequest, uploadSlideShowImageRequest, getSlideShowImageRequest} from './http'
import CRUDFormButtons from './../UI/CRUDFormButtons'
import CRUDTitle from './../UI/CRUDTitle'
import CRUDPublicPrivate from './../UI/CRUDPublicPrivate'
import Loading from './../UI/Loading'

const Form = (props) => {
  const durations = [5, 10, 30, 60, 120]
  const newScreenTemplate = {
    type: 'scoreboard',
    itemId: null,
    duration: durations[0],
  }
  const defaultScreens = [{...newScreenTemplate}, {...newScreenTemplate}]
  const {profile, scrollToTop} = useContext(AppContext)
  const [action, setAction] = useState(null)
  const [validationError, setValidationError] = useState(null)
  const [title, setTitle] = useState(null)
  const [isPublic, setIsPublic] = useState(false)
  const [prevTitle, setPrevTitle] = useState(null)
  const [busy, setBusy] = useState(false)
  const [scoreboards, setScoreboards] = useState(null)
  const [scoreboardsOptions, setScoreboardsOptions] = useState([])
  const [leaderboards, setLeaderboards] = useState(null)
  const [leaderboardsOptions, setLeaderboardsOptions] = useState([])
  const [comboboards, setComboboards] = useState(null)
  const [comboboardsOptions, setComboboardsOptions] = useState([])
  const [loading, setLoading] = useState(true)
  const [screens, setScreens] = useState(defaultScreens)

  const mapPropsToState = useCallback(() => {
    const {item} = props
    setAction(item ? 'update' : 'create')
    if (!item) return
    setTitle(item["title"])
    setPrevTitle(item["title"])
    setIsPublic(item["is_public"])
    async function fetchData() {      
      var screenData = [];
      for (var screen of item.items) {     
        if (screen["item_type"] === "slideshowimage") {
          // eslint-disable-next-line no-loop-func
          await getSlideShowImageRequest(screen["item_id"]).then(res => {
            screenData.push({
              type: screen["item_type"],
              itemId: screen["item_id"],
              duration: screen["duration"],
              imageSrc: res.image
            })
          })
        } else {      
          screenData.push({
            type: screen["item_type"],
            itemId: screen["item_id"],
            duration: screen["duration"]
          })
        }
      }
      setScreens(screenData)
    }
    fetchData();
  }, [props])
  useEffect(mapPropsToState, [props.item])

  const getResources = useCallback(() => {
    getResourcesRequest(profile.id)
    .then(resources => {
      const {scoreboards, leaderboards, comboboards} = resources
      const makeOption = (item) => {
        return {
          value: item.id,
          label: item.title,
        }
      }
      setScoreboards(scoreboards)
      setScoreboardsOptions(scoreboards.map(makeOption))
      setLeaderboards(leaderboards)
      setLeaderboardsOptions(leaderboards.map(makeOption))
      setComboboards(comboboards)
      setComboboardsOptions(comboboards.map(makeOption))
      setLoading(false)
    })
  }, [profile.id])
  useEffect(getResources, [getResources])

  const validate = () => {
    const titles = props.items.map(item => item.title)
    const isDupe = titles.filter(item => item === title).length ? true : false
    let validationError = ''

    // validate title not blank
    if (!title || !title.length) {
      validationError = 'Title must not be blank'
    }

    // validate unique title when action is create
    else if (action === 'create' && isDupe) {
      validationError = 'Title must be unique'
    }

    // validate unique title when action is update
    else if (action === 'update' && isDupe && title !== prevTitle) {
      validationError = 'Title must be unique'
    }

    // validate at leaset two screens
    else if (screens.length < 2) {
      validationError = 'A slideshow must have at least two screens.'
    }

    // every screen must have an item
    else if (screens.filter(screen => screen.itemId === null).length) {
      validationError = 'Each screen must have a scoreboard, leaderboard, or comboboard'
    }

    // every item must be unique
    else if ([...new Set(screens.map(screen => `${screen.type}${screen.itemId}`))].length < screens.length) {
      validationError = 'Each screen must be unique'
    }

    // show error or proceed
    if (validationError.length) {
      setValidationError(validationError)
      return false
    } else {
      setValidationError(null)
      return true
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    setBusy(true)
    if (validate()) {
      const items = screens.map((screen, i) => ({
        "item_type": screen.type,
        "item_id": screen.itemId,
        "duration": screen.duration,
        "order": i,
      }))

      const slideshow = {
        "title": title,
        "is_public": isPublic,
        "items": items,
      }

      props.onSubmit(slideshow)
    } else {
      setBusy(false)
      scrollToTop()
    }
  }

  const renderSelect = ({key, type, value, options, onChange}) =>
    <div className="formRow">
      <div>
        <label>
          {type === 'scoreboard' && 'Select Scoreboard'}
          {type === 'leaderboard' && 'Select Leaderboard'}
          {type === 'comboboard' && 'Select Comboboard'}
          {type === 'slideshowimage' && 'Select Image'}
        </label>
      </div>
      {type === 'slideshowimage' && <div>
        <input type='file' accept='image/*' onChange={onChange} />
        <div>
          {screens && screens[key] && screens[key].imageSrc && <img className='slideshowimage-preview' alt="" src={screens[key].imageSrc} />}
        </div>
      </div>}
      {type !== 'slideshowimage' && <Select
        key={key}
        className="react-select"
        classNamePrefix="react-select"
        value={value}
        onChange={(option) => onChange(key, option)}
        options={options}
      />}
    </div>

  const renderScoreboardsSelect = (i) => {
    if (!scoreboards.length) return null
    const getSelectedOption = () => {
      if (!screens[i].itemId) {
        return null
      } else {
        return scoreboardsOptions.find(option => option.value === screens[i].itemId)
      }
    }
    const onChange = (i, option) => {
      changeScreenItem(i, 'scoreboard', option)
    }

    return renderSelect({
      key: i,
      value: getSelectedOption(),
      options: scoreboardsOptions,
      onChange: onChange,
      type: 'scoreboard',
    })
  }

  const renderLeaderboardsSelect = (i) => {
    if (!leaderboards.length) return null
    const getSelectedOption = () => {
      if (!screens[i].itemId) {
        return null
      } else {
        return leaderboardsOptions.find(option => option.value === screens[i].itemId)
      }
    }
    const onChange = (i, option) => {
      changeScreenItem(i, 'leaderboard', option)
    }

    return renderSelect({
      key: i,
      value: getSelectedOption(),
      options: leaderboardsOptions,
      onChange: onChange,
      type: 'leaderboard',
    })
  }

  const renderComboboardsSelect = (i) => {
    if (!comboboards.length) return null
    const getSelectedOption = () => {
      if (!screens[i].itemId) {
        return null
      } else {
        return comboboardsOptions.find(option => option.value === screens[i].itemId)
      }
    }
    const onChange = (i, option) => {
      changeScreenItem(i, 'comboboard', option)
    }  
    
    return renderSelect({
      key: i,
      value: getSelectedOption(),
      options: comboboardsOptions,
      onChange: onChange,
      type: 'comboboard',
    })
  }
  
  const renderSlideshowimageSelect = (i) => {
    const onChange = async (event) => {
      const file = event.target.files[0]

      if (file) {
        changeScreenImage(i, 'slideshowimage', null, URL.createObjectURL(file))
        await uploadSlideShowImageRequest(file).then(response => {
          if (response.id)
            changeScreenImage(i, 'slideshowimage', response.id, response.image)
        })
      }
    }
    return renderSelect({
      key: i,
      onChange: onChange,
      type: 'slideshowimage',
    })
  }

  const createScreen = (event) => {
    event.preventDefault()
    setScreens([...screens, {
      type: 'scoreboard',
      itemId: null,
      duration: durations[0]
    }])
  }

  const deleteScreen = (event, i) => {
    event.preventDefault()
    const nextScreens = [...screens]
    nextScreens.splice(i, 1)
    setScreens(nextScreens)
  }

  const changeScreenItem = (i, type, option) => {
    const nextScreens = [...screens]
    nextScreens[i].type = type
    nextScreens[i].itemId = option.value
    setScreens(nextScreens)
  }

  const changeScreenImage = (i, type, itemId, imageSrc) => {
    const nextScreens = [...screens]
    nextScreens[i].type = type
    nextScreens[i].itemId = itemId
    nextScreens[i].imageSrc = imageSrc
    setScreens(nextScreens)
  }

  const setScreenType = (i, type) => {
    const nextScreens = [...screens]
    nextScreens[i].type = type
    nextScreens[i].itemId = null
    setScreens(nextScreens)
  }

  const setScreenDuration = (i, duration) => {
    const nextScreens = [...screens]
    nextScreens[i].duration = duration
    setScreens(nextScreens)
  }

  const reorderScreens = (event, i, direction) => {
    event.preventDefault()
    const nextScreens = [...screens]
    const x = i
    const y = direction === 'up' ? i - 1 : i + 1
    nextScreens[x] = nextScreens.splice(y, 1, nextScreens[x])[0]
    setScreens(nextScreens)
  }

  const renderScreen = (screen, i) =>
    <div
      key={i}
      className="formRow screen"
    >
      <div className="formRow flexRow screen-type-buttons">
        <div className="screen-type flexRow">
          <label>Type:</label>
          {scoreboards.length
            ? <label htmlFor={`screenTypeScoreboard-${i}`}>
                <input
                  id={`screenTypeScoreboard-${i}`}
                  type="radio"
                  name={`screenTypeScoreboard-${i}`}
                  checked={screen.type === 'scoreboard'}
                  onChange={() => setScreenType(i, 'scoreboard')}
                />
                Scoreboard
              </label>
            : null
          }
          {leaderboards.length
            ? <label htmlFor={`screenTypeLeaderboard-${i}`}>
                <input
                  id={`screenTypeLeaderboard-${i}`}
                  type="radio"
                  name={`screenTypeLeaderboard-${i}`}
                  checked={screen.type === 'leaderboard'}
                  onChange={() => setScreenType(i, 'leaderboard')}
                />
                Leaderboard
              </label>
            : null
          }
          {comboboards.length
            ? <label htmlFor={`screenTypeComboboard-${i}`}>
                <input
                  id={`screenTypeComboboard-${i}`}
                  type="radio"
                  name={`screenTypeComboboard-${i}`}
                  checked={screen.type === 'comboboard'}
                  onChange={() => setScreenType(i, 'comboboard')}
                />
                Comboboard
              </label>
            : null
          }
          <label htmlFor={`screenTypeSlideshowimage-${i}`}>
            <input
              id={`screenTypeSlideshowimage-${i}`}
              type="radio"
              name={`screenTypeSlideshowimage-${i}`}
              checked={screen.type === 'slideshowimage'}
              onChange={() => setScreenType(i, 'slideshowimage')}
            />
            Image
          </label>
        </div>
        <div className="screen-buttons">
          {screens.length >= 2 && i > 0 &&
            <button
              className="Button Button--secondary Button--small"
              onClick={(event) => reorderScreens(event, i, 'up')}
              title={'move up'}
            >
              <i className="material-icons">arrow_upward</i>
            </button>
          }
          {screens.length >= 2 && i < screens.length - 1 &&
            <button
              className="Button Button--secondary Button--small"
              onClick={(event) => reorderScreens(event, i, 'down')}
              title={'move down'}
            >
              <i className="material-icons">arrow_downward</i>
            </button>
          }
          {screens.length > 2 &&
            <button
              className="Button Button--secondary Button--small"
              onClick={(event) => deleteScreen(event, i)}
              title={'delete'}
            >
              <i className="material-icons">close</i>
            </button>
          }
        </div>
      </div>
      {screen.type === 'scoreboard' && renderScoreboardsSelect(i)}
      {screen.type === 'leaderboard' && renderLeaderboardsSelect(i)}
      {screen.type === 'comboboard' && renderComboboardsSelect(i)}
      {screen.type === 'slideshowimage' && renderSlideshowimageSelect(i)}
      <div className="flexRow">
        <label>Duration (seconds)</label>
        {durations.map((duration, j) =>
          <label
            key={j}
            htmlFor={`screenDuration-${i}-${j}`}
          >
            <input
              id={`screenDuration-${i}-${j}`}
              type="radio"
              name={`screenDuration-${i}`}
              checked={screen.duration === duration}
              onChange={() => setScreenDuration(i, duration)}
            />
            {duration}
          </label>
        )}
      </div>
    </div>

  const renderForm = () =>
    <form
      onSubmit={onSubmit}
      className="has-required-fields"
    >
      {validationError &&
        <div className="formError">{validationError}</div>
      }
      <div className="formRow">
        <CRUDTitle
          title={title}
          setTitle={setTitle}
        />
      </div>
      <div className="formRow">
        <CRUDPublicPrivate
          isPublic={isPublic}
          setIsPublic={setIsPublic}
        />
      </div>
      <div className="formRow">
        <label className="primary">Screens</label>
        {screens.length < 2 &&
          <Fragment>
            {renderScreen()}
            {renderScreen()}
          </Fragment>
        }
        {screens.map(renderScreen)}
      </div>
      <div className="formRow">
        <button
          className="Button Button--secondary"
          onClick={createScreen}
        >
          Add Screen
        </button>
      </div>
      <CRUDFormButtons
        busy={busy}
        disableSubmit={busy}
        titleForSubmit={props.titleForSubmit}
        cancelHref="/slideshows"
      />
    </form>

  const renderNoResources = () =>
    <p>
      You must create at least one scoreboard or leaderboard to use this feature.
    </p>

  return loading
    ? <Loading />
    : !scoreboards && !leaderboards && !comboboards
      ? renderNoResources()
      : renderForm()
}

export default Form
