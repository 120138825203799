import React, {useState, useEffect} from 'react'
import {getCRUDRequests} from './../../../utils/xhr'
import {setUser, getUserById} from './../../../utils/users'

const {read: fetchUser} = getCRUDRequests('/api/user')

const CRUDUsername = (props) => {
  const [username, setUsername] = useState(null)

  const getUser = (id) => {
    fetchUser(id)
      .then(user => {
        setUser(user)
        setUsername(user["cached_display_name"])
      })
  }

  useEffect(() => {
    let user = getUserById(props.id)
    user
      ? setUsername(user.username)
      : getUser(props.id)
    }, [props.id])

  return  (
    <div className="CRUD-Username">
      {username}
    </div>
  )
}

export default CRUDUsername