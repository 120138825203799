import React from 'react'
import {Link} from 'react-router-dom'
import {capitalize, pluralize} from './../../../utils/string'
import CRUDItemList from './../CRUDItemList'
import Loading from './../Loading'

const CRUDIndex = (props) => {
  const {loading, resource, renderListItem, items, disableCreate, enableBulkCreate} = props
  const className = capitalize(pluralize(resource))

  const renderEmptyList = () => {
    return (
      <p>{`You have not created any ${pluralize(resource)}!`}</p>
    )
  }

  const renderItems = () => {
    const label = capitalize(resource)
    const className = capitalize(resource)
    const pathToCreate = `/${resource}s/add`
    const pathToBulkCreate = `/${resource}s/wizard`
    const newItemLabel = `Add ${capitalize(resource)}`
    const newBulkItemLabel = `${capitalize(resource)} Wizard`
    return (
      <div>
        {!items.length && renderEmptyList()}
        {items.length > 0 && (
          <CRUDItemList
            className={`${pluralize(capitalize(resource))}-List ${className}`}
            label={label}
            items={items.map(renderListItem)}
            newItemLink={<Link to={pathToCreate}>{`+ ${newItemLabel}`}</Link>}
            disableCreate={disableCreate}
            enableBulkCreate={enableBulkCreate}
          />
        )}
        {!disableCreate &&
          <Link to={pathToCreate} className="Button">
            {newItemLabel}
          </Link>
        }
        {enableBulkCreate &&
          <>
              <br />
              <br />
              <Link to={pathToBulkCreate} className="Button">
                {newBulkItemLabel}
              </Link>
          </>
        }
      </div>
    )
  }

  return (
    <div className={className}>
      {loading
        ? <Loading />
        : renderItems()
      }
    </div>
  )
}

export default CRUDIndex
