import React from 'react'
import CRUDable from './../UI/CRUDable'
import Form from './form'
import LeaderboardsIcon from './../../assets/img/nav-icons/leaderboards.svg'
import './style.scss'

const Leaderboards = (props) => {
  return (
    <CRUDable
      title="ScorbitVision - My Leaderboards"
      className="Leaderboards"
      resource="leaderboard"
      form={Form}
      icon={LeaderboardsIcon}
      {...props}
    />
  )
}

export default Leaderboards
