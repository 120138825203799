import React, {useState, useEffect} from 'react'
import UserSelect from './../UI/UserSelect'

const UserWhiteList = (props) => {
  const [users, setUsers] = useState(props.userWhiteList || [])
  const [adding, setAdding] = useState(false)

  const changeUser = (index, userId) => {
    if (index === null) {
      setUsers([...users, userId])
      setAdding(false)
    } else {
      const nextUsers = [...users]
      nextUsers[index] = userId
      setUsers(nextUsers)
    }
  }

  const removeUser = (userId) => {
    setUsers(users.filter(id => id !== userId))
  }

  useEffect(() => {
    props.setUserWhiteList(users)
  }, [props, users])

  return (
    <div className="UserSelect">
      {users && users.map((id, i) =>
        <div className="whiteListItem" key={i}>
          <UserSelect
            id={id}
            onChange={userId => changeUser(i, userId)}
          />
          <button
            type="button"
            className="Button Button--secondary Button--small removeWhiteListItem"
            onClick={() => removeUser(id)}
          >
            remove
          </button>
        </div>
      )}
      {!adding &&
        <button
          type="button"
          className="Button Button--secondary Button--small"
          onClick={() => setAdding(true)}
        >
          Add User
        </button>
      }
      {adding &&
        <UserSelect
          id={null}
          onChange={userId => changeUser(null, userId)}
        />
      }
    </div>
  )
}

export default UserWhiteList