import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import './style.scss'


const PrimaryNavigation = (props) => {
  const {navItems, logo} = props
  const location = useLocation()
  const {pathname} = location
  const current = navItems.find(link => pathname.split('/')[1] === link.href.split('/')[1])
  return (
    <div className="PrimaryNavigation">
      <nav>
        <ul>
          <li>
            <Link to="/" className="HomeLink">
              <img src={logo} className="HomeLink-Logo" alt="Scorbit Logo" />
            </Link>
          </li>
          {navItems.map(link =>
            <li
              key={link.href}
              className={link === current ? 'current' : ''}
            >
              {link.disabled
                ? <span className="Primary-Navigation__link Primary-Navigation__link--disabled">
                    <img src={link.icon} alt="" />
                    {link.label}
                  </span>
                : <Link to={link.href} className="Primary-Navigation__link">
                    <img src={link.icon} alt="" />
                    {link.label}
                  </Link>
              }
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}

export default PrimaryNavigation
