import React, {Fragment} from 'react'

const CRUDTitle = (props) => {
  const {title, setTitle} = props

  return (
    <Fragment>
      <label htmlFor="title" className="primary required">Title</label>
      <input
        className="wide"
        type="text"
        id="title"
        name="title"
        maxLength="256"
        value={title || ''}
        onChange={(e) => setTitle(e.target.value)}
      />
    </Fragment>
  )
}

export default CRUDTitle