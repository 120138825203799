import React from 'react'

const CRUDCreate = (props) => {
  const {form: Form, onSubmit, titleForSubmit, error, items} = props

  return <Form
    onSubmit={onSubmit}
    error={error}
    titleForSubmit={titleForSubmit}
    items={items}
  />
}

export default CRUDCreate
