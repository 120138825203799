import React from 'react'
import CRUDable from './../UI/CRUDable'
import Form from './form'
import ScoreboardsIcon from './../../assets/img/nav-icons/scoreboards.svg'
import './style.scss'

const Scoreboards = (props) => {
  return (
    <CRUDable
      title="ScorbitVision - My Scoreboards"
      className="Scoreboards"
      resource="scoreboard"
      form={Form}
      icon={ScoreboardsIcon}
      {...props}
    />
  )
}

export default Scoreboards
