import {doRequest} from './../../../utils/xhr'

const doMachinesRequest = async () => {
  let machines = await doRequest(`/api/machines/?limit=5000&offset=0&groups=true`, 'GET')
    .then(response => response.results)

  let machineGroups = await doRequest(`/api/machinegroups/?limit=5000`, 'GET')
      .then(response => response.results)

  const machinesInGroups = []

  const machineGroupsAsMachines = machineGroups.map(group => {
    group.machines.forEach(m => machinesInGroups.push(m.id))
    const machine = group["machines"][0]
    machine["is_group"] = true
    machine["name"] = group["name"]
    machine["id"] = `g-${group["id"]}`
    return machine
  })

  machines = machines.filter(machine => !machinesInGroups.includes(machine.id))
   
  return [...machines, ...machineGroupsAsMachines]
}

const doMachinesSearchRequest = async (searchTerm) => {
  let machines = await doRequest(`/api/machines/?search=${searchTerm}&limit=5000&offset=0&groups=true`, 'GET')
    .then(response => response.results)

  let machineGroups = await doRequest(`/api/machinegroups/?search=${searchTerm}&limit=5000`, 'GET')
      .then(response => response.results)

  const machinesInGroups = []

  const machineGroupsAsMachines = machineGroups.map(group => {
    group.machines.forEach(m => machinesInGroups.push(m.id))
    const machine = group["machines"][0]
    machine["is_group"] = true
    machine["name"] = group["name"]
    machine["id"] = `g-${group["id"]}`
    return machine
  })

  machines = machines.filter(machine => !machinesInGroups.includes(machine.id))
   
  return [...machines, ...machineGroupsAsMachines]
}

const doMachinesRequestById = async (id) => {
  let machine = await doRequest(`/api/machines/${id}`, 'GET')
    .then(response => response)
  return machine;
}

export {doMachinesRequest, doMachinesSearchRequest, doMachinesRequestById}