import React, {useState, useContext, useEffect, useCallback} from 'react'
import AppContext from './../../contexts/app'
import {getResourcesRequest} from './http'
import CRUDFormButtons from './../UI/CRUDFormButtons'
import CRUDTitle from './../UI/CRUDTitle'
import CRUDPublicPrivate from './../UI/CRUDPublicPrivate'
import Loading from './../UI/Loading'
import TimeOptions from './../Leaderboards/timeOptions'

const WizardForm = (props) => {
  const {userVenueMachines, profile, scrollToTop} = useContext(AppContext)
  const [action, setAction] = useState(null)
  const [validationError, setValidationError] = useState(null)
  const [title, setTitle] = useState(null)
  const [isPublic, setIsPublic] = useState(false)
  const [prevTitle, setPrevTitle] = useState(null)
  const [busy, setBusy] = useState(false)
  const [vmCheckboxStates, setVMCheckboxStates] = useState(new Array(userVenueMachines.length).fill(false))

  // Scoreboard config items
  const [idleLeaderboardSec, setIdleLeaderboardSec] = useState(10)
  const [idleScoreboardSec, setIdleScoreboardSec] = useState(10)
  const [enablePlayerRank, setEnablePlayerRank] = useState(false)
  const [scoreboardBackgroundColor, setScoreboardBackgroundColor] = useState('#000000')

  // Leaderboard config items
  const useWhiteLists = true
  const [type, setType] = useState("venuemachine")
  const [perPlayer, setPerPlayer] = useState(false)
  const [timeType, setTimeType] = useState(null)
  const [timeBoxStart, setTimeBoxStart] = useState(null)
  const [timeBoxEnd, setTimeBoxEnd] = useState(null)
  const [lastCalendarOption, setLastCalendarOption] = useState(null)
  const [timeZone, setTimeZone] = useState(null)
  const [lastTimeCount, setLastTimeCount] = useState(null)
  const [leaderboardBackgroundColor, setLeaderboardBackgroundColor] = useState('#000000')

  const [loading, setLoading] = useState(true)
  const [highDensity, setHighDensity] = useState(false);

  const mapPropsToState = useCallback(() => {
    const {item} = props
    setAction(item ? 'update' : 'create')
    if (!item) return
    setTitle(item["title"])
    setPrevTitle(item["title"])
    setIsPublic(item["is_public"])
    setHighDensity(item["high_density"])
  }, [props])
  useEffect(mapPropsToState, [props.item])

  const getResources = useCallback(() => {
    getResourcesRequest(profile.id)
    .then(resources => {
      setLoading(false)
    })
  }, [profile.id])
  useEffect(getResources, [getResources])

  const validate = () => {
    const titles = props.items.map(item => item.title)
    const isDupe = titles.filter(item => item === title).length ? true : false
    let validationError = ''

    // validate title not blank
    if (!title || !title.length) {
      validationError = 'Title must not be blank'
    }

    // validate unique title when action is create
    else if (action === 'create' && isDupe) {
      validationError = 'Title must be unique'
    }

    // validate unique title when action is update
    else if (action === 'update' && isDupe && title !== prevTitle) {
      validationError = 'Title must be unique'
    }

    // validate at least two venuemachine
    else if (vmCheckboxStates.filter((b) => b).length < 2) {
      validationError = 'A comboboard must have at least two items.'
    }

    // validate time settings
    if (timeType === "time_box" && timeBoxStart === null) {
      validationError = 'Please select a start date'
    }

    // show error or proceed
    if (validationError.length) {
      setValidationError(validationError)
      return false
    } else {
      setValidationError(null)
      return true
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    setBusy(true)
    if (validate()) {
      const vms = userVenueMachines.filter((item, i) => vmCheckboxStates[i])

      const comboboardWizard = {
        "title": title,
        "is_public": isPublic,
        "high_density": highDensity,
        "vms": vms,
        "scoreboard_bg": scoreboardBackgroundColor,
        "show_live_player_rank": enablePlayerRank,
        "idle_display_scoreboard": idleScoreboardSec,
        "idle_display_leaderboard": idleLeaderboardSec,
        "one_score_per_player": perPlayer,
        "leaderboard_time_type": timeType,
        "leaderboard_bg": leaderboardBackgroundColor,
        "leaderboard_type": type
      }

      if (timeType === "time_box") {
        comboboardWizard["score_start_time"] = timeBoxStart
        comboboardWizard["score_end_time"] = timeBoxEnd
      }

      if (timeType === "last_calendar") {
        comboboardWizard["last_time_bucket"] = lastCalendarOption
        comboboardWizard["last_time_timezone"] = timeZone
        comboboardWizard["last_time_count"] = 1
      }

      if (timeType === "last_rolling") {
        comboboardWizard["last_time_bucket"] = "day"
        comboboardWizard["last_time_count"] = lastTimeCount
      }

      if (type === "machine") {
        comboboardWizard["leaderboard_machine_type"] = "selected"
      }

      props.onSubmit(comboboardWizard)
    } else {
      setBusy(false)
      scrollToTop()
    }
  }

  const onMachineCheckboxChange = (position) => {
      setVMCheckboxStates(vmCheckboxStates.map((item, index) => index === position ? !item : item))
  }

  const onMachineSelectAll = () => {
      setVMCheckboxStates(vmCheckboxStates.every((s) => s === true) ? new Array(userVenueMachines.length).fill(false) : new Array(userVenueMachines.length).fill(true))
  }

  const renderMachineCheckbox = (item, index) =>
    <div className="flexRow" key={`machine-row-${index}`}>
      <label htmlFor={`machine-checkbox-${index}`}>
        <input type="checkbox" id={`machine-checkbox-${index}`} name="machine" value={item.id} checked={vmCheckboxStates[index]} onChange={() => onMachineCheckboxChange(index)} />
        {`${item.machine.name} at ${item.venue.name}`}
      </label>
    </div>

  const renderForm = () =>
    <form
      onSubmit={onSubmit}
      className="has-required-fields"
    >
      {validationError &&
        <div className="formError">{validationError}</div>
      }
      <div className="formRow">
        <CRUDTitle
          title={title}
          setTitle={setTitle}
        />
      </div>
      <div className="formRow">
        <CRUDPublicPrivate
          isPublic={isPublic}
          setIsPublic={setIsPublic}
        />
      </div>
      <div className="formRow">
        <label className="primary">
          <span>High Density</span>
        </label>

        <div className="flexRow">
          <label htmlFor="enableHighDensity">
            <input
              id="enableHighDensity"
              type="radio"
              name="highDensity"
              checked={highDensity}
              onChange={() => setHighDensity(true)}
            />
            Enable
          </label>
          <label htmlFor="disableHighDensity">
            <input
              id="disableHighDensity"
              type="radio"
              name="highDensity"
              checked={!highDensity}
              onChange={() => setHighDensity(false)}
            />
            Disable
          </label>
        </div>
      </div>

      <div className="formRow">
        <label className="primary">Machines</label>
        <div className="flexRow" key="machines-select-all">
          <label htmlFor={`machine-checkbox-select-all`}>
            <input type="checkbox" id={`machine-checkbox-select-all`} checked={vmCheckboxStates.every((s) => s === true)} onChange={() => onMachineSelectAll()} />
            Select All
          </label>
        </div>
        {userVenueMachines.map(renderMachineCheckbox)}
      </div>

      <div className="formRow">
        <label className="primary">Scoreboard Options</label>
      </div>

      <div className="formRow">
        <label className="primary">
          <span>Background Color</span>
        </label>
        <input
          className="colorPicker"
          type="color"
          value={scoreboardBackgroundColor}
          onChange={(event) => {
            setScoreboardBackgroundColor(event.target.value);
          }}
        />
      </div>
      <div className="formRow">
        <label className="primary">
          <span>Show Live Player Rank</span>
        </label>
      </div>

      <div className="flexRow">
        <label htmlFor="enablePlayerRank">
          <input
            id="enablePlayerRank"
            type="radio"
            name="enablePlayerRank"
            checked={enablePlayerRank}
            onChange={() => setEnablePlayerRank(true)}
          />
          Enable
        </label>
        <label htmlFor="disablePlayerRank">
          <input
            id="disablePlayerRank"
            type="radio"
            name="enablePlayerRank"
            checked={!enablePlayerRank}
            onChange={() => setEnablePlayerRank(false)}
          />
          Disable
        </label>
      </div>
      <div className="formRow">
        <label className="primary">
          <span>During Idle Display Scoreboard (in seconds)</span>
        </label>
        <input
          className="idleScoreboardSec"
          type="text"
          value={idleScoreboardSec}
          onChange={(event) => {
            setIdleScoreboardSec(event.target.value);
          }}
        />
      </div>
      <div className="formRow">
        <label className="primary">
          <span>During Idle Display Leaderboard (in seconds)</span>
        </label>
        <input
          className="idleLeaderboardSec"
          type="text"
          value={idleLeaderboardSec}
          onChange={(event) => {
            setIdleLeaderboardSec(event.target.value);
          }}
        />
      </div>

      <div className="formRow">
        <label className="primary">Leaderboard Options</label>
      </div>

      {useWhiteLists &&
        <TimeOptions
          timeType={timeType}
          setTimeType={setTimeType}
          timeBoxStart={timeBoxStart}
          setTimeBoxStart={setTimeBoxStart}
          timeBoxEnd={timeBoxEnd}
          setTimeBoxEnd={setTimeBoxEnd}
          lastCalendarOption={lastCalendarOption}
          setLastCalendarOption={setLastCalendarOption}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
          lastTimeCount={lastTimeCount}
          setLastTimeCount={setLastTimeCount}
        />
      }
      <div className="formRow">
        <label className="primary">Scores per player</label>
        <div className="flexRow">
          <label htmlFor="perPlayerAll">
            <input
              id="perPlayerAll"
              type="radio"
              name="perPlayer"
              checked={perPlayer === false}
              onChange={() => setPerPlayer(false)}
            />
            All Scores
          </label>
          <label htmlFor="perPlayerOne">
            <input
              id="perPlayerOne"
              type="radio"
              name="perPlayer"
              checked={perPlayer === true}
              onChange={() => setPerPlayer(true)}
            />
            One Score Per Player
          </label>
        </div>
      </div>
      <div className="formRow">
        <label className="primary">Type</label>
        <div className="flexRow">
          <label htmlFor="venuemachine">
            <input
              id="venuemachine"
              type="radio"
              name="type"
              checked={type === "venuemachine"}
              onChange={() => setType("venuemachine")}
            />
            Single Machine
          </label>
          <label htmlFor="machine">
            <input
              id="machine"
              type="radio"
              name="type"
              checked={type === "machine"}
              onChange={() => setType("machine")}
            />
            Multiple machines of same title
          </label>
        </div>
      </div>
      <div className="formRow">
        <label className="primary">
          <span>Background Color</span>
        </label>
        <input
          className="colorPicker"
          type="color"
          value={leaderboardBackgroundColor}
          onChange={(event) => {
            setLeaderboardBackgroundColor(event.target.value);
          }}
        />
      </div>

      <CRUDFormButtons
        busy={busy}
        disableSubmit={busy}
        titleForSubmit={props.titleForSubmit}
        cancelHref="/comboboards"
      />
    </form>

  return loading
    ? <Loading />
    : renderForm()
}

export default WizardForm
