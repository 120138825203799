import React, { Fragment, useEffect, useState, useRef } from 'react';
import LeaderboardInfo from './leaderboardInfo';
import ScoreboardStatus from './scoreboardStatus';
import DigitCounter from './../../UI/DigitCounter';
import shuffle from 'lodash/shuffle';
import throttle from 'lodash/throttle';
import FlipMove from 'react-flip-move';
import {
  getBootstrapRequest,
  getLeaderboardScoresRequest,
  getVenuemachineHotRequest,
  getVenuemachineCurrentByIdRequest,
  getVenuemachineByIdRequest
} from './http';
import HotgameType from './hotgameType';

const Board = ({ config, type, data, highDensity, viewerType, curHotGame, setCurHotGame }) => {
  const [state, setState] = useState({ currentData: { config, type, data } });
  const gameover = useRef(false);
  const showHotgame = useRef(false);
  const poller = useRef(null);
  const isLeaderboardScheduled = useRef(false);
  const [hotgameNotFound, setHotgameNotFound] = useState(false);

  const showGif = () => {
    if (gameover.current) {
      setHotgameNotFound(false);
      setState({
        ...state,
        currentData: state.scoreboardData
      })

      setTimeout(() => {
        if (gameover.current) {
          setHotgameNotFound(true);
          setTimeout(() => {
            if (gameover.current)
              getVenuemachineHotList();
          }, 20000);  //show gif 20 seconds
        }
      }, 10000);  //show idled scoreboard 20 seconds
    }
  }

  const getVenuemachineHotList = () => {
    getVenuemachineHotRequest()
      .then((venuemachineHotList) => {
        if (venuemachineHotList && venuemachineHotList['venuemachine'] && venuemachineHotList['venuemachine'][0]) {
          getVenuemachine(0, venuemachineHotList);
        } else {
          showGif();
        }
      })
      .catch((error) => {
        setHotgameNotFound(true);
        console.error(error);
      })
  };

  const getVenuemachine = (index, venuemachineHotList) => {
    const venuemachineId = venuemachineHotList['venuemachine'][index];
    getVenuemachineByIdRequest(venuemachineId)
      .then((venuemachineData) => {
        if (venuemachineData.error) {
          if ((index + 1) === venuemachineHotList['venuemachine'].length) {
            showGif();
          } else {
            getVenuemachine(index + 1, venuemachineHotList);
          }
          return;
        }

        if (!curHotGame.includes(venuemachineId)) {
          curHotGame.push(venuemachineId);
          setCurHotGame(curHotGame)
        } else {
          if ((index + 1) === venuemachineHotList['venuemachine'].length) {
            showGif();
          } else {
            getVenuemachine(index + 1, venuemachineHotList);
          }
          return;
        }

        getHotGameScores(index, venuemachineData, venuemachineHotList);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const removeVenuemachineId = (venuemachineId) => {
    for ( var i = 0; i < curHotGame.length; i++) { 
      if ( curHotGame[i] === venuemachineId) { 
        curHotGame.splice(i, 1);
        setCurHotGame(curHotGame);
        return;
      }
    }
  }

  const getHotGameScores = (index, venuemachineData, venuemachineHotList) => {
    if (index === venuemachineHotList['venuemachine'].length) {
      return;
    }
    const venuemachineId = venuemachineHotList['venuemachine'][index];
    if (poller.current === null) {
      poller.current = setInterval(() => {
        getVenuemachineCurrentByIdRequest(venuemachineId)
          .then((venuemachineCurrentData) => {

            if (venuemachineCurrentData.error) {
              clearInterval(poller.current);
              poller.current = null;
              removeVenuemachineId(venuemachineId);
              showGif();
              return;
            }

            if (venuemachineCurrentData['settled_on']) {
              clearInterval(poller.current);
              poller.current = null;
              removeVenuemachineId(venuemachineId);
              if ((index + 1) === venuemachineHotList['venuemachine'].length) {
                showGif();
              } else {
                getVenuemachine(index + 1, venuemachineHotList)
              }
              return;
            }

            const newData = [{
              venuemachine: {
                machine: venuemachineData.machine,
                venue: venuemachineData.venue
              }
            }]

            setState({
              ...state,
              currentData: {
                ...state.currentData,
                config: {
                  ...state.currentData.config,
                  display_title: false,
                  venuemachine_items: newData
                },
                data: {
                  type: 'scoreboard',
                  session: venuemachineCurrentData
                }
              }
            })

            setHotgameNotFound(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }, 5000); //get hotgame scoredata every 5 seconds
    }
  };

  useEffect(() => {
    if (config && type && data) {
      if (data && data['session'] && data['session']['settled_on']) {
        gameover.current = true;
        if (config['idle_hotgame_show'])
          showHotgame.current = true;
      } else {        
        gameover.current = false;
        if (config['idle_hotgame_show']) {
          showHotgame.current = false;
          if (poller.current) {
            clearInterval(poller.current);
            poller.current = null;
          }
        }
      }
      setState({
        ...state,
        currentData: { config, type, data },
        scoreboardData: { config, type, data },
      });
    }
  }, [data]);

  

  useEffect(() => {
    if (type === 'scoreboard' && config && config['leaderboard'] && config['idle_leaderboard_show'] && data && !state.leaderboardData) {
      getBootstrapRequest('leaderboard', config['leaderboard'])
        .then((leaderboardConfig) => {
          if (!leaderboardConfig.error) {
            getLeaderboardScoresRequest(config['leaderboard'])
              .then((response) => {
                setState({
                  currentData: { config, type, data },
                  scoreboardData: { config, type, data },
                  leaderboardData: {
                    config: leaderboardConfig,
                    type: 'leaderboard',
                    data: response.data[0].scores
                  },
                });
              })
              .catch((error) => {
                console.error(error);
              });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [config, data]);

  
  useEffect(() => {
    if (type === 'scoreboard' && config && config['idle_hotgame_show'] && showHotgame.current) {
      setTimeout(() => {
        setState({
          ...state,
          currentData: state.scoreboardData,
        });
        getVenuemachineHotList();
      }, 10000);
    } else if (type === 'scoreboard' && config && config['idle_hotgame_show'] && !showHotgame.current) {
      setHotgameNotFound(false);
    }
  }, [showHotgame.current]);

  useEffect(() => {
    if (!state.scoreboardData) {
      return;
    }

    if (!state.currentData) {
      return;
    }

    if (!state.currentData.data) {
      setState({
        ...state,
        currentData: state.scoreboardData
      })
      return;
    }

    if (!state.leaderboardData) {
      return;
    }

    if (!state.scoreboardData.data) {
      return;
    }

    if (state.scoreboardData.data['session'] && state.currentData.type === 'scoreboard') {
      if (state.scoreboardData.data['session']['settled_on']) {
        if (isLeaderboardScheduled.current === false) {
          isLeaderboardScheduled.current = true;
          setTimeout(() => {
            if (gameover.current === true) {
              setState({
                ...state,
                currentData: state.leaderboardData
              })
            }
          }, config['idle_scoreboard_sec'] * 1000);
        }
      }
    } else if (gameover.current === true && state.currentData.type === 'leaderboard') {
      if (isLeaderboardScheduled.current === true) {
        isLeaderboardScheduled.current = false;
        setTimeout(() => {
          if (gameover.current === true) {
            setState({
              ...state,
              currentData: state.scoreboardData
            })
          }
        }, config['idle_leaderboard_sec'] * 1000);
      }
    }
  }, [state]);
  return (
    !hotgameNotFound ? <div className="ComboBoard-Board">
      {state && state.currentData && state.currentData.type === 'scoreboard' && (
        <Fragment>
          <ScoreboardHeader config={state.currentData.config} />
          <ScoreboardContent config={state.currentData.config} data={state.currentData.data} />
        </Fragment>
      )}
      {state && state.currentData && state.currentData.type === 'leaderboard' && (
        <Fragment>
          <LeaderboardHeader config={state.currentData.config} />
          <LeaderboardContent config={state.currentData.config} data={state.currentData.data} highDensity={highDensity}/>
        </Fragment>
      )}
    </div> : <Fragment><div className="ComboBoard-Board"><HotgameType type={config['idle_hotgame_type']} viewerType={viewerType}/></div></Fragment>
  );
};

const BoardNoData = () => (
  <div className="ComboBoard-Board-NoData">Waiting for data...</div>
);

const BoardHeader = ({ children }) => (
  <div className="ComboBoard-Board-Header">{children}</div>
);

const BoardTitle = ({ children }) => (
  <div className="ComboBoard-Board-Header-MachineTitle">{children}</div>
);
const MachineTitle = ({ children }) => (
  <div className="ComboBoard-Board-Header-Title">{children}</div>
);

const BoardMeta = ({ children }) => (
  <div className="ComboBoard-Board-Header-Meta">{children}</div>
);

const BoardContent = ({ type, backGlass, children }) => (
  <div
    className={`ComboBoard-Board-Content ComboBoard-Board-Content--${type}`}
    style={{ backgroundImage: `url(${backGlass})` }}
  >
    <div className="ComboBoard-Board-Content-Data">{children}</div>
  </div>
);

const ScoreboardHeader = ({ config }) => {
  const boardTitle = config['title'];
  const machine = config['venuemachine_items'][0]['venuemachine']['machine'];
  const venue = config['venuemachine_items'][0]['venuemachine']['venue'];
  return (
    <BoardHeader>
      {config['display_title'] && <BoardTitle>{boardTitle}</BoardTitle>}
      <MachineTitle>{machine.name}</MachineTitle>
      <BoardMeta>
        <span className="material-icons">location_on</span>
        {venue.name}
      </BoardMeta>
    </BoardHeader>
  );
};

const ScoreboardContent = ({ config, data, error }) => {
  const machine = config['venuemachine_items'][0]['venuemachine']['machine'];
  const machineName = machine && machine.name ? machine.name : 'Unknown';
  if (!data) {
    return (
      <BoardContent type="scoreboard" backGlass={machine['backglass_art']}>
        <BoardNoData />
      </BoardContent>
    );
  }
  const { session } = data || {};
  let scores = [];
  if (session && session.scores) {
    scores = session.scores;
    if (config['enablePlayerRank']) {
      scores.sort((a, b) => (a.position < b.position ? 1 : -1));
      scores.sort((a, b) => (a.score < b.score ? 1 : -1));
    } else {
      scores.sort((a, b) => (a.position > b.position ? 1 : -1));
    }
  }
  const lastPlayerScore = scores ? Math.max(...scores.map(score => score.score)) : null;
  const getPlayerName = (score) => {
    const { player } = score;
    let playerName = `Player ${score.position}`;

    if (player && player['cached_display_name']) {
      playerName = player['cached_display_name'];
    }

    return playerName;
  };

  return (
    <BoardContent type="scoreboard" backGlass={machine['backglass_art']}>
      {session && (
        <Fragment>
          <div className="ComboBoard-Board-Content-Row ComboBoard-Board-Content-Row--status">
            <div className="ComboBoard-Status">
              {session['settled_on'] ? (
                <div className="ComboBoard-Status-Mode ComboBoard-Status-Mode--red ComboBoard-Status-Mode--game-over">
                {machineName !== "Total Nuclear Annihilation" ? 'GAME OVER' :
                  (lastPlayerScore % 10 === 9 ? 'YOU WIN' : 'YOU FAILED')}
              </div>
              ) : (
                <Fragment>
                  <ScoreboardStatus modes={session['game_modes']} machineName={machineName} lastPlayerScore={lastPlayerScore} />
                  <div className="ComboBoard-Status-Ball">
                    <span>BALL</span>
                    <span className="ComboBoard-Status-Ball-Shape">
                      {session.current_ball}
                    </span>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <div className="ComboBoard-Board-Content-LiveScores">
            <FlipMove
              staggerDurationBy="30"
              duration={500}
              style={{height: "100%"}}
            >
              {scores.map((item, i) => {
                return (
                  <div
                    key={item.position}
                    className={`ComboBoard-Board-Content-Row ComboBoard-Board-Content-Row--${
                      scores.length
                    }Players ComboBoard-Board-Content-Row--scoreboard ${
                      !session['settled_on'] &&
                      session['current_player'] === item.position
                        ? 'ComboBoard-Board-Content-Row--CurrentPlayer'
                        : ''
                    }`}
                  >
                    <div className={`ComboBoard-Score`}>
                      <div className="ComboBoard-Score-Player">
                        {getPlayerName(item)}
                      </div>
                      <div className="ComboBoard-Score-Value">
                        {item.score !== null ? (
                          <DigitCounter score={item.score} />
                        ) : (
                          <span className="noScore">--</span>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </FlipMove>
          </div>
        </Fragment>
      )}
    </BoardContent>
  );
};

const LeaderboardHeader = ({ config }) => {
  let name;
  const item = config['items'][0];
  const boardTitle = config['title'];
  const { type } = item;

  if (type === 'venuemachine') {
    name = item.venuemachine.machine.name;
  } else if (type === 'machine') {
    name = item.machine.name;
  } else if (type === 'machinegroup') {
    name = item.machinegroup.name;
  }

  return (
    <BoardHeader>
      {config['display_title'] && <BoardTitle>{boardTitle}</BoardTitle>}
      <MachineTitle>{name}</MachineTitle>
      <BoardMeta>
        <LeaderboardInfo leaderboard={config} />
      </BoardMeta>
    </BoardHeader>
  );
};

const LeaderboardContent = ({ config, data, highDensity }) => {
  let backGlass;
  let filteredData = data;
  const item = config['items'][0];
  const { type } = item;
  if (type === 'venuemachine') {
    backGlass = item.venuemachine.machine['backglass_art'];
  } else if (type === 'machine') {
    backGlass = item.machine['backglass_art'];
  } else if (type === 'machinegroup') {
    backGlass = item.machinegroup['backglass_art'];
  }
  if (highDensity && filteredData) {
    filteredData = filteredData.filter((item, i) => i < 5);
  }
  return (
    <BoardContent type="leaderboard" backGlass={backGlass}>
      {filteredData &&
        filteredData.map((item, i) => (
          <div
            key={i}
            className={`ComboBoard-Board-Content-Row ComboBoard-Board-Content-Row--leaderboard${highDensity?'-highDensity':''}`}
          >
            <div className="ComboBoard-Score">
              <div className="ComboBoard-Score-Position">{i + 1}</div>
              <div className="ComboBoard-Score-Player">
                {item.player
                  ? item.player['cached_display_name']
                  : `Player ${i + 1}`}
              </div>
              <div className="ComboBoard-Score-Value">
                {item.score && item.score.toLocaleString()}
              </div>
            </div>
          </div>
        ))}
    </BoardContent>
  );
};

export default Board;
