import React, {useState, useCallback, useEffect} from 'react'
import hash from 'object-hash'
import Loading from './../../UI/Loading'
import Branding from './../Branding'
import { getBootstrapRequest } from './http'
import { getSlideShowImageRequest } from '../../Slideshows/http'

const SlideshowViewer = (props) => {
  const {id} = props
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [slideshow, setSlideshow] = useState(null)
  const [currentItemIndex, setCurrentItemIndex] = useState(null)
  const [configHash, setConfigHash] = useState(null)

  const getImageMeta = async (url) => new Promise((resolve) => {
    var img = new Image();
    img.src = url;
    img.onload = function () {
      resolve({
        width: this.width,
        height: this.height
      });
    }
  });

  const getConfig = useCallback(() => {
    getBootstrapRequest(id)
    .then(data => {
      if (data.error) {
        setError(data.error)
      } else {
        async function fetchData() {
          for (var screen of data.items) {     
            if (screen["item_type"] === "slideshowimage") {
              // eslint-disable-next-line no-loop-func
              await getSlideShowImageRequest(screen["item_id"]).then(res => {
                screen["imageSrc"] = res.image;
              })
              
              // eslint-disable-next-line no-loop-func
              await getImageMeta(screen["imageSrc"]).then(res => {
                screen['imageWidth'] = res.width;
                screen['imageHeight'] = res.height;
              });
            }
          }
          setSlideshow(data)
          setCurrentItemIndex(0)
        }
        fetchData()
        setLoading(false)
        setError(null)
      }
    })
  }, [id])

  useEffect(getConfig, [getConfig])

  useEffect(() => {
    if (loading) return
    if (!slideshow) return
    const { items } = slideshow
    setTimeout(() => {
      const nextCurrentItemIndex = currentItemIndex < items.length - 1
        ? currentItemIndex + 1
        : 0
      setCurrentItemIndex(nextCurrentItemIndex)
    }, items[currentItemIndex].duration * 1000)
  }, [currentItemIndex, loading]) // eslint-disable-line

  useEffect(() => {
    if (!slideshow) return
    const prevConfigHash = configHash
    const hashed = hash(slideshow)
    if (configHash && prevConfigHash !== hashed) {
      return window.location.reload()
    }
    setConfigHash(hashed)
  }, [slideshow, configHash])
  
  const renderScreen = (screen, i) => {
    const itemType = screen["item_type"]
    const itemId = screen["item_id"]
    const imageSrc = screen["imageSrc"]
    if (itemType === "slideshowimage") {
      const imageWidth = screen['imageWidth'];
      const imageHeight = screen['imageHeight'];
      let style = (imageWidth > imageHeight) ? { width: "100%" } : { height: "100%" };
      if (imageWidth !== 0 && imageHeight !== 0) {
        if ((window.innerWidth / imageWidth) > (window.innerHeight / imageHeight)) {
          style = { height: "100%" };
        } else {
          style = { width: "100%" };
        }
      }
      return <div key={i} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className={`Viewer-Iframe ${i !== currentItemIndex ? 'Viewer-Iframe-hidden' : ''}`}>
        <img src={imageSrc} alt="background" style={style}/>
      </div>
    } else {
      return <iframe
        title={`slideshow-item-${i}`}
        className={`Viewer-Iframe ${i !== currentItemIndex ? 'Viewer-Iframe-hidden' : ''}`}
        key={i}
        src = {`${document.location.origin}/viewer/${itemType}s/${itemId}?slideshow=true`}
      />      
    }
  }

  const renderSlideshow = () =>
    slideshow.items.map(renderScreen)

  const render = () => {
    if (error) {
      return <div className="Viewer-Error">
        {error}
      </div>
    }

    return loading
      ? <div className="Viewer-Resource">
          <Loading />
        </div>
      : <div className="Viewer">
          {slideshow && renderSlideshow()}
        </div>
  }

  return render()
}

export default SlideshowViewer