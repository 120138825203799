import React, {useContext, useEffect} from 'react'
import AppContext from './../../../contexts/app'
import './style.scss'

const Message = (props) => {
  const {content} = props
  const {clearMessage} = useContext(AppContext)
  const className = content.match(/error/) ? 'error' : 'success'


  useEffect(() => {
    return () => {
      clearMessage()
    }
  }, [clearMessage])

  return (
    <div className={`Message ${className}`}>
      {content}
      <div
        className="Message-Dismiss"
        onClick={clearMessage}
      >
        ×
      </div>
    </div>
  )
}

export default Message
