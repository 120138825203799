import React, {useState, useRef, useEffect} from 'react'
import {getVenuemachinesRequest} from './http'
import {sortById, sortByMachine, sortByVenue, sortByUser, sortBySerial,
  sortByDetector, sortByBitstream, filterMachines} from './utils'
import PageHeader from './../UI/PageHeader'
import Loading from './../UI/Loading'
import './style.scss'

const columns = [
  {key: 'machine', label: 'Machine', sortFunction: sortByMachine},
  {key: 'venue', label: 'Venue', sortFunction: sortByVenue},
  {key: 'user', label: 'User', sortFunction: sortByUser},
  {key: 'id', label: 'ID', sortFunction: sortById},
  {key: 'serial', label: 'Serial', sortFunction: sortBySerial},
  {key: 'detector', label: 'Detector', sortFunction: sortByDetector},
  {key: 'bitstream', label: 'Bitstream', sortFunction: sortByBitstream},
]

const Venuemachines = (props) => {
  const [venuemachines, setVenuemachines] = useState(null)
  const [sortOrder, setSortOrder] = useState('ascending')
  const [sortKey, setSortKey] = useState('machine')
  const [filterKey, setFilterKey] = useState('machine')
  const [filterValue, setFilterValue] = useState('')
  const [filtered, setFiltered] = useState([])
  const [loading, setLoading] = useState(true)
  const tableRef = useRef(null)

  // effect:
  // load data and do inital sort
  useEffect(() => {
    getVenuemachinesRequest()
      .then(data => {
        const sorted = sortByMachine(data, 'ascending')
        setVenuemachines(sorted)
        setFiltered(sorted)
        setLoading(false)
      })
  }, [])

  // effect:
  // filter data when applicable
  useEffect(() => {
    const filtered = filterMachines(venuemachines, filterKey, filterValue)
    setFiltered(filtered)
  }, [venuemachines, filterKey, filterValue, sortOrder, sortKey])

  const getSortOrder = (key) => {
    if (key !== sortKey) {
      return 'descending'
    } else {
      return sortOrder === 'ascending' ? 'descending' : 'ascending'
    }
  }

  const handleSort = (sortKey, sortFunction) => {
    const newSortOrder = getSortOrder(sortKey)
    setVenuemachines(sortFunction(venuemachines, newSortOrder))
    setSortOrder(newSortOrder)
    setSortKey(sortKey)
    tableRef.current.scrollTop = 0
  }

  const handleFilterValueChange = (event) => {
    setFilterValue(event.target.value)
  }

  const handleFilterKeyChange = (event) => {
    const prevFilterKey = filterKey
    const nextFilterKey = event.target.value
    setFilterKey(nextFilterKey)
    if (nextFilterKey !== prevFilterKey) {
      setFilterValue('')
    }
  }

  const getRowData = ({id, machine, venue, user, scorbitron}) => [
    {
      key: 'machine',
      value: machine.name,
    },
    {
      key: 'venue',
      value: venue.name,
    },
    {
      key: 'user',
      value: user && user.username ? user.username : renderNull(),
    },
    {
      key: 'id',
      value: id,
    },
    {
      key: 'serial',
      value: scorbitron ? scorbitron.serial_no : renderNull(),
    },
    {
      key: 'detector',
      value: scorbitron ? scorbitron.score_detector_version : renderNull(),
    },
    {
      key: 'bitstream',
      value: scorbitron ? scorbitron.bitstream_version : renderNull(),
    }
  ]

  const renderFilter = () =>
    <form
      className="Machines-List-Filter"
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        className="Machines-List-Filter-Value"
        type="text"
        value={filterValue}
        onChange={handleFilterValueChange}
        placeholder="Filter..."
      />
      {columns.map(col => col.key).map(key =>
        <label key={key} htmlFor={`filter-${key}`}>
          <input
            id={`filter-${key}`}
            name="filterKey"
            value={key}
            type="radio"
            checked={key === filterKey}
            onChange={handleFilterKeyChange} />
          {key}
        </label>
      )}
    </form>

  const renderEmpty = () =>
    <p>There are no venuemachines to display</p>

  const renderMachinesCount = () =>
    <div className="Machines-List-Count">
      <strong>{filtered.length}</strong> of {venuemachines.length} machines
    </div>

  const renderColumnHead = ({label, key, sortFunction}) =>
    <th
      key={key}
      className={`${key} ${sortKey === key ? `sorted ${sortOrder}` : ''}`}
      onClick={() => handleSort(key, sortFunction)}
    >
      {label}
    </th>

  const renderRow = (venuemachine) =>
    <tr key={venuemachine.id}>
      {getRowData(venuemachine).map(renderColumnCell)}
    </tr>

  const renderColumnCell = ({key, value}) =>
    <td className={key} key={key}>
      {value ? value : renderNull()}
    </td>

  const renderNull = () =>
    <span className="null">--</span>

  const renderMachines = () =>
    <div className="Machines-List">
      {renderFilter()}
      {renderMachinesCount()}
      <div className="Machines-List-Items" ref={tableRef}>
        <table>
          <thead>
            <tr>
              {columns.map(renderColumnHead)}
            </tr>
          </thead>
          <tbody>
            {filtered.map(renderRow)}
          </tbody>
        </table>
      </div>
    </div>

  return <div className="Venuemachines">
    <PageHeader>Machines</PageHeader>
    {loading ? <Loading /> : null}
    {!loading && !venuemachines.length ? renderEmpty() : null}
    {!loading && venuemachines.length ? renderMachines() : null}
  </div>
}

export default Venuemachines
