import _ from 'lodash'

const sortById = (venuemachines, sortOrder) => {
  const compare = (a, b) => {
    let comparator = a.id > b.id ? 1 : -1
    comparator = sortOrder === 'descending' ? comparator * -1 : comparator
    return comparator
  }
  return venuemachines.sort(compare)
}

const sortByMachine = (venuemachines, sortOrder) => {
  const compare = (a, b) => {
    let comparator = a.machine.name.toLowerCase() > b.machine.name.toLowerCase() ? 1 : -1
    comparator = sortOrder === 'descending' ? comparator * -1 : comparator
    return comparator
  }
  return venuemachines.sort(compare)
}

const sortByVenue = (venuemachines, sortOrder) => {
  const compare = (a, b) => {
    let comparator = a.venue.name.toLowerCase() > b.venue.name.toLowerCase() ? 1 : -1
    comparator = sortOrder === 'descending' ? comparator * -1 : comparator
    return comparator
  }
  return venuemachines.sort(compare)
}

const sortByUser = (venuemachines, sortOrder) => {
  const membersToSort = []
  const membersToIgnore = []
  venuemachines.forEach((machine) => {
    const {user} = machine
    if (user && user.username) {
      membersToSort.push(machine)
    } else {
      membersToIgnore.push(machine)
    }
  })
  const compare = (a, b) => {
    let comparator = a.user.username.toLowerCase() > b.user.username.toLowerCase() ? 1 : -1
    comparator = sortOrder === 'descending' ? comparator * -1 : comparator
    return comparator
  }
  const sorted = membersToSort.sort(compare)
  return sorted.concat(membersToIgnore)
}

const sortBySerial = (venuemachines, sortOrder) => {
  const membersToSort = []
  const membersToIgnore = []
  venuemachines.forEach((machine) => {
    const {scorbitron} = machine
    if (scorbitron && scorbitron.serial_no && scorbitron.serial_no.toString().match(/^\d/)) {
      membersToSort.push(machine)
    } else {
      membersToIgnore.push(machine)
    }
  })
  const compare = (a, b) => {
    let comparator = a.scorbitron.serial_no > b.scorbitron.serial_no ? 1 : -1
    comparator = sortOrder === 'descending' ? comparator * -1 : comparator
    return comparator
  }
  const sorted = membersToSort.sort(compare)
  return sorted.concat(membersToIgnore)
}

const sortByDetector = (venuemachines, sortOrder) => {
  const membersToSort = []
  const membersToIgnore = []
  venuemachines.forEach((machine) => {
    const {scorbitron} = machine
    if (scorbitron && scorbitron.score_detector_version && scorbitron.score_detector_version.toString().match(/^\d/)) {
      membersToSort.push(machine)
    } else {
      membersToIgnore.push(machine)
    }
  })
  const compare = (a, b) => {
    let comparator = a.scorbitron.score_detector_version > b.scorbitron.score_detector_version ? 1 : -1
    comparator = sortOrder === 'descending' ? comparator * -1 : comparator
    return comparator
  }
  const sorted = membersToSort.sort(compare)
  return sorted.concat(membersToIgnore)
}

const sortByBitstream = (venuemachines, sortOrder) => {
  const membersToSort = []
  const membersToIgnore = []
  venuemachines.forEach((machine) => {
    const {scorbitron} = machine
    if (scorbitron && scorbitron.bitstream_version && scorbitron.bitstream_version.length) {
      membersToSort.push(machine)
    } else {
      membersToIgnore.push(machine)
    }
  })
  const compare = (a, b) => {
    let comparator = a.scorbitron.bitstream_version > b.scorbitron.bitstream_version ? 1 : -1
    comparator = sortOrder === 'descending' ? comparator * -1 : comparator
    return comparator
  }
  const sorted = membersToSort.sort(compare)
  return sorted.concat(membersToIgnore)
}

const filterMachines = (venuemachines, filterKey, filterValue) => {
  if (!filterValue.length) {
    return venuemachines
  } else {
    const compare = value => value.toString().toLowerCase().match(filterValue.toLowerCase())
    let test
    if (filterKey === 'machine')
      test = venuemachine => _.get(venuemachine, 'machine.name')
        && compare(venuemachine.machine.name)
    else if (filterKey === 'venue')
      test = venuemachine => _.get(venuemachine, 'venue.name')
        && compare(venuemachine.venue.name)
    else if (filterKey === 'user')
      test = venuemachine => _.get(venuemachine, 'user')
        && compare(venuemachine.user.username)
    else if (filterKey === 'serial')
      test = venuemachine => _.get(venuemachine, 'scorbitron.serial_no')
        && compare(venuemachine.scorbitron.serial_no)
    else if (filterKey === 'detector')
      test = venuemachine => _.get(venuemachine, 'scorbitron.score_detector_version')
        && compare(venuemachine.scorbitron.score_detector_version)
    else if (filterKey === 'bitstream')
      test = venuemachine => _.get(venuemachine, 'scorbitron.bitstream_version')
        && compare(venuemachine.scorbitron.bitstream_version)
    return venuemachines.filter(test)
  }
}

export {
  sortById,
  sortByMachine,
  sortByVenue,
  sortByUser,
  sortBySerial,
  sortByDetector,
  sortByBitstream,
  filterMachines,
}
