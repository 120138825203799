import React, { useContext, useState, useEffect, useRef, useCallback } from 'react'
import AppContext from './../../contexts/app'
import Helmet from 'react-helmet'
import PageHeader from './../UI/PageHeader'
import Loading from './../UI/Loading'
import {updateProfile} from './http'
import AvatarEditor from 'react-avatar-editor'
import DefaultUserIcon from './../../assets/img/user-icon/default-user.png'
import './style.scss'

const Profile = (props) => {
  const [displayName, setDisplayName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [initials, setInitials] = useState('');
  const [ifpaId, setIFPAId] = useState('');
  const [validationError, setValidationError] = useState(null)
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageFile, setProfileImageFile] = useState(null);
  const [busy, setBusy] = useState(false);
  const { profile } = useContext(AppContext);

  const [avatarImage, setAvatarImage] = useState(null);
  const [avatarImageZoom, setAvatarImageZoom] = useState(1);
  const [editAvatarImage, setEditAvatarImage] = useState(false);

  let editor = null;
  const setEditorRef = (ed) => {
    editor = ed;
  };

  useEffect(() => {
    if (profile) {
      setFirstName(profile.first_name);
      setLastName(profile.last_name);
      setDisplayName(profile.display_name);
      if (profile.profile_picture)
        setProfileImage(profile.profile_picture);
      else
        setProfileImage(DefaultUserIcon);
      setInitials(profile.initials);
      setIFPAId(profile.ifpa_id);
    }
  }, [profile]);

  const onChangeProfile = async (event) => {
    const file = event.target.files[0]
    if (file) {
      setAvatarImage(URL.createObjectURL(file))
      setEditAvatarImage(true);
      setAvatarImageZoom(1);
    }
  }

  const onUpdateProfile = () => {
    
    if (!firstName) {
      setValidationError("First name should not be empty");
      return;
    } else if (!lastName) {
      setValidationError("Last name should not be empty");
      return;
    } else if (!initials) {
      setValidationError("Initials must less than 4 characters");
      return;
    } else if (initials.length > 3) {
      setValidationError("Initials must less than 4 characters");
      return;
    }

    setBusy(true);
    let formData = new FormData();

    if (profileImageFile) {
      formData.append('profile_picture', profileImageFile);
    }

    formData.append('display_name', displayName);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('initials', initials);
    formData.append('ifpa_id', ifpaId);

    updateProfile(formData).then(data => {
      if (data.updateProfileResult && data.updateProfileResult.id) {
        window.location.href = '';
      } else if (data.updateProfileResult.error) {
        setValidationError(data.updateProfileResult.error);
      }
      setBusy(false);
    });
  }
  const renderForm = () =>
    <form
      onSubmit={onUpdateProfile}
      className="has-required-fields"
    >
      {validationError &&
        <div className="formError">{validationError}</div>
      }
      <div className="formRow">
        <label className="primary">
          <span>Profile Image</span>
        </label>
        <input type='file' accept='image/*' onChange={onChangeProfile} />
        <div>
          {!editAvatarImage && profileImage && <img className='profileImage' alt="" src={profileImage} />}
          {editAvatarImage && 
            <AvatarEditor
              ref={setEditorRef}
              image={avatarImage}
              width={250}
              height={250}
              border={0}
              borderRadius={250}
              color={[0, 0, 0, 0.3]} // RGBA
              scale={avatarImageZoom}
              rotate={0}
            />}
          {editAvatarImage && <div>
            <div className="avatarEditor">
              <span className="avatarEditorZoom">Zoom</span>
              <input type="range" min="20" max="100" value={avatarImageZoom * 20} onChange={(ev) => {
                setAvatarImageZoom(ev.target.value / 20)
              }} />
              <span className="avatarEditorActionButton" onClick={() => {
                const canvasScaled = editor.getImageScaledToCanvas();
                const croppedImg = canvasScaled.toDataURL();
                fetch(croppedImg)
                  .then(res => res.blob())
                  .then(blob => {
                    const file = new File([blob], 'profile.png', blob)
                    setProfileImageFile(file);
                  })
                setProfileImage(croppedImg)
                setEditAvatarImage(false)
                setAvatarImageZoom(1);
              }}>Save</span>
              <span className="avatarEditorActionButton" onClick={() => {
                setEditAvatarImage(false)
                setAvatarImageZoom(1);
              }}>Cancel</span>
            </div>
          </div>}
        </div>
      </div>
      <div className="formRow">
        <label className="primary">
          <span>Display Name</span>
        </label>
        <input
          className="displayName"
          type="text"
          value={displayName}
          onChange={(event) => {
            setDisplayName(event.target.value);
          }}
        />
      </div>
      <div className="formRow">
        <label className="primary required">
          <span>First Name</span>
        </label>
        <input
          className="firstName"
          type="text"
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
          }}
        />
      </div>
      <div className="formRow">
        <label className="primary required">
          <span>Last Name</span>
        </label>
        <input
          className="lastName"
          type="text"
          value={lastName}
          onChange={(event) => {
            setLastName(event.target.value);
          }}
        />
      </div>
      <div className="formRow">
        <label className="primary required">
          <span>Initials</span>
        </label>
        <input
          className="initials"
          type="text"
          value={initials}
          onChange={(event) => {
            setInitials(event.target.value);
          }}
        />
      </div>
      <div className="formRow">
        <label className="primary">
          <span>IFPA Player Number</span>
        </label>
        <input
          className="ifpaId"
          type="text"
          value={ifpaId}
          onChange={(event) => {
            setIFPAId(event.target.value);
          }}
        />
      </div>
      <div className="formRow formRow--buttons flexRow">
        <button
          type="button"
          className="Button"
          onClick={onUpdateProfile}
        >
          {busy ? <Loading /> : "Update Profile"}
        </button>
      </div>
    </form>
  
  return (
    <div className="Profile">
      <Helmet>
        <title>ScorbitVision - Profile</title>
      </Helmet>
      <PageHeader>
      Profile
      </PageHeader>
      <div>
        <div className="Home-Section">
          {renderForm()}
        </div>
      </div>
    </div>
  )
}

export default Profile

