import React from 'react'
import CRUDable from './../UI/CRUDable'
import Form from './form'
import WizardForm from './wizardform'
import ComboboardsIcon from './../../assets/img/nav-icons/comboboards.svg'
import './style.scss'

const Comboboards = (props) => {
  return (
    <CRUDable
      title="ScorbitVision - My Comboboards"
      className="Comboboards"
      resource="comboboard"
      form={Form}
      wizardForm={WizardForm}
      icon={ComboboardsIcon}
      enableBulkCreate={true}
      {...props}
    />
  )
}

export default Comboboards
