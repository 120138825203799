import React from 'react'
import {maybePluralize} from './../../../utils/string'
import './style.scss'

const CrudList = (props) => {
  const {className, items, label, newItemLink, disableCreate} = props
  return (
    <div className={`CrudList ${className || ''}`}>
      {!disableCreate &&
        <div className="CrudList-Header">
          <h2>{renderLabel(items, label)}</h2>
          <div>{newItemLink}</div>
        </div>
      }
      <ul className="CrudList-List">
        {items.map(renderItem)}
      </ul>
    </div>
  )
}

const renderLabel = (items, label) => {
  const ct = items.length
  return `${ct} ${maybePluralize(label, ct)}`
}

const renderItem = (item, i) => <li key={i}>{item}</li>

export default CrudList
