import Cookies from 'universal-cookie'
const cookies = new Cookies()

const Logout = () => {
  if (window.location.host.endsWith('scorbit.io')) {
    cookies.set('token', '', {domain: '.scorbit.io'})
  } else if (window.location.host.endsWith('pages.dev')) {
    cookies.set('token', '', {domain: '.scorbitvision.pages.dev'})
  } else {
    cookies.set('token', '') 
  }
  window.location = '/'
}

export default Logout
