import React, {useState, useEffect} from 'react'
import AsyncSelect from 'react-select/async'
import {doUsersRequest, doUserRequest} from './http'

const UserSelect = (props) => {
  const [selection, setSelection] = useState(null)

  const makeOption = (user) => {
    return {
      value: user["id"],
      label: `${user["username"]}${user["first_name"] ? " - " : ""}${user["first_name"]} ${user["last_name"]}`,
    }
  }

  useEffect(() => {
    if (!props.id) return
    doUserRequest(props.id)
      .then(user => setSelection(makeOption(user)))
  }, [props.id])

  const loadOptions = (inputValue, callback) => {
    if (!inputValue || inputValue.length < 2) {
      callback([])
    } else {
      doUsersRequest(inputValue)
        .then(results => {
          callback(results.map(makeOption))
        })
    }
  }

  const handleChange = (option) => {
    setSelection(option)
    props.onChange(option.value)
  }

  return (
    <div className="UserSelect">
      <AsyncSelect
        cacheOptions
        loadOptions={loadOptions}
        placeholder={"search by username, full name, or initials"}
        onChange={handleChange}
        value={selection ? selection : null}
      />
    </div>
  )
}

export default UserSelect