import React from "react"
import CountUp from 'react-countup'

const Score = (props) => {
  const {score} = props

  return (
    <span>
      {typeof(score) === 'number'
        ? <CountUp
            duration={1}
            delay={0}
            end={score}
            separator=","
            preserveValue={score === 0 ? false : true}
          >
            {({countUpRef}) => (
              <span ref={countUpRef} />
            )}
          </CountUp>
        : '--'
      }
    </span>
  )
}

export default Score