import React, {useState} from 'react'
import StreamboardViewer from './Streamboard'
import SlideshowViewer from './Slideshow'
import ComboboardViewer from './ComboBoard'
import Branding from './Branding'
import './style.scss'

const Viewer = (props) => {
  const {type} = props
  const {id} = props.match.params
  const [backgroundColor, setBackgroundColor] = useState('#000');

  if (type === "slideshow") {
    return <SlideshowViewer
      id={id}
    />
  }

  return (
    <div className={`Viewer Viewer-${type}`} style={{backgroundColor: backgroundColor}}>
      {type === 'streamboard' &&
        <StreamboardViewer id={id} />
      }
      {type === 'comboboard' &&
        <ComboboardViewer type="comboboard" id={id} setBackgroundColor={setBackgroundColor}/>
      }
      {type === 'scoreboard' &&
        <>
          <ComboboardViewer
            type="scoreboard"
            id={id}
            setBackgroundColor={setBackgroundColor}
          />
        </>
      }
      {type === 'leaderboard' &&
        <ComboboardViewer type="leaderboard" id={id} setBackgroundColor={setBackgroundColor}/>
      }
      {type === 'slideshow' &&
        <SlideshowViewer id={id} />
      }
      {(type !== 'streamboard' && type!== 'slideshow') &&
        <Branding />
      }
    </div>
  )
}

export default Viewer
