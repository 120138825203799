import React from 'react'
import CRUDable from './../UI/CRUDable'
import Form from './form'
import StreamboardsIcon from './../../assets/img/nav-icons/streamboards.svg'
import './style.scss'

const Streamboards = (props) => {
  return (
    <CRUDable
      title="ScorbitVision - My Streamboards"
      className="Streamboards"
      resource="streamboard"
      form={Form}
      icon={StreamboardsIcon}
      {...props}
    />
  )
}

export default Streamboards

