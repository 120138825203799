import React, {Fragment, useState, useContext, useEffect, useCallback} from 'react'
import Select from 'react-select'
import {ChromePicker} from 'react-color'
import AppContext from './../../contexts/app'
import OneCamImg from './../../assets/img/streamboard-layouts/OneCam.png'
import TwoCamImg from './../../assets/img/streamboard-layouts/TwoCam.png'
import TwoCamHeadToHeadImg from './../../assets/img/streamboard-layouts/TwoCamHeadToHead.png'
import ThreeCamImg from './../../assets/img/streamboard-layouts/ThreeCam.png'
import FourCamImg from './../../assets/img/streamboard-layouts/FourCam.png'
import FourCamHeadToHeadImg from './../../assets/img/streamboard-layouts/FourCamHeadToHead.png'
import ScoresOnlyImg from './../../assets/img/streamboard-layouts/ScoresOnly.png'
import {getScoreboardsRequest} from './http'
import CRUDFormButtons from './../UI/CRUDFormButtons'
import CRUDTitle from './../UI/CRUDTitle'
import Loading from './../UI/Loading'

const Form = (props) => {
  const sizes = {
    '1280x720': '1280px wide by 720px tall',
    '1440x900': '1440px wide by 900px tall',
    '1920x1080': '1920px wide by 1080px tall',
  }
  const defaultSizeKey = '1280x720'
  const layouts = {
    'OneCam': {
      label: '1 Camera',
      img: OneCamImg,
    },
    'TwoCam': {
      label: '2 Cameras',
      img: TwoCamImg,
    },
    'TwoCamHeadToHead': {
      label: '2 Cameras - Head to Head',
      img: TwoCamHeadToHeadImg,
    },
    'ThreeCam': {
      label: '3 Cameras',
      img: ThreeCamImg,
    },
    'FourCam': {
      label: '4 Cameras',
      img: FourCamImg,
    },
    'FourCamHeadToHead': {
      label: '4 Cameras - Head to Head',
      img: FourCamHeadToHeadImg,
    },
    'ScoresOnlyFixed': {
      label: 'Scores only - fixed width',
      dimensions: '860px wide, 170px tall',
      img: ScoresOnlyImg,
    },
    'ScoresOnlyVariable': {
      label: 'Scores only - variable width',
      dimensions: 'variable width, 170px tall',
      img: ScoresOnlyImg,
    },
    'LowerThirdHorizontal': {
      label: 'HD Lower Thirds Horizontal ',
      dimensions: 'high resolution lower thirds only',
      img: ScoresOnlyImg,
    },
    'LowerThirdVertical': {
      label: 'HD Lower Thirds Vertical',
      dimensions: 'high resolution lower thirds only',
      img: ScoresOnlyImg,
    },
  }
  const defaultLayoutKey = "OneCam"
  const types = {
    oneUp: "1 machine, 1 - 4 players",
    multiUp: "2 machines, 1 player each"
  }
  const defaultTypeKey = Object.keys(types)[0]
  const newItemTemplate = {
    scoreboard: null,
    numPlayers: 1,
    players: [{}, {}, {}, {}]
  }
  const defaultScoreboards = [{...newItemTemplate}]
  const {profile, scrollToTop} = useContext(AppContext)
  const [action, setAction] = useState(null)
  const [validationError, setValidationError] = useState(null)
  const [title, setTitle] = useState(null)
  const [items, setItems] = useState(defaultScoreboards)
  const [backgroundColor, setBackgroundColor] = useState('#000000')
  const [textColor, setTextColor] = useState('#ffffff')
  const [highlightColor, setHighlightColor] = useState('#ffdf00')
  const [sizeKey, setSizeKey] = useState(defaultSizeKey)
  const [typeKey, setTypeKey] = useState(defaultTypeKey)
  const [layoutKey, setLayoutKey] = useState(defaultLayoutKey)
  const [prevTitle, setPrevTitle] = useState(null)
  const [busy, setBusy] = useState(false)
  const [scoreboards, setScoreboards] = useState(null)
  const [scoreboardsOptions, setScoreboardsOptions] = useState([])
  const [loading, setLoading] = useState(true)

  const mapPropsToState = useCallback(() => {
    const {item} = props
    setAction(item ? 'update' : 'create')
    if (!item) return
    setTitle(item["title"])
    setPrevTitle(item["title"])
    setItems(item["scoreboard_items"].map((scoreboard, i) => {
      return {
        scoreboard: scoreboard["scoreboard"],
        numPlayers: item["config_json"]["scoreboardsConfig"][i]['numPlayers'],
        players: item["config_json"]["scoreboardsConfig"][i]['players'],
    }}))
    setSizeKey(item["config_json"]["size"] || defaultSizeKey)
    setTypeKey(item["config_json"]["type"] || defaultTypeKey)
    setLayoutKey(item["config_json"]["layout"] || defaultLayoutKey)
    setBackgroundColor(item["config_json"]["backgroundColor"])
    setTextColor(item["config_json"]["textColor"])
    setHighlightColor(item["config_json"]["highlightColor"])
  }, [props, defaultTypeKey])
  useEffect(mapPropsToState, [props.item])

  const getScoreboards = useCallback(() => {
    getScoreboardsRequest(profile.id)
    .then(scoreboards => {
      const oneUpScoreboards = scoreboards.filter(scoreboard =>
        scoreboard["venuemachine_items"].length === 1 && scoreboard["is_public"] === true)
      const makeOption = (item) => {
        return {
          value: item.id,
          label: item.title,
        }
      }
      setScoreboardsOptions([...oneUpScoreboards.map(makeOption)])
      setScoreboards(oneUpScoreboards)
      setLoading(false)
    })
  }, [profile.id])
  useEffect(getScoreboards, [getScoreboards])

  const validate = () => {
    const titles = props.items.map(item => item.title)
    const isDupe = titles.filter(item => item === title).length ? true : false
    let validationError = ''

    // validate title not blank
    if (!title || !title.length) {
      validationError = 'Title must not be blank'
    }

    // validate unique title when action is create
    else if (action === 'create' && isDupe) {
      validationError = 'Title must be unique'
    }

    // validate unique title when action is update
    else if (action === 'update' && isDupe && title !== prevTitle) {
      validationError = 'Title must be unique'
    }

    // every item must have a scoreboard
    else if (items.filter(item => item.scoreboard === null).length) {
      validationError = 'Each item must specify a scoreboard'
    }

    // scoreboards must be unique
    else if (items.length === 2 && items[0].scoreboard === items[1].scoreboard) {
      validationError = "Each scoreboard must be unique"
    }

    // show error or proceed
    if (validationError.length) {
      setValidationError(validationError)
      return false
    } else {
      setValidationError(null)
      return true
    }
  }

  const onSubmit = (event) => {
    event.preventDefault()
    setBusy(true)
    if (validate()) {
      const scoreboardItems = (typeKey === 'oneUp' ? [items[0]] : items).map((scoreboard, i) => ({
        "scoreboard": scoreboard.scoreboard,
        "order": i,
      }))
      const configJSON = {
        size: sizeKey,
        type: typeKey,
        layout: layoutKey,
        scoreboardsConfig: (typeKey === 'oneUp' ? [items[0]] : items).map((item, i) => ({
          numPlayers: item.numPlayers,
          players: item.players,
        })),
        backgroundColor: backgroundColor,
        textColor: textColor,
        highlightColor: highlightColor,
      }
      const streamboard = {
        "title": title,
        "is_public": true,
        "config_json": configJSON,
        "scoreboard_items": scoreboardItems,
      }
      props.onSubmit(streamboard)
    } else {
      setBusy(false)
      scrollToTop()
    }
  }

  const renderSelect = ({key, type, value, options, onChange}) =>
    <div className="formRow">
      <div>
        <label>
          {type === 'scoreboard' && 'Select Scoreboard'}
        </label>
      </div>
      <Select
        key={key}
        className="react-select"
        classNamePrefix="react-select"
        value={value}
        onChange={(option) => onChange(key, option)}
        options={options}
      />
    </div>

  const renderScoreboardsSelect = (i) => {
    const getSelectedOption = () => {
      if (!items[i].scoreboard) {
        return null
      } else {
        return scoreboardsOptions.find(option => option.value === items[i].scoreboard)
      }
    }
    const onChange = (i, option) => {
      changeItem(i, 'scoreboard', option)
    }

    return renderSelect({
      key: i,
      value: getSelectedOption(),
      options: scoreboardsOptions,
      onChange: onChange,
      type: 'scoreboard',
    })
  }

  const deleteItem = (event, i) => {
    event.preventDefault()
    const nextItems = [...items]
    nextItems.splice(i, 1)
    setItems(nextItems)
  }

  const changeItem = (i, type, option) => {
    const nextItems = [...items]
    nextItems[i].scoreboard = option.value
    setItems(nextItems)
  }

  const changeNumPlayers = (i, numPlayers) => {
    const nextItems = [...items]
    nextItems[i].numPlayers = numPlayers
    setItems(nextItems)
  }

  const changePlayerName = (itemIndex, playerIndex, value) => {
    const nextItems = [...items]
    if (!nextItems[itemIndex].players[playerIndex]) {
      nextItems[itemIndex].players[playerIndex] = {}
    }
    nextItems[itemIndex].players[playerIndex].playerName = value
    setItems(nextItems)
  }

  const changePlayerInfo = (itemIndex, playerIndex, value) => {
    const nextItems = [...items]
    nextItems[itemIndex].players[playerIndex].playerInfo = value
    setItems(nextItems)
  }

  const changePlayerAddress = (itemIndex, playerIndex, value) => {
    const nextItems = [...items]
    nextItems[itemIndex].players[playerIndex].playerAddress = value
    setItems(nextItems)
  }
  const changeTypeKey = (key) => {
    const nextItems = key === 'oneUp' ? [items[0]] : [...items]
    if (key === 'multiUp') {
      nextItems.forEach(item => {
        item.numPlayers = 1
        item.players = [{}]
      })
      if (nextItems.length === 1) {
        nextItems.push({...newItemTemplate})
      }
    } else {
      nextItems.forEach(item => {
        item.players = [{}, {}, {}, {}]
      })
    }
    setItems(nextItems)
    setTypeKey(key)
  }

  const reorderItems = (event, i, direction) => {
    event.preventDefault()
    const nextItems = [...items]
    const x = i
    const y = direction === 'up' ? i - 1 : i + 1
    nextItems[x] = nextItems.splice(y, 1, nextItems[x])[0]
    setItems(nextItems)
  }

  const renderNoResources = () =>
    <p>
      You must create at least one public, scoreboard with only one machine to use this feature.
    </p>

  const renderItem = (item, i) =>
    <div
      key={i}
      className="formRow item"
    >
      <div className="formRow flexRow item-label">
        <label className="primary">Scoreboard {i + 1}</label>
        <div className="item-buttons">
          {items.length >= 1 && i > 0 &&
            <button
              className="Button Button--secondary Button--small"
              onClick={(event) => reorderItems(event, i, 'up')}
              title={'move up'}
            >
              <i className="material-icons">arrow_upward</i>
            </button>
          }
          {items.length >= 1 && i < items.length - 1 &&
            <button
              className="Button Button--secondary Button--small"
              onClick={(event) => reorderItems(event, i, 'down')}
              title={'move down'}
            >
              <i className="material-icons">arrow_downward</i>
            </button>
          }
          {items.length > 1 &&
            <button
              className="Button Button--secondary Button--small"
              onClick={(event) => deleteItem(event, i)}
              title={'delete'}
            >
              <i className="material-icons">close</i>
            </button>
          }
        </div>
      </div>
      {renderScoreboardsSelect(i)}
      <div className="formRow">
        {typeKey === "oneUp" &&
          <Fragment>
            <label>How many players?</label>
            <select
              defaultValue={item.numPlayers}
              onChange={event => changeNumPlayers(i, event.target.value)}
            >
              {(typeKey === "oneUp" ? [1, 2, 3, 4] : [1]).map(i =>
                <option
                  key={i}
                  value={i}
                >
                  {i}
                </option>
              )}
            </select>
          </Fragment>
        }
      </div>
      <div className="formRow">
        <label>
          Player Info
          <div className="tip">
            Player names will be automatically assigned by Scorbit API, but can be overridden here.
          </div>
        </label>
      </div>
      {(typeKey === "oneUp" ? [0, 1, 2, 3] : [0]).map(j => {
        return j <= item.numPlayers - 1
          ? <Fragment key={j}>
              <div className="formRow flexRow">
                <div>
                  <label>
                    {`Player ${j + 1} name`}
                  </label>
                  <input
                    type="text"
                    value={(item.players[j] && item.players[j].playerName) || ''}
                    onChange={event => changePlayerName(i, j, event.target.value)}
                  />
                </div>
                <div>
                  <label>
                    {`Player ${j + 1} info`}
                  </label>
                  <input
                    type="text"
                    value={(item.players[j] && item.players[j].playerInfo) || ''}
                    onChange={event => changePlayerInfo(i, j, event.target.value)}
                  />
                </div>
                <div>
                  <label>
                    {`Player ${j + 1} address`}
                  </label>
                  <input
                    type="text"
                    value={(item.players[j] && item.players[j].playerAddress) || ''}
                    onChange={event => changePlayerAddress(i, j, event.target.value)}
                  />
                </div>
              </div>
            </Fragment>
          : null
      })}
    </div>

  const renderForm = () =>
    <form
      onSubmit={onSubmit}
      className="has-required-fields"
    >
      {validationError &&
        <div className="formError">{validationError}</div>
      }
      <div className="formRow">
        <CRUDTitle
          title={title}
          setTitle={setTitle}
        />
      </div>

      <div className="formRow">
        <label className="primary">
          Size (canvas size in OBS)
        </label>
        {Object.keys(sizes).map((key) =>
          <div key={key}>
            <label
              htmlFor={`size-${key}`}
              key={key}
            >
              <input
                id={`size-${key}`}
                type="radio"
                name={`size-${key}`}
                checked={sizeKey === key}
                onChange={() => {setSizeKey(key)}}
              />
              {sizes[key]}
            </label>
          </div>
        )}
      </div>


      <div className="formRow wide">
        <label className="primary">
          Layout
        </label>
        {Object.keys(layouts).map((key) =>
          <label
            className={`hasImage ${layoutKey === key ? ' selected' : ''}`}
            htmlFor={`layout-${key}`}
            key={key}
          >
            <div>
              <input
                id={`layout-${key}`}
                type="radio"
                name={`layout-${key}`}
                checked={layoutKey === key}
                onChange={() => {setLayoutKey(key)}}
              />
              <img src={layouts[key].img} alt="" />
            </div>
            <div>
              <strong>
                {layouts[key].label}
              </strong>
            </div>
            <div>
              {layouts[key].dimensions ? layouts[key].dimensions : null}
            </div>
          </label>
        )}
      </div>
      <div className="formRow">
        <label className="primary">
          Type
        </label>
        {Object.keys(types).map((key) =>
          <div key={key}>
            <label
              htmlFor={`type-${key}`}
              key={key}
            >
              <input
                id={`type-${key}`}
                type="radio"
                name={`type-${key}`}
                checked={typeKey === key}
                onChange={() => {changeTypeKey(key)}}
              />
              {types[key]}
            </label>
          </div>
        )}
      </div>
      <div className="formRow items">
        {items.length < 1 && renderItem()}
        {typeKey === "oneUp" && [items[0]].map(renderItem)}
        {typeKey === "multiUp" && items.map(renderItem)}
      </div>
      <div className="formRow">
        <label className="primary">Colors</label>
        <div className="formRow flexRow">
          <div>
            <label>Background Color</label>
            <ChromePicker
              color={backgroundColor}
              disableAlpha={true}
              onChange={(color) => setBackgroundColor(color.hex)}
            />
          </div>
          <div>
            <label>Text Color</label>
            <ChromePicker
              color={textColor}
              disableAlpha={true}
              onChange={(color) => setTextColor(color.hex)}
            />
          </div>
          <div>
            <label>Highlight Color</label>
            <ChromePicker
              color={highlightColor}
              disableAlpha={true}
              onChange={(color) => setHighlightColor(color.hex)}
            />
          </div>
        </div>
      </div>
      <CRUDFormButtons
        busy={busy}
        disableSubmit={busy}
        titleForSubmit={props.titleForSubmit}
        cancelHref="/streamboards"
      />
    </form>

  return loading
    ? <Loading />
    : !scoreboards.length
      ? renderNoResources()
      : renderForm()
}

export default Form