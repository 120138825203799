import React, {useState, useEffect, useCallback} from 'react'
import AsyncSelect from 'react-select/async'
import _ from 'lodash'
import {doMachinesRequest, doMachinesSearchRequest, doMachinesRequestById} from './http'

const MachineSelect = (props) => {
  const {id, onChange, defaultMachines} = props
  const [defaultOptions, setDefaultOptions] = useState(null)
  const [selection, setSelection] = useState(null)

  const noOption = {
    value: null,
    label: '-- none --',
  }

  const makeOption = (machine) => {
    const {id, name, manufacturer, year} = machine

    return {
      value: id,
      label: `${name} - ${manufacturer}, ${year}`,
    }
  }

  const makeOptions = useCallback((machines) => {
    return machines.map(makeOption)
  }, [])

  const getDefaultOptions = useCallback(() => {
    doMachinesRequest()
      .then(machines => {
        const options = makeOptions(machines)
        setDefaultOptions(options)
      })
  }, [makeOptions])

  useEffect(() => {
    if (!id) return
    const selectedMachine = defaultMachines.find(machine => machine.id === id)
    if (defaultMachines.find(machine => machine.id === id)) {
      const option = makeOption(selectedMachine)
      setSelection(option)
    } else {
      doMachinesRequestById(id)
        .then(machines => {
          setDefaultOptions([machines, ...defaultMachines])
          const option = makeOption(machines)
          setSelection(option)
      })
    }
  }, [id, defaultMachines])

  useEffect(() => {
    if (!defaultMachines) {
      getDefaultOptions()
    } else {
      const options = makeOptions(defaultMachines)
      setDefaultOptions(options)
    }
  }, [defaultMachines, getDefaultOptions, makeOptions])

  const loadOptions = _.debounce((inputValue, callback) => {
    if (!inputValue || !inputValue.length) {
      callback([noOption, ...defaultOptions])
    } else {
      doMachinesSearchRequest(inputValue)
        .then(machines => {
          const searchMatches = makeOptions(machines)
          callback([noOption, ...searchMatches])
        })
    }
  }, 500)

  const handleChange = (option) => {
    setSelection(option)
    onChange(option.value)
  }

  return (
    <div className="MachineSelect">
      {defaultOptions
        ? <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            defaultOptions
            placeholder={"search by machine or venue..."}
            onChange={handleChange}
            value={selection ? selection : null}
          />
        : null
      }
    </div>
  )
}

export default MachineSelect